import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import DataForm, { DataFormSaveMode } from './DataForm';
import Spinner from './Spinner';
import Grid from '@material-ui/core/Grid';
import { buildTabFragmentLookup, createFragments } from '../../../util/graphql';
import { AltButton, OutlineButton } from '../PrimaryButton';
import { isNullOrUndefined } from '../../../util/objects';
import cx from 'classnames';
import BackIcon from '../../icon/BackIcon';
import NextIcon from '../../icon/NextIcon';
import Stepper from '../Stepper';
import { Hidden } from '@material-ui/core';

/**
 * Wraps a data form for viewing. Requires a few parameters to work properly
 *
 * @param tabs an array of tabs. The tab has the following structure:
            {
                id: "SubPanelBracketsTab1",                 id that maps back to the component
                label: "Brackets",                          the label to show in viewMode mode
                component: SubPanelBracketsTab1,            the view
                fragment: BracketsTabBracketsFragment,      fragment to load when shown
                onLoad: data => {},                         manipulate data before loading the view
                formatSaveData: (saveData, state) => {}     manipulate the data before saving
                disabled: false                             allows to disable specific tab/step
            }
 * @param buttonLabels overrides generated labels for buttons. Expected structure:
            {
                isUnsaved: 'Saved'
                isSaved: 'Save'
            }
 * @param additionalActions passed to DataForm, adds additional set of buttons with custom actions. Expected array containing objects with structure:
            {
                onClick: () => {}         callback function for action
                disabled: false           is button disabled
                skipSave: false           function unclear ;) look in to DataForm->renderAllButtons() for more details
            }
 * @param createNew whether a new object should be created when this view is shown
 * @param createNewFunc creates an empty version of the object
 * @param client Appolo Client to be used by DataForm
 * @param queryName name of the query for future refernce, if not provided read<One|Current><objectType> will be used
 * @param objectType type of the object in the database
 * @param parentObjectType type of the parent/connected object in the database
 * @param parentId id of the parent to connect to when saving
 * @param name of this view
 * @param context of the parent view
 * @param form parent form
 * @param itemId id of the object to load
 * @param onDataChanged executes when data in form was changed
 * @param onCreateNew executes after a new object has been created/saved to the database
 * @param onSaved executes after an object has been saved to the database (returns the new object)
 * @param onBeforeSave executes before DataForm dataform saved, will receive current instance of DataForm and should return boolean to approve or cancel saving
 * @param onPageChange executes when selectedIndex was changed. Arguments: (<event|null>, <selectedIndex>). Event provided only if view in Tabbed mode
 * @param onStepComplete executes when view in stepper mode and step was completed. Arguments: (<completedStep>,<allCompletedSteps>)
 * @param onClose callback for close button. Button only rendered if callback present
 * @param onLoad fires each time form finishes loading
 * @param viewMode use the import DataFormViewMode to select single, tabbed or stepped view
 * @param viewLoaded function that is called once a view loads for the first time. NOTE: There's a bit of kink here. It doesn't trigger on the first view (because the viewLoaded is called in componentDidUpdate). Gotta fix this eventually
 * @param readCurrent if true, instead of using readOne{objectType} will use readCurrent{objectType}
 * @param readOnly stops save button being rendered
 * @param selectedIndex value to be passed in to the state to choose selected tab from outside
 * @param useFixedStepperBehaviour fixes stepper mode behaviour, by default fix is disabled to preserve behaviour for code that could rely on broken behaviour
 * @param useValidation boolean, decicdes if DataFormView validation function will be passed to the DataForm. NOTE: Not seems to work good with stepped view, locking save button on next step, not on current
 * @param tabProps passed as property 'tabProps' of component of currently active tab|step
 * @param saveMode use the import DataFormSaveMode from DataForm to select save mode. Available modes: currentTab - saves changes that are made on currently opened tab/step, currentFragment - saves changes for all tabs/pages that use same fragment as current tab, all<not implemented yet> - save changes to all tabs/pages.
 * @param keepComponentWhileLoading render component while loading in propgress, child components need to be ready to deal with loading and empty data
 * @param purgeCachedTypes array of types to be purgetd from cache when form will be saved
 * @param continuousValidation enables behaviour used in arrangement forms where validation happens continuesly and starts from form load. Default - false, form will be validated on save
 * @param saveNotValid enables saving of form with failed validion
 * @param historyBlockHandler function(confirmCallback,cancelCallback) - function shall handle user promt for leaving page with unsaved changes. If user confirms leaving - confirmCallback() should be called, and if not - cancelCallback(). Use confirmCallback(true) to force navigation without saving
 * @param refetchInvalid should be paired with validList parameter. Will force dataformview to fetch query from network if it is not present in validList as this: validList[objectType][itemId] === true. Once form is loaded it will be added to that list, so if it persists - it will be populated automaticaly with loaded types and ids
 */

export const DataFormViewMode = {
    single: 0,
    tabbed: 1,
    stepped: 2
};

class DataFormView extends Component {
    constructor(props) {
        super(props);
        this.htmlForm = React.createRef();
    }

    state = {
        selectedIndex: 0,
        itemId: null,
        createNew: false,
        tabFragmentLookup: null,
        fragments: null,
        viewMode: DataFormViewMode.single,
        completedSteps: new Set(),
        skippedSteps: null,
        loaded: [],
        readQuery: null,
        saveMode: null //making sure that we know that we did not have selected some saveMode already
    };

    static getDerivedStateFromProps({ itemId, createNew, tabs, viewMode, selectedIndex, saveMode }, oldState) {
        let newState = { ...oldState };

        //build the tab lookup if it hasn't been built yet
        if (oldState.tabFragmentLookup === null) {
            newState.tabFragmentLookup = buildTabFragmentLookup(tabs, true);
            newState.fragments = createFragments(tabs);
            newState.loaded = tabs.map(x => false);

            if (!viewMode) newState.viewMode = tabs.length > 1 ? DataFormViewMode.tabbed : DataFormViewMode.single;
            else newState.viewMode = viewMode;
        }

        if (selectedIndex !== undefined) {
            newState.selectedIndex = selectedIndex;
        }

        if (newState.saveMode === null) {
            newState.saveMode = saveMode === undefined ? DataFormSaveMode.currentTab : saveMode;
        } else if (newState.saveMode !== saveMode && saveMode !== undefined) {
            throw new Error('Once set, save mode can not be changed');
        }

        if (
            viewMode === DataFormViewMode.stepped &&
            (oldState.completedSteps == null || oldState.skippedSteps == null)
        ) {
            newState.completedSteps = new Set();
            newState.skippedSteps = new Set();
        }
        if (createNew) {
            if (oldState.itemId === null) {
                //if its brand new, set it to createNew = true
                newState.createNew = true;
            } else {
                //this was a createNew, but it's been saved and now has a itemId
                newState.createNew = false;
            }
        } else if (itemId !== oldState.itemId) {
            //this is an existing thing
            newState.itemId = itemId;
        }
        return newState;
    }

    componentDidMount() {
        const { readCurrent, objectType } = this.props;
        this.setState({
            readQuery: readCurrent === true ? `readCurrent${objectType}` : `readOne${objectType}`
        });
    }

    componentDidUpdate() {
        const { loaded, selectedIndex } = this.state;

        if (!loaded[selectedIndex]) {
            loaded[selectedIndex] = true;
            this.setState({ loaded });

            const { viewLoaded } = this.props;
            if (viewLoaded) {
                viewLoaded(selectedIndex);
            }
        }
    }

    render() {
        const { selectedIndex, itemId, createNew, readQuery } = this.state;
        const {
            tabs,
            createNewFunc,
            readCurrent,
            objectType,
            client,
            refetchInvalid,
            validList,
            refetchQueries
        } = this.props;
        let { queryName } = this.props;

        const tab = tabs[selectedIndex];

        if (createNew) {
            return this.renderForm({
                loading: false,
                data: { [`readOne${objectType}`]: createNewFunc() }
            });
        }

        if (!readQuery) return null;
        if (queryName === undefined) {
            queryName = readQuery;
        }

        let query, variables;
        if (readCurrent === true) {
            query = gql`
          ${tab.fragment}
          query ${queryName} {
            ${readQuery} {
                  ID
                  ...${tab.fragment.definitions[0].name.value}
              }
          }
      `;
            variables = {};
        } else {
            query = gql`
          ${tab.fragment}
          query ${queryName}($id: ID!) {
            ${readQuery}(ID: $id) {
                  ID
                  ...${tab.fragment.definitions[0].name.value}
              }
          }
      `;
            variables = { id: itemId };
        }

        let fetchPolicy = 'cache-first';

        if (refetchInvalid) {
            if (!validList) {
                fetchPolicy = 'network-only';
                this.props.validList = {};
            }
            if (!validList[objectType]) {
                fetchPolicy = 'network-only';
                this.props.validList[objectType] = {};
            }
            if (validList[objectType][itemId] !== true) {
                fetchPolicy = 'network-only';
                this.props.validList[objectType][itemId] = true;
            }
        }

        return (
            <Query
                query={query}
                variables={variables}
                client={client}
                fetchPolicy={fetchPolicy}
                refetchQueries={refetchQueries ? refetchQueries : false}
            >
                {results => this.renderForm(results, query)}
            </Query>
        );
    }

    renderForm({ loading, error, data }, query) {
        const {
            parentId,
            form,
            tabs,
            name,
            parentObjectType,
            objectType,
            context,
            onSaved,
            onLoad,
            onBeforeSave,
            classes,
            buttonLabels,
            useValidation,
            readCurrent,
            readOnly,
            className,
            additionalActions,
            client,
            keepComponentWhileLoading,
            saveNotValid,
            historyBlockHandler
        } = this.props;
        const { selectedIndex, itemId, tabFragmentLookup, fragments, viewMode, readQuery, saveMode } = this.state;
        const tab = tabs[selectedIndex];

        if (loading && (!data || !data[0]) && !keepComponentWhileLoading) return this.renderLoading();
        if (error) return this.renderError(error);

        let createNew;
        if (readCurrent === true) {
            if (isNullOrUndefined(data[readQuery])) {
                data = this.props.createNewFunc();
                createNew = true;
            } else {
                createNew = false;
            }
        } else {
            createNew = this.props.createNew;
        }

        //get the save functions
        let saveFunc = createNew ? s => this.onCreateNew(s) : s => onSaved && onSaved(s);

        //get the correct button labels
        let steppedButtonLabels = null;
        if (viewMode === DataFormViewMode.stepped) {
            const { completedSteps } = this.state;

            steppedButtonLabels = {
                isUnsaved: completedSteps.size === tabs.length ? 'Submit' : 'Save',
                isSaved: completedSteps.size === tabs.length ? 'Submit' : 'Save'
            };
        }

        //get the appropriate function to render the view
        let renderViewFunc = null;
        switch (viewMode) {
            case DataFormViewMode.single:
                renderViewFunc = this.renderSingleView;
                break;
            case DataFormViewMode.tabbed:
                renderViewFunc = this.renderTabbedView;
                break;
            case DataFormViewMode.stepped:
                renderViewFunc = this.renderSteppedView;
                break;
            default:
                throw new Error('unknown view mode', this.props.name);
        }

        return (
            <div className={className}>
                <DataForm
                    name={name}
                    id={createNew ? parentId : itemId}
                    idType={createNew ? `${parentObjectType}ID` : 'ID'}
                    tab={tab}
                    loading={loading}
                    error={error}
                    onSaved={saveFunc}
                    onLoad={onLoad}
                    onBeforeSave={onBeforeSave}
                    data={data && data[readQuery]}
                    mutationName={`update${objectType}`}
                    mutationInputType={`Update${objectType}Input`}
                    createNew={createNew}
                    context={context}
                    fragments={fragments}
                    tabs={tabs}
                    tabFragmentLookup={tabFragmentLookup}
                    parentDataForm={form}
                    buttonLabels={buttonLabels || steppedButtonLabels}
                    buttonStyles={classes.saveButton}
                    selectedIndex={selectedIndex}
                    readOnly={readOnly}
                    validate={useValidation === true ? () => this.validate() : null}
                    additionalActions={additionalActions}
                    customClient={client}
                    saveMode={saveMode}
                    buttonContainerStyles={classes.footerButtonsContainer}
                    onChange={this.onFormDataChanged}
                    saveNotValid={saveNotValid}
                    historyBlockHandler={historyBlockHandler}
                    query={query}
                >
                    {(form, saveButton) => (
                        <Fragment>
                            {!!loading && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        zIndex: 99,
                                        margin: -20,
                                        padding: 20,
                                        height: '100%',
                                        width: '100%',
                                        background: 'rgba(255,255,255,0.5)'
                                    }}
                                >
                                    <span>Loading data, please wait a moment...</span>
                                    <div style={{ float: 'left', marginRight: 8 }}>
                                        <Spinner />
                                    </div>
                                </div>
                            )}
                            {readOnly ? (
                                renderViewFunc(form, saveButton, query) //Read-only DataFormViews don't require form element.
                            ) : (
                                <form className={classes.form} ref={this.htmlForm}>
                                    {renderViewFunc(form, saveButton, query)}
                                </form>
                            )}
                        </Fragment>
                    )}
                </DataForm>
            </div>
        );
    }

    renderSingleView = (form, saveButton, query) => {
        const { classes, tabs } = this.props;
        const { selectedIndex } = this.state;
        const tab = tabs[selectedIndex];
        return (
            <Fragment>
                <div className={classes.root}>
                    <tab.component
                        form={form}
                        createNew={this.props.createNew}
                        itemId={this.props.itemId}
                        parentId={this.props.parentId}
                        saveButton={saveButton}
                        tabProps={this.props.tabProps}
                        context={this.props.context}
                        query={query}
                    />
                </div>

                <div className={classes.footerButtons}>
                    {this.renderCloseButton()}
                    {saveButton}
                </div>
            </Fragment>
        );
    };

    renderCloseButton() {
        const { onClose, classes, buttonLabels } = this.props;
        if (!onClose) return null;

        return (
            <AltButton onClick={onClose} className={classes.button}>
                {buttonLabels && buttonLabels.close ? buttonLabels.close : 'Close'}
            </AltButton>
        );
    }

    renderSteppedView = (form, saveButton, query) => {
        const { classes, tabs, className } = this.props;
        const { selectedIndex, completedSteps } = this.state;
        const tab = tabs[selectedIndex];
        return (
            <span className={className}>
                <Grid item xs={12} className={classes.stepperContainer}>
                    <div>
                        {tabs.length > 1 && (
                            <Stepper
                                alternativeLabel
                                nonLinear
                                activeStep={selectedIndex}
                                tabs={tabs}
                                completedSteps={completedSteps}
                                onStepperStep={index => this.onStepperStep(index)}
                            />
                        )}
                        <div>
                            {this.renderTabContent(selectedIndex, form, null, query)}
                            {saveButton}
                            <div
                                className={
                                    selectedIndex === 0
                                        ? cx(classes.buttonHolder, classes.firstPageButtons)
                                        : selectedIndex === tabs.length - 1
                                        ? cx(classes.buttonHolder, classes.lastPageButtons)
                                        : classes.buttonHolder
                                }
                            >
                                {selectedIndex > 0 && (
                                    <OutlineButton
                                        disabled={selectedIndex === 0}
                                        variant="contained"
                                        color="primary"
                                        onClick={e => this.onStepperBack()}
                                    >
                                        <BackIcon />
                                        <Hidden xsDown>&nbsp;Previous Page</Hidden>
                                    </OutlineButton>
                                )}
                                {selectedIndex < tabs.length - 1 &&
                                    (tab.isOptional === true ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={e => this.onStepperSkip()}
                                            className={classes.button}
                                        >
                                            Skip
                                        </Button>
                                    ) : (
                                        <AltButton
                                            variant="contained"
                                            color="primary"
                                            onClick={this.onStepperNext}
                                            className={classes.button}
                                        >
                                            <Hidden xsDown>Next Page&nbsp;</Hidden>
                                            <NextIcon />
                                        </AltButton>
                                    ))}
                            </div>
                        </div>
                    </div>
                </Grid>
            </span>
        );
    };

    renderTabbedView = (form, saveButton, query) => {
        const { classes, tabs } = this.props;
        const { selectedIndex } = this.state;

        return (
            <Fragment>
                <div className={classes.root}>
                    <Tabs
                        value={selectedIndex}
                        onChange={this.onChange}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                        className={classes.tabs}
                        classes={{
                            scrollButtonsAuto: classes.scrollButtons,
                            flexContainer: classes.tabsContainer,
                            scroller: classes.scroller
                        }}
                    >
                        {tabs.map((tab, index) => (
                            <Tab
                                className={classes.tab}
                                key={index}
                                selected={index === selectedIndex}
                                label={this.renderTabLabel(tab, index === selectedIndex)}
                                disabled={tab.disabled}
                            />
                        ))}
                    </Tabs>
                    {this.renderTabContent(selectedIndex, form, saveButton, query)}
                </div>

                <div className={classes.footerButtons}>
                    {this.renderCloseButton()}
                    {saveButton}
                </div>
            </Fragment>
        );
    };

    renderTabLabel(tab, selected) {
        const { classes } = this.props;

        return (
            <div className={classes.tabContainer}>
                <div className={selected ? cx(classes.tabLabel, classes.tabLabelSelected) : classes.tabLabel}>
                    {tab.title || tab.label}
                </div>
                <div className={classes.badgeContainer}>
                    <div title={'Please fix required fields'} className={classes.badgeError} tab-error={tab.key} />
                    <div
                        title={'Please fix suggested fields'}
                        className={classes.badgeSuggested}
                        tab-suggested={tab.key}
                    />
                </div>
            </div>
        );
    }

    renderTabContent(selectedIndex, form, saveButton, query) {
        const { classes, tabs } = this.props;
        const tab = tabs[selectedIndex];
        return (
            <Fragment>
                <div className={classes.content}>
                    <tab.component
                        form={form}
                        createNew={this.props.createNew}
                        itemId={this.props.itemId}
                        parentId={this.props.parentId}
                        tabProps={this.props.tabProps}
                        saveButton={saveButton}
                        context={this.props.context}
                        query={query}
                    />
                </div>
            </Fragment>
        );
    }

    renderError(error) {
        const { classes, className } = this.props;
        console.error('an error occurred loading the sum tabs', error);
        return (
            <span className={className}>
                <div className={classes.graphQLFeedbackFull}>An error occurred</div>
            </span>
        );
    }

    renderLoading() {
        const { classes, className } = this.props;
        return (
            <span className={className}>
                <div className={classes.root}>
                    <div className={classes.loading}>
                        <Spinner />
                    </div>
                </div>
            </span>
        );
    }

    onChange = (event, selectedIndex) => {
        this.setState({ selectedIndex });
        if (this.props.onPageChange) this.props.onPageChange(event, selectedIndex);
    };

    onCreateNew(newObject) {
        this.setState({ itemId: newObject.ID, createNew: false });

        if (this.props.onCreateNew) {
            this.props.onCreateNew(newObject);
        }
    }

    onStepperStep(index) {
        const { onPageChange, tabs, useFixedStepperBehaviour } = this.props;

        this.updateValidation();
        //Preventing from stepping in to the disabled step
        if (!tabs[index].disabled || !useFixedStepperBehaviour) {
            this.setState({ selectedIndex: index });
            if (onPageChange) onPageChange(null, index);
        }
    }

    onStepperNext = () => {
        let { selectedIndex, completedSteps } = this.state;
        const { tabs } = this.props;

        //=======this code is the part of the fix for stepper mode============
        const { useFixedStepperBehaviour } = this.props;
        //====================================================================
        if (useFixedStepperBehaviour) {
            this.updateValidation();
        }
        let nextIndex = selectedIndex + 1;
        if (nextIndex === tabs.length) {
            //=======this code is the part of the fix for stepper mode============
            if (!useFixedStepperBehaviour) {
                //====================================================================
                if (completedSteps.size === 0) {
                    //cycle to start
                    nextIndex = 0;
                } else if (completedSteps.size !== tabs.length - 1) {
                    //cycle to next incomplete step
                    const indices = tabs.map((x, i) => i);
                    nextIndex = indices.find(x => !completedSteps.has(x));
                }
                //=======this code is the part of the fix for stepper mode============
            } else {
                const indices = tabs.map((x, i) => i);
                nextIndex = indices.find(x => !completedSteps.has(x));
            }
            //====================================================================
        }

        if (tabs[nextIndex].disabled) {
            nextIndex = selectedIndex; //next tab locked - staying here
        }

        if (this.props.onPageChange) this.props.onPageChange(null, nextIndex);
        this.setState({ selectedIndex: nextIndex, completedSteps });

        if (!useFixedStepperBehaviour && !completedSteps.has(selectedIndex)) {
            completedSteps.add(selectedIndex);
        }

        //=======this code is the part of the fix for stepper mode============
        if (!useFixedStepperBehaviour) {
            //====================================================================
            if (completedSteps.size === tabs.length - 1) this.onStepperComplete();
            //=======this code is the part of the fix for stepper mode============
        }
        //====================================================================
    };

    onStepperBack = () => {
        let { selectedIndex } = this.state;
        if (selectedIndex === 0) return;

        selectedIndex--;
        this.setState({ selectedIndex });
        if (this.props.onPageChange) this.props.onPageChange(null, selectedIndex);
    };

    onStepperSkip = () => {
        let { selectedIndex, skippedSteps } = this.state;
        const { tabs } = this.props;
        const tab = tabs[selectedIndex];

        if (tab.isOptional !== false) return;

        skippedSteps.add(selectedIndex);

        if (selectedIndex !== tabs.length - 1) selectedIndex++;

        this.setState({ skippedSteps, selectedIndex });
        if (this.props.onPageChange) this.props.onPageChange(null, selectedIndex);
    };

    onStepperComplete = () => {
        const { onStepperComplete } = this.props;
        if (onStepperComplete) onStepperComplete();
    };

    validate() {
        if (!this.htmlForm.current) return true;
        return this.htmlForm.current.checkValidity();
    }

    updateValidation() {
        const { onStepComplete, tabs } = this.props;
        const completedSteps = new Set();
        if (this.form && tabs) {
            this.form.validateForm();
            const results = this.form.validation.tabs;
            tabs.forEach((tab, index) => {
                if (!results[tab.id] || results[tab.id].invalid === 0) {
                    completedSteps.add(index);
                }
            });
        }
        if (this.state.completedSteps.size !== completedSteps.size && onStepComplete) {
            onStepComplete(completedSteps);
        }
        this.state.completedSteps = completedSteps;
    }

    onFormDataChanged = form => {
        this.form = form;
        const { continuousValidation } = this.props;
        if (continuousValidation) {
            this.updateValidation();
        }
        if (this.props.onDataChanged) this.props.onDataChanged(form);
    };
}
const browserScrollBarOffset = 32;
const styles = theme => ({
    footerButtons: {
        paddingTop: '16px',
        display: 'flex',
        justifyContent: 'flex-end'
        /*'& > :not(:first-child)': {
            marginLeft: `10px`
        }*/
    },

    footerButtonsContainer: {
        position: 'fixed',
        right: 10,
        bottom: 15,
        zIndex: theme.zIndex.drawer + 2,
        minWidth: 180,
        minHeight: 36,
        maxWidth: '85%',
        boxShadow: 'none !important',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end'
        }
    },
    saveButton: {
        border: '1.5px solid' + theme.palette.button.save,
        borderRadius: 24,
        color: '#ffffff',
        backgroundColor: theme.palette.button.save,
        textTransform: 'none',
        padding: '1.2em 1.5em',
        '& span > svg': {
            height: '20px',
            width: '20px',
            // marginRight: 12,
            fill: '#67F7A6'
        },
        '& span': {
            fontWeight: '400'
        },
        '&:hover': {
            backgroundColor: '#ffffff',
            border: '1.5px solid' + theme.palette.button.save,
            color: theme.palette.button.save,
            '& span': {
                color: theme.palette.button.save
            }
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 36,
            bottom: 0,
            right: 5,
            '& span > span': {
                display: 'block'
            },

            '& span > svg': {
                marginRight: '0.5em'
            }
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },

    root: {
        //paddingBottom: theme.spacing.unit * 2 + 60, //includes button height
        fontSize: 18
    },

    header: {
        fontSize: 36,
        textAlign: 'center',
        fontWeight: 100,
        paddingBottom: theme.spacing.unit * 2
    },

    indicator: {
        backgroundColor: theme.palette.primary.main
    },

    tabDisplayBase: {
        paddingTop: 20,
        backgroundColor: 'black'
    },
    tabContainer: {
        //display: 'flex'
    },
    badgeContainer: {
        display: 'flex',
        '& > *': {
            borderRadius: '50% 50%',
            alignContent: 'center',
            height: '20px',
            width: '20px',
            fontSize: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '3px',
            display: 'none' //flex
        }
    },
    badgeError: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.validation.error
    },
    badgeSuggested: {
        color: '#FFFFFF',
        backgroundColor: theme.palette.validation.suggested
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: theme.sizes.tabHeight
    },
    tabsContainer: {
        height: theme.sizes.tabHeight
    },
    tab: {
        height: theme.sizes.tabHeight
    },
    scrollButtons: {
        flex: '0 0 40px'
    },
    scroller: {
        height: theme.sizes.tabHeight + browserScrollBarOffset,
        '& > :last-child': {
            top: theme.sizes.tabHeight - 3
        }
    },
    tabLabel: {
        whiteSpace: 'pre',
        textTransform: 'none',
        ...theme.typography.subheading
    },
    tabLabelSelected: {
        fontWeight: theme.typography.fontWeightMedium
    },
    content: {
        padding: 20
    },
    typography: {
        padding: theme.spacing.unit * 3
    },

    buttonHolder: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '0 20px'
    },

    firstPageButtons: {
        justifyContent: 'flex-end'
    },

    lastPageButtons: {
        justifyContent: 'flex-start'
    },

    paddedButton: {
        float: 'right',
        marginRight: 10,
        marginTop: 10
    },

    stepperContainer: {
        background: 'none',
        iconColor: 'green' // or logic to change color
    },

    active: {
        background: 'green'
    },

    stepperButton: {
        // transform: 'scale(1.8)',
        // background: theme.palette.contentForeground[theme.funeralHome|| 'none' ], // or logic to change color
        '& svg': {
            width: '2rem',
            height: '2rem',
            color: '#FFFFFF',
            border: '2px solid #d5d5d5',
            borderRadius: '99px'
        },
        '& text': {
            fontSize: '0.6rem',
            fill: '#b3b3b3'
        }
    },

    stepLabel: {
        '@media (max-width: 768px)': {
            display: 'none'
        },

        marginTop: 0,
        fontSize: '0.85rem',
        textAlign: 'center',
        position: 'relative',
        top: '-10px'
    },

    stepContainer: {
        lineHeight: '8px',
        background: 'none',
        marginTop: 0
    },

    stepConnector: {
        borderColor: 'red'
    }
});

export default withStyles(styles, { withTheme: true })(DataFormView);
