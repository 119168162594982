import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../component/form/Grid';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import Checkbox from '../../../component/form/Checkbox';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo/index';
import { applyUrlParams } from '../../../util/strings';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '../../../component/icon/CloseIcon';
import TickIcon from '../../../component/icon/TickIcon';
import QuestionMarkCircleIcon from '../../../component/icon/QuestionMarkCircleIcon';
import IconButton from '@material-ui/core/IconButton';
import { getProperty, isNullOrUndefined, setProperty } from '../../../util/objects';
import { dateTimeToString } from '../../../util/date';
import { SaveButton } from '../../../component/form/PrimaryButton';
import SaveIcon from '../../../component/icon/SaveIcon';
import Icon from '@material-ui/core/Icon';
import { ArrayDiff } from '../../../util/arrays';
import LinearProgressIndicator from '../../../component/form/LinearProgressIndicator';
import { TABS } from '../funeralConstants';
import GatedComponent from '../../../component/GatedComponent';
import { ProgressPieIcon } from '../../../component/icon/ProgressPieIcon';
import Hidden from '@material-ui/core/Hidden';
import Spinner from '../../../component/Spinner';

const funeralTabs = TABS.map(x => {
    return { title: x.title || x.label, key: x.key };
});

class AdminSummary extends Component {
    state = {
        modified: [],
        isSaving: false,
        currentTab: null,
        currentIndex: -1
    };

    componentDidMount() {
        this.selectTab(this.props.currentTab);
    }

    componentWillReceiveProps(nextProps) {
        const { adminSummary, original } = nextProps;
        if (adminSummary && original) {
            const nowChecked = adminSummary.AdminChecks.filter(e => !!e._join.FuneralAdminCheck.Checked).map(e => e.ID);
            const wasChecked = original.AdminChecks.filter(e => !!e._join.FuneralAdminCheck.Checked).map(e => e.ID);
            const { removedItems, addedItems } = ArrayDiff(nowChecked, wasChecked, (a, b) => a === b);
            this.setState({ modified: addedItems.concat(removedItems), isSaving: false });
        }
        this.selectTab(nextProps.currentTab);
    }

    render() {
        const { classes, onSave } = this.props;
        const { modified, isSaving } = this.state;

        return (
            <Fragment>
                <Paper className={classes.paper}>
                    <div className={classes.container}>
                        {this.renderTitle()}
                        <div className={classes.scrollBox}>
                            {this.renderTabItems()}
                            <GatedComponent showComponentCode={'FM_ACCESS_Funeral_Edit'}>
                                {() => {
                                    return (
                                        <div style={{ textAlign: 'right', padding: '16px 0' }}>
                                            <SaveButton
                                                disabled={isSaving || modified.length < 1}
                                                onClick={e => {
                                                    const that = this;
                                                    that.setState({ isSaving: true });
                                                    onSave();
                                                }}
                                                className={classes.greenButt}
                                            >
                                                {isSaving ? (
                                                    <Fragment>
                                                        <Spinner />
                                                        <Hidden smDown>&nbsp;Saving...</Hidden>
                                                    </Fragment>
                                                ) : modified.length > 0 ? (
                                                    <Fragment>
                                                        <SaveIcon />
                                                        <Hidden smDown>&nbsp;Save Checklist</Hidden>
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <Icon>check_circle_outline</Icon>
                                                        <Hidden smDown>&nbsp;Saved</Hidden>
                                                    </Fragment>
                                                )}
                                            </SaveButton>
                                        </div>
                                    );
                                }}
                            </GatedComponent>
                            {this.renderCheckoutProgress()}
                        </div>
                    </div>
                </Paper>
            </Fragment>
        );
    }

    renderTitle() {
        const { classes } = this.props;
        const { currentTab } = this.state;
        const title = funeralTabs.find(e => e.key === currentTab);

        return (
            <Fragment>
                <div className={classes.titleContainer}>
                    <Typography variant="headline" gutterBottom>
                        <span className={classes.pageTitle}>Funeral Checklist:</span>
                        &nbsp;
                        <span className={classes.pageSubtitle}>{title ? title.title || title.label : ''} Tab</span>
                    </Typography>
                    <IconButton onClick={() => this.onClose()}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </Fragment>
        );
    }

    renderTabItems() {
        const { currentIndex } = this.state;
        const { classes, groupedIndices, adminSummary } = this.props;

        if (!groupedIndices) return null;

        return (
            <Grid container spacing={16}>
                <InlineHeader header="Please confirm the following items" />
                {currentIndex >= 0 &&
                    groupedIndices[currentIndex].value.map(index => (
                        <InlineField key={index}>
                            <Checkbox
                                classes={{ label: classes.listItem }}
                                label={adminSummary.AdminChecks[index].Title}
                                checked={
                                    !isNullOrUndefined(adminSummary.AdminChecks[index]._join.FuneralAdminCheck.Checked)
                                }
                                onClick={e => this.onCheckItem(e.target.checked, index)}
                            />
                        </InlineField>
                    ))}
            </Grid>
        );
    }

    renderCheckoutProgress() {
        const { classes, adminSummary, groupedIndices } = this.props;

        if (!groupedIndices) return null;

        const subGroupedIndices = getSubGroupedIndices(groupedIndices);

        let funeralCompletion = 0;

        if (adminSummary && adminSummary.AdminChecks && adminSummary.AdminChecks.length > 0) {
            const filteredAdminChecks = filterAdminChecks(adminSummary.AdminChecks);
            const count = filteredAdminChecks.length;
            const LiveAdminChecksComplete = filteredAdminChecks.filter(x => x._join.FuneralAdminCheck.Checked).length;
            funeralCompletion = count > 0 ? (LiveAdminChecksComplete / count) * 100 : 100;
        } else return null;

        return (
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <Typography variant="headline" gutterBottom>
                        <span className={classes.pageTitle}>Checklist Progress</span>
                    </Typography>
                </Grid>

                {adminSummary.AdminChecks && adminSummary.AdminChecks.length > 0 && (
                    <div style={{ marginBottom: 32, width: '100%' }}>
                        <LinearProgressIndicator progress={funeralCompletion} />
                    </div>
                )}

                <Grid item xs={12}>
                    <div className={classes.progressContainer}>
                        {subGroupedIndices.map((kvp, index) => {
                            const selectedTab = funeralTabs.find(x => x.key === kvp.key);
                            const title = selectedTab && (selectedTab.title || selectedTab.label);
                            const totalItems = kvp.value.length;
                            const completedItems = kvp.value.filter(
                                i => !isNullOrUndefined(adminSummary.AdminChecks[i]._join.FuneralAdminCheck.Checked)
                            ).length;
                            const isComplete = completedItems === totalItems;
                            return (
                                <Fragment key={index}>
                                    <div className={classes.progressRow}>
                                        <span className={classes.tickTitleContainer}>
                                            {isComplete && (
                                                <span className={classes.progressTick}>
                                                    <TickIcon />
                                                </span>
                                            )}
                                            {!isComplete &&
                                                ((completedItems === 0 && (
                                                    <span className={classes.progressQuestionMark}>
                                                        <QuestionMarkCircleIcon />
                                                    </span>
                                                )) || (
                                                    <span className={classes.progressQuestionMark}>
                                                        <ProgressPieIcon value={(completedItems / totalItems) * 100} />
                                                    </span>
                                                ))}
                                            <span className={classes.progressTabName}>{title}</span>
                                        </span>
                                        <span
                                            className={classes.itemsComplete}
                                            style={{ opacity: completedItems === 0 ? 1 : 0.666 }}
                                        >
                                            {completedItems}/{totalItems} items complete
                                        </span>
                                    </div>

                                    {index < subGroupedIndices.length - 1 && (
                                        <div className={classes.progressRow}>
                                            <div className={classes.progressPath} />
                                        </div>
                                    )}
                                </Fragment>
                            );
                        })}
                    </div>
                </Grid>
            </Grid>
        );
    }

    selectTab(tab) {
        const { groupedIndices } = this.props;
        this.setState({ currentTab: tab });

        if (groupedIndices === null || isNullOrUndefined(tab)) return;

        let currentIndex = -1;

        for (let x = 0; x < groupedIndices.length && currentIndex < 0; x++) {
            if (groupedIndices[x].key === tab) currentIndex = x;
        }

        this.setState({ currentIndex });
    }

    goToTab(tab) {
        const { match, history } = this.props;
        const path = applyUrlParams('/funeral/:key/:id/' + tab, match.params);
        history.push(path);
    }

    onClose() {
        const { onClose } = this.props;
        if (onClose) onClose();
    }

    onCheckItem(checked, index) {
        const { modified } = this.state;
        const path = `AdminChecks[${index}]._join.FuneralAdminCheck.Checked`;
        const timestamp = checked ? dateTimeToString(new Date()) : null;

        this.setNestedState(this.props.adminSummary, { [path]: timestamp });

        let found = false;

        for (var i = modified.length - 1; i >= 0; i--) {
            if (modified[i] === index) {
                modified.splice(i, 1);
                found = true;
            }
        }
        if (!found) modified.push(index);
        this.setState({ modified });
    }

    getNestedState = (state, field) => {
        return getProperty(state, field);
    };

    setNestedState = (state, newState) => {
        for (let fieldName in newState) {
            setProperty(state, fieldName, newState[fieldName]);
        }
    };
}

const getSubGroupedIndices = groupedIndices => {
    let results = [];
    for (let x = 0; x < groupedIndices.length; x++) {
        let searching = true;
        for (let y = 0; y < funeralTabs.length && searching; y++) {
            if (groupedIndices[x].key === funeralTabs[y].key) {
                searching = false;
                results.push(groupedIndices[x]);
            }
        }
    }
    return results;
};

const filterAdminChecks = adminChecks => {
    let results = [];
    for (let x = 0; x < adminChecks.length; x++) {
        let searching = true;
        for (let y = 0; y < funeralTabs.length && searching; y++) {
            if (adminChecks[x].TabURLSegment === funeralTabs[y].key) {
                searching = false;
                results.push(adminChecks[x]);
            }
        }
    }
    return results;
};

const styles = ({ palette, typography, breakpoints }) => ({
    titleContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '1rem 1rem 0 2rem',
        '& > :first-child': {
            flexGrow: '1',
            margin: '0.35rem 0'
        }
    },
    scrollBox: {
        overflow: 'auto',
        padding: '0 2rem 2rem',
        [breakpoints.down('md')]: {
            maxHeight: 'calc(100vh - 20rem)',
            overflow: 'auto'
        }
    },
    paper: {
        boxShadow: 'none'
    },
    pageTitle: {
        color: palette.action.active,
        fontSize: '1.25rem'
    },
    pageSubtitle: {
        fontWeight: typography.fontWeightLight,
        color: palette.text.primary,
        fontSize: '1.25rem'
    },
    progressContainer: {
        margin: -6
    },

    tickTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px'
    },

    progressRow: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '12px'
    },

    progressPath: {
        height: '25px',
        width: '1px',
        background: '#CCCCCC',
        margin: '5px 0px 5px 17px'
    },

    progressTick: {
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '35px',
        width: '35px',
        minWidth: '35px',
        background: '#26CC6F',
        color: '#FFFFFF'
    },

    progressTabName: {
        fontWeight: typography.fontWeightLight,
        color: palette.text.primary,
        verticalAlign: 'middle',
        display: 'inline-block',
        lineHeight: 1,
        fontSize: '1.10rem'
    },

    progressQuestionMark: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '35px',
        width: '35px',
        color: '#CCC',
        '& svg': {
            height: '35px',
            width: '35px'
        }
    },

    itemsComplete: {
        alignSelf: 'center',
        fontSize: '0.875rem',
        fontWeight: typography.fontWeightLight,
        textAlign: 'right'
    },

    listItem: {
        '& > span': {
            fontSize: '0.975rem !important',
            fontWeight: 'light'
        }
    },
    greenButt: {
        border: '1px solid' + palette.button.save,
        backgroundColor: palette.button.save,
        '&:hover': {
            backgroundColor: palette.action.hover,
            color: palette.button.save
        }
    },
    svgLabel: {
        marginLeft: 6,
        [breakpoints.down('xs')]: {
            display: 'none'
        }
    }
});

// prettier-ignore
export default compose(
    withRouter,
    withStyles(styles)
)(AdminSummary);
