import gql from 'graphql-tag';
import { getUtilitiesClient } from '../apollo';
import ProductFragment from '../page/fragments/Product';
import { flattenConnection } from './graphql';
import { isNullOrUndefined } from './objects';

export const fallbackImages = {
    // key should match URL segment...
    coffins: [
        'https://kasidaily.b-cdn.net/wp-content/uploads/2017/10/coffin.jpg',
        'https://www.nmfcgs.com/Ashford_6036816_PC_O.jpg',
        'https://www.gatheredhere.com.au/wp-content/uploads/2017/08/Cremation-Capsule.png',
        'https://www.freakingnews.com/pictures/91000/Steve-Jobs-Coffin-91225.jpg'
        //TODO: REMOVE THESE!!!!!!!!!
        /*'https://cdn.shopify.com/s/files/1/1732/8203/products/IMG_0396_clipped_rev_1_grande.jpeg',
        'http://poisonprops.com/wp-content/uploads/2016/02/VC703-Vampire-Coffin_800-768x737.jpg',
        'https://chihuahuazilla.files.wordpress.com/2013/07/toasty.jpg'
        */
    ],
    flowers: [
        'https://www.gardenia.net/rendition.square_200/uploads/plant/1504709618-a24adb9ff90474f62/34311.jpg',
        'https://static4.shop033.com/resources/9F/157599/resized/8B/56971147_200x200.jpg',
        'https://www.bangladeshgift.com/images/products/thumb_316_rose-vase-15.jpg',
        'https://d1ic4altzx8ueg.cloudfront.net/shopping/images/hero/gerbera1.jpg'
    ],
    handles: [
        'https://www.lrtipping.co.uk/onlinestore/Yorkrem-plastic-handles-lb.jpg',
        'https://armenian-nobility.com/wp-content/uploads/2018/08/coffin-latch-new-25-5-x-6-5-cm-zamak-metal-coffin-handles-h006-gold-finish-of-coffin-latch.jpg',
        'https://www.coffin-hardware.com/photo/pl3793531-coffin_fittings_plastic_coffin_handles_funeral_decoration_hp026.jpg'
    ],
    lining: [
        'https://www.lrtipping.co.uk/onlinestore/Yorkrem-plastic-handles-lb.jpg',
        'https://armenian-nobility.com/wp-content/uploads/2018/08/coffin-latch-new-25-5-x-6-5-cm-zamak-metal-coffin-handles-h006-gold-finish-of-coffin-latch.jpg',
        'https://www.coffin-hardware.com/photo/pl3793531-coffin_fittings_plastic_coffin_handles_funeral_decoration_hp026.jpg'
    ],
    fittings: [
        'https://www.coffin-hardware.com/photo/pl3892792-antique_copper_color_funeral_accessories_coffin_fittings_metal_hinge_d040.jpg',
        'https://img.everychina.com/nimg/5d/32/950ed0473a3f446ac84c77ed66fe-300x300-0/plastic_crucifix_plastic_decoration_plastic_cross_for_coffin_coffin_fitting_coffin_decoration.jpg',
        'https://gallery.yopriceville.com/var/resizes/Free-Clipart-Pictures/Easter-Pictures-PNG/Gold_Cross_PNG_Transparent_Clip_Art_Image.png?m=1507172105'
    ],
    'name-plates': [
        'https://3.imimg.com/data3/BU/WX/MY-12000218/gold-name-plate-500x500.jpg',
        'https://deseta.info/wp-content/uploads/2018/05/desk-name-plate-holder-aai-trophies-and-awards-with-regard-to-decor-9.jpg'
    ],
    'personal-touches': [
        'https://www.whitedovereleasecolorado.com/wordpress/wp-content/uploads/2016/03/flying-white-doves-isolated-on-blue.jpg',
        'https://4.bp.blogspot.com/-6vDv7qcAeOU/UCMNyFye_CI/AAAAAAAAAbw/G1NykZwdfqw/s1600/DSC_8311.JPG',
        'https://www.vanishingtattoo.com/tds/images/patriotic/flag_large/flag_australia_005.jpg'
    ],
    'memorial-stationary': [
        'https://www.frazerconsultants.com/wp-content/uploads/2012/01/funeral-stationery-Frazer-Consultants.jpg',
        'https://www.fondremembrance.com/uploads/5/2/9/4/52946983/s499336442647378049_p48_i2_w640.jpeg',
        'https://www.frontrunnerpro.com/css/images/HEADER-IMAGES/mobile-headers/stationery-slide-1-garden.jpg'
    ],
    catering: [
        'https://creativecateringperth.com/wp-content/uploads/2016/03/036.jpg',
        'https://www.iqcatering.com.au/wp-content/uploads/2016/05/sandwich-platter-32-pieces-1030x773.jpg',
        'https://i.pinimg.com/600x315/83/0e/68/830e68622c67c2a495bce07143e3b968.jpg'
    ],
    'newspaper-notices': [
        'https://www.stabroeknews.com/images/2011/08/Alfred-memo-3x2.jpg',
        'https://2.bp.blogspot.com/-sqQsrAWD6Bg/TZ5XsQ6AJCI/AAAAAAAAAjg/pDcnyL8wzTo/s1600/Obituary.jpg',
        'https://www.sylvanfunerals.com.au/wp-content/uploads/2012/11/QT-Notice-Example.jpg'
    ]
};

/**
 * @return {[office, price]} applicable brand/branch ID, price
 * @param {[node]} pricesOverrideEdges array of nodes with each office PriceOverride
 */
export function setPricesPerOffice(pricesOverrideEdges) {
    const priceOverrides = [];
    pricesOverrideEdges.forEach(({ node: officePrice }) => {
        const { BasePrice } = officePrice._join.PriceOverride;
        if (officePrice.Branches && officePrice.Branches.nodes.length) {
            // apply a brand price to all branches
            officePrice.Branches.nodes.forEach(branch => {
                if (-1 === priceOverrides.findIndex(obj => obj.office === branch.ID)) {
                    // only if no branch price
                    priceOverrides.push({ office: branch.ID, price: BasePrice }); // add price as branch
                }
            });
        } else if (officePrice.BrandID && officePrice.BrandID > 0) {
            // apply branch-only prices
            const hasBrandPrice = priceOverrides.findIndex(obj => obj.office === officePrice.BrandID);
            if (-1 !== hasBrandPrice) {
                // we already have a price? Must be from brand.
                priceOverrides.splice(hasBrandPrice, 1); // remove brand price
            }
        }
        priceOverrides.push({ office: officePrice.ID, price: BasePrice }); // add branch price
    });
    return priceOverrides;
}

export function populateConfig(config, data) {
    if (!data) {
        return;
    }

    data.edges.forEach(({ node: product }, i) => {
        // Already have this product in the config so skip it
        if (config.productMap[product.ID]) return;

        const urlSegment = resolveUrlSegment(
            product.ProductCategories.map(x => x.URLSegment).concat([product.Parent.URLSegment])
        );

        const attributeOptions = {};
        const variations = product.Variations.edges.map(({ node: variation }) => ({
            id: variation.ID,
            price: Number(variation.Price),
            priceOverrides: setPricesPerOffice(variation.PriceOverrides.edges),
            legacyKey: variation.LegacyKey,
            gst: variation.GST,
            internalId: variation.InternalItemID,
            title: getVariationDescription(product.Title, variation.AttributeValues),
            attributes: variation.AttributeValues.edges.reduce((acc, { node: attribute }) => {
                const type = attribute.Type;
                acc[type.ID] = attribute.ID;
                if (!config.attributeTypes[type.ID]) {
                    config.attributeTypes[type.ID] = {
                        id: type.ID,
                        label: type.Label
                    };
                }
                if (!attributeOptions[type.ID]) attributeOptions[type.ID] = [];
                if (!attributeOptions[type.ID].some(({ value }) => value === attribute.ID)) {
                    attributeOptions[type.ID].push({
                        value: attribute.ID,
                        label: attribute.Value
                    });
                }
                return acc;
            }, {})
        }));

        const relatedProducts =
            product.RelatedProducts.edges.length > 0
                ? product.RelatedProducts.edges.map(relatedProduct => {
                      return {
                          id: relatedProduct.node.ID
                      };
                  })
                : null;

        const original =
            variations.length === 0
                ? {
                      id: product.ID,
                      price: Number(product.BasePrice),
                      priceOverrides: setPricesPerOffice(product.PriceOverrides.edges),
                      coffinTrim: product.CoffinTrim,
                      quantity: product.Quantity,
                      gst: product.GST,
                      title: product.Title,
                      legacyKey: product.LegacyKey,
                      attributes: []
                  }
                : null;

        const productItem = {
            id: product.ID,
            title: product.Title,
            legacyKey: product.LegacyKey,
            coffinTrim: product.CoffinTrim,
            image:
                product.ImageURL ||
                (urlSegment && fallbackImages[urlSegment]
                    ? fallbackImages[urlSegment][i % fallbackImages[urlSegment].length]
                    : null),
            imagePortrait:
                product.ImagePortraitURL ||
                (urlSegment && fallbackImages[urlSegment]
                    ? fallbackImages[urlSegment][i % fallbackImages[urlSegment].length]
                    : null),
            imageLandscape:
                product.ImageLandscapeURL ||
                (urlSegment && fallbackImages[urlSegment]
                    ? fallbackImages[urlSegment][i % fallbackImages[urlSegment].length]
                    : null),
            original,
            variations,
            relatedProducts,
            variationMap: variations.reduce((map, v) => {
                map[v.id] = v;
                return map;
            }, {}),
            attributeOptions,
            purchase: !!product.AllowPurchase,
            urlSegment: urlSegment,
            internalId: product.InternalItemID
        };

        config.products.push(productItem);
        config.productMap[productItem.id] = productItem;
        if (config.categoryIds[urlSegment]) config.categoryIds[urlSegment].push(productItem.id);
    });
}

export const getProducts = gql`
    query GetProducts($searchTerm: String, $categoryUrlsegment: String, $offset: Int) {
        readProducts(
            contains: $searchTerm
            categoryUrlsegment: $categoryUrlsegment
            #            limit: 12
            offset: $offset
            purchase: true
        ) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    ...Product
                }
            }
        }
    }
    ${ProductFragment}
`;

export const getProductsByIds = gql`
    query GetProductsByIds($ids: [ID]!) {
        readProducts(ids: $ids) {
            edges {
                node {
                    ...Product
                }
            }
        }
    }
    ${ProductFragment}
`;

/**
 * Finds the variation in the config with all attribute values set to the first option.
 */
export function getDefaultVariationId(productId, config, whereFunc) {
    const product = config.productMap[productId];
    const segment = product.urlSegment;
    const metadataKey = Object.keys(ProductMetadata).find(x => ProductMetadata[x].queryKey === segment);

    if (metadataKey && ProductMetadata[metadataKey].defaultVariation && config.productMap[productId].variations) {
        const variation = config.productMap[productId].variations[ProductMetadata[metadataKey].defaultVariation];
        if (variation) {
            return variation.id;
        }
    }

    const { attributeOptions } = config.productMap[productId];
    const defaultAttributes = Object.keys(attributeOptions).reduce((acc, k) => {
        const result = (whereFunc && attributeOptions[k].find(x => whereFunc(x))) || attributeOptions[k][0];

        acc[k] = result.value;

        return acc;
    }, {});
    const variation = getVariationByAttributeValues(productId, defaultAttributes, config);
    return variation ? variation.id : null;
}

/**
 * Find the variation in the config which matches the given set of attribute values.
 */
export function getVariationByAttributeValues(productId, attributes, config) {
    const { variations, attributeOptions } = config.productMap[productId];
    const optionKeys = Object.keys(attributeOptions);
    return variations.find(v => !optionKeys.some(k => v.attributes[k] !== attributes[k]));
}

export function getItemVariation(item, config) {
    const product = config.productMap[item.productId];
    if (!product) return item;
    if (item.variationId && product.variationMap[item.variationId]) {
        return product.variationMap[item.variationId];
    }
    const allVariations = Object.keys(product.variationMap).map(x => product.variationMap[x]);
    if (allVariations.length > 0) {
        return allVariations[0];
    }
    if (product.original) return product.original;
    return item;
}

/**
 * gets the current price of a product, checks for any office price override
 * @param item the cart item, with productID & variationID
 * @param config the product catalogue (for this type of product)
 * @param officeID the office/brand ID (for selecting price overrides)
 * @returns {Number} the price, including GST if necessary
 */
export function getItemPrice(item, config, officeID) {
    const obj = getItemVariation(item, config);
    const product = obj.original || obj;
    const { price } = (product.priceOverrides && product.priceOverrides.find(p => p.office === officeID)) || product;
    return product.gst ? price * (GST + 1) : price;
}

/**
 * calculates the total value of products from a single category
 */
export function getCartTotal(cart, config, officeID) {
    return cart.reduce((acc, item) => acc + getItemPrice(item, config, officeID) * item.quantity, 0);
}

/**
 * calculates the total value of products from different categories (configs)
 */
export function getMixedCartTotal(cart, configs, officeID) {
    return cart.reduce((acc, item) => acc + getItemPrice(item, configs[item.configKey], officeID) * item.quantity, 0);
}

/**
 * government savings tax figure. 10%
 */
export const GST = 0.1;

/**
 * min & max range beyond the base value (eg. 200 means a $300 cost can be altered to be between $100 to $500)
 */
export const price_amendment_range = 2000;

/**
 * flattens booking items
 */
export const flattenBookingItems = (data, propertyName) => {
    if (!data || !data[propertyName]) return;

    data[propertyName] = (data[propertyName].edges || data[propertyName]).map(item => {
        let node = item.node || item;

        let categories;

        if (!isNullOrUndefined(node.Product) && !isNullOrUndefined(node.Product.ProductCategories)) {
            categories = node.Product.ProductCategories.map(x => x.URLSegment);

            if (!isNullOrUndefined(node.Product.Parent))
                categories = categories.concat([node.Product.Parent.URLSegment]);
        } else {
            categories = [];
        }

        return {
            ID: node.ID,
            Quantity: node.Quantity,
            UnitPrice: node.UnitPrice,
            Total: node.Total,
            Title: node.Title || null,
            Comment: node.Comment,
            Returns: node.Returns,
            InternalItemID:
                (!!node.Variation && node.Variation.InternalItemID) ||
                (!!node.Product && node.Product.InternalItemID) ||
                null,
            ProductID: node.ProductID || (!!node.Product && node.Product.ID) || 0,
            VariationID: node.VariationID || (!!node.Variation && node.Variation.ID) || 0,
            URLSegment: node.URLSegment || resolveUrlSegment(categories)
        };
    });
};

export const QueryKeys = {
    // value should match URL segment...
    Coffins: 'coffins',
    Lining: 'linings',
    Handles: 'handles',
    Flowers: 'flowers',
    Fittings: 'fittings',
    NamePlates: 'name-plates',
    PersonalTouches: 'personal-touches',
    MemorialStationery: 'memorial-stationary',
    NewspaperNotices: 'newspaper-notices',
    Catering: 'catering',
    PaymentsMadeOnYourBehalf: 'payments-made-on-your-behalf'
};

const QueryKeysArray = Object.keys(QueryKeys).map(x => QueryKeys[x]);

const resolveUrlSegment = array => {
    for (let x = 0; x < QueryKeysArray.length; x++) {
        if (array.indexOf(QueryKeysArray[x]) >= 0) return QueryKeysArray[x];
    }
    return 'unknown';
};

export const ProductMetadata = {
    Coffins: {
        queryKey: QueryKeys.Coffins,
        maxProductTypeQuantity: 1,
        maxPurchaseQuantity: 1,
        relatedProductsKey: null,
        canBeReturned: false,
        defaultVariation: null
    },
    Handles: {
        queryKey: QueryKeys.Handles,
        maxProductTypeQuantity: 1,
        maxPurchaseQuantity: null,
        relatedProductsKey: null,
        canBeReturned: false,
        defaultVariation: null
    },
    Lining: {
        queryKey: QueryKeys.Lining,
        maxProductTypeQuantity: null,
        maxPurchaseQuantity: null,
        relatedProductsKey: null,
        canBeReturned: false,
        defaultVariation: null
    },
    Fittings: {
        queryKey: QueryKeys.Fittings,
        maxProductTypeQuantity: null,
        maxPurchaseQuantity: null,
        relatedProductsKey: null,
        canBeReturned: true,
        defaultVariation: null
    },
    NamePlates: {
        queryKey: QueryKeys.NamePlates,
        maxProductTypeQuantity: 1,
        maxPurchaseQuantity: 1,
        relatedProductsKey: null,
        canBeReturned: false,
        defaultVariation: null
    },
    PersonalTouches: {
        queryKey: QueryKeys.PersonalTouches,
        maxPurchaseQuantity: 1,
        relatedProductsKey: null,
        canBeReturned: true,
        defaultVariation: null
    },
    Flowers: {
        queryKey: QueryKeys.Flowers,
        maxProductTypeQuantity: null,
        relatedProductsKey: null,
        canBeReturned: false,
        defaultVariation: 1
    },
    MemorialStationery: {
        queryKey: QueryKeys.MemorialStationery,
        maxProductTypeQuantity: null,
        relatedProductsKey: null,
        canBeReturned: false,
        defaultVariation: null
    },
    NewspaperNotices: {
        queryKey: QueryKeys.NewspaperNotices,
        maxProductTypeQuantity: null,
        relatedProductsKey: null,
        canBeReturned: false,
        defaultVariation: null
    },
    Catering: {
        queryKey: QueryKeys.Catering,
        maxProductTypeQuantity: null,
        relatedProductsKey: null,
        canBeReturned: false,
        defaultVariation: null
    },
    PaymentsMadeOnYourBehalf: {
        queryKey: QueryKeys.PaymentsMadeOnYourBehalf,
        maxProductTypeQuantity: null,
        relatedProductsKey: null,
        canBeReturned: false,
        defaultVariation: null
    }
};

export const ProductConfig = {
    products: [],
    productMap: {},
    attributeTypes: {},
    categoryIds: Object.keys(ProductMetadata)
        .map(k => ProductMetadata[k].queryKey)
        .reduce((ids, queryKey) => {
            ids[queryKey] = [];
            return ids;
        }, {})
};

export const CreateProducts = items => {
    if (items && items.length > 0) {
        const ids = items.map(x => x.ProductID);
        return getUtilitiesClient()
            .query({ query: getProductsByIds, variables: { ids } })
            .then(
                res => {
                    try {
                        populateConfig(ProductConfig, res.data.readProducts);
                        return items
                            .map((item, i) => {
                                const product = ProductConfig.productMap[item.ProductID];
                                if (!product) {
                                    console.warn('product was null', item, ProductConfig.productMap);
                                    return null;
                                }
                                return {
                                    label: product.Title,
                                    image:
                                        product.image ||
                                        (fallbackImages[product.urlSegment] &&
                                            fallbackImages[product.urlSegment][
                                                i % fallbackImages[product.urlSegment].length
                                            ]), //todo: remove fallback images
                                    product
                                };
                            })
                            .filter(x => x !== null);
                    } catch (e) {
                        console.error('failed to load products', e);
                    }
                    return [];
                },
                error => console.error('failed', error)
            );
    }
    return [];
};

export const LoadProducts = (categoryUrlsegment, searchTerm) => {
    return getUtilitiesClient()
        .query({ query: getProducts, variables: { categoryUrlsegment, searchTerm } })
        .then(
            res => {
                try {
                    populateConfig(ProductConfig, res.data.readProducts);
                    const ids = res.data.readProducts.edges.map(e => e.node.ID);
                    return ProductConfig.products.filter(x => !!ids.find(e => e === x.id));
                } catch (e) {
                    console.error('failed to load products', e);
                }
                return [];
            },
            error => {
                console.error('failed', error);
                return [];
            }
        );
};

export const PreloadProducts = (who, products = []) => {
    if (!!products && products.length > 0) {
        const unmatched = {};
        if (ProductConfig.products.length > 0) {
            unmatched.list = products.filter(
                p => !!p.ProductID && Number(p.ProductID) > 0 && !ProductConfig.productMap[p.ProductID]
            );
        } else {
            unmatched.list = products.filter(p => !!p.ProductID && Number(p.ProductID) > 0);
        }

        if (unmatched.list.length > 0) {
            return CreateProducts(unmatched.list).then(newList => {
                if (newList && newList.length) who.forceUpdate();
                return newList;
            });
        }
    }
    return Promise.resolve([]);
};

export const getVariationDescription = (productTitle, attributeValues) => {
    let metadata = '';
    const values = { attributeValues: attributeValues };
    flattenConnection(values, 'attributeValues');

    if (!!values.attributeValues && values.attributeValues.length > 0)
        values.attributeValues.forEach(attributeValue => {
            metadata += ` | ${attributeValue.Type.Label}: ${attributeValue.Value}`;
        });

    return `${productTitle}${metadata}`;
};

/**
 * takes a set of field names of objects which contain BookableItems, and retrieves their ProductID and VariationID
 */
export const getProductIds = (fieldNames, form) => {
    if (isNullOrUndefined(form)) {
        return [];
    }

    const products = [];

    fieldNames.forEach(fieldName => {
        const array = form.getField(fieldName);
        if (array && array.length > 0) {
            array
                .filter(item => !isNullOrUndefined(item.BookingItems) && item.BookingItems.length > 0)
                .forEach(item => {
                    item.BookingItems.forEach(({ ProductID, VariationID }) => {
                        products.push({ ProductID, VariationID });
                    });
                });
        }
    });

    return products;
};
