import React, { Component, Fragment } from 'react';
import { Step, StepLabel, Stepper, Typography, withStyles } from '@material-ui/core';
import Checkbox from '../../../../component/form/Checkbox';
import TextField from '../../../../component/form/TextField';
import Grid from '../../../../component/form/Grid';
import DataForm from '../../../../component/form/DataForm';
import Select from '../../../../component/form/Select';
import { VALUABLES_LOCATION_OPTIONS, VALUABLES_TO_BE_PLACE_OPTIONS } from './ValuablesConstants';
import moment from 'moment';
import OptionalTextField from '../../../../component/form/OptionalTextField';
import { validationHelper } from '../../../../util/validation';
import { isNullOrUndefined } from '../../../../util/objects';

class ValuablesModalDetailsTab extends Component {
    render() {
        const { classes } = this.props;

        return <div className={classes.root}>{this.renderAdjustmentList()}</div>;
    }

    renderAdjustmentList() {
        const { mutation, recordId, form, classes } = this.props;

        //this is the data fields what table component expects to see.
        const data = {
            Description: null,
            Note: null,
            ToBePlaced: '',
            CurrentLocation: '',
            CurrentLocationDetail: null,
            Quantity: '',
            ToBeReturned: null,
            WhereToBeReturned: null,
            Signed: null,
            Changes: null
        };

        if (recordId > 0 && form) {
            let recordInFormState = form.getField('valuableItems.id' + recordId);
            if (!!recordInFormState && recordInFormState.ID === recordId) {
                Object.keys(data).forEach(dataKey => {
                    data[dataKey] = recordInFormState[dataKey];
                });
            }
        }

        const loading = false;
        const error = false;

        return (
            <span className={classes.detailsForm}>
                <DataForm
                    id={recordId}
                    loading={loading}
                    error={error}
                    data={data}
                    mutation={mutation}
                    variables={this.cleanupData}
                    onLoad={this.loadData}
                    onSaved={this.savedData}
                    validation={validation}
                >
                    {(form, save) => {
                        const currLoc = form.getField('CurrentLocation');
                        const received = !(currLoc === 'Awaiting Delivery' || !currLoc);
                        return (
                            <Fragment>
                                <Grid container spacing={24}>
                                    <Grid bucket>
                                        <Grid pc={1}>
                                            <Typography variant="title" className={classes.title}>
                                                {!!recordId && recordId > 0
                                                    ? 'Valuable details:'
                                                    : 'Please enter the details:'}
                                            </Typography>
                                        </Grid>
                                        <Grid pc={1}>
                                            <TextField
                                                required={true}
                                                name="Description"
                                                label="Description of the item"
                                                form={form}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required={true}
                                                name="Quantity"
                                                label="Quantity"
                                                form={form}
                                                type={'number'}
                                                inputProps={{ min: '1' }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Select
                                                required={true}
                                                name="ToBePlaced"
                                                label="Where to be placed..."
                                                form={form}
                                                options={VALUABLES_TO_BE_PLACE_OPTIONS}
                                            />
                                        </Grid>

                                        <Grid pc={1}>
                                            <Checkbox name="ToBeReturned" label="To be returned" form={form} />
                                            {!!form.getField('ToBeReturned') && (
                                                <TextField
                                                    name="WhereToBeReturned"
                                                    label="To be returned to..."
                                                    placeholder="Enter location details here..."
                                                    form={form}
                                                />
                                            )}
                                        </Grid>
                                        <Grid pc={1}>
                                            <OptionalTextField
                                                name="Note"
                                                label="Comments"
                                                buttonlabel={'+ Add Comments'}
                                                placeholder={'Enter any comments...'}
                                                form={form}
                                                multiline={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid bucket>
                                        <Grid pc={1}>
                                            <Typography variant="title" className={classes.title}>
                                                Current Status:
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Checkbox
                                                disabled={!received}
                                                name="Signed"
                                                label="Valuables Form is signed"
                                                form={form}
                                            />
                                        </Grid>

                                        <Grid pc={1}>
                                            <Select
                                                required={true}
                                                name={'CurrentLocation'}
                                                form={form}
                                                options={VALUABLES_LOCATION_OPTIONS}
                                                label="Current location of item"
                                                allowNone={false}
                                            />
                                            <br />
                                            <TextField
                                                name="CurrentLocationDetail"
                                                label="Location details"
                                                placeholder="Enter location details here..."
                                                form={form}
                                            />
                                        </Grid>

                                        <Grid pc={1}>
                                            <div className={classes.historyDiv}>
                                                {!!form.getField('Changes') && form.getField('Changes').length > 0 && (
                                                    <Stepper
                                                        style={{
                                                            flexDirection: 'column',
                                                            display: 'flex',
                                                            padding: 0,
                                                            backgroundColor: 'transparent'
                                                        }}
                                                        orientation={'vertical'}
                                                        activeStep={0}
                                                    >
                                                        {form
                                                            .getField('Changes')
                                                            .map((e, i, a) => {
                                                                return (
                                                                    <Step key={i}>
                                                                        <StepLabel StepIconProps={{ icon: 1 + i }}>
                                                                            {e.Location}: {e.LocationDetail}
                                                                            <br />
                                                                            <small>
                                                                                Entered by {e.UserName},
                                                                                {moment(e.Created).format(
                                                                                    'D/MM/YYYY h:mma'
                                                                                )}
                                                                            </small>
                                                                        </StepLabel>
                                                                    </Step>
                                                                );
                                                            })
                                                            .reverse()}
                                                    </Stepper>
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {save}
                            </Fragment>
                        );
                    }}
                </DataForm>
            </span>
        );
    }

    cleanupData = e => {
        const { funeralId, recordId } = this.props;
        if (!(recordId && recordId > 0)) {
            e.FuneralID = funeralId;
        } else {
            e.ID = recordId;
        }
        e.Quantity = Number(e.Quantity);
        delete e.Changes; // don't fiddle with these babies
        if (!!e.WhereToBeReturned) e.WhereToBeReturned = e.WhereToBeReturned.replace(/^\s?to\s/i, '');
        return e;
    };

    loadData = e => {
        if (!!e.Changes && !!e.Changes.edges) e.Changes = e.Changes.edges.map(x => x.node); // reconstruct after saving
        return e;
    };
    savedData = e => {
        if (!!e.createFuneralValuableItem) {
            this.props.onCreated(e.createFuneralValuableItem.ID);
        } else if (!!e.updateFuneralValuableItem) {
            this.props.onCreated(e.updateFuneralValuableItem.ID);
        }
    };
}

const validation = {
    EditValuable: {
        required: ['Description', 'CurrentLocation', 'ToBePlaced'],
        onValidate: {
            Quantity: (newValue, persistedValue, hasValue, getField) => {
                return Number(persistedValue) < 1
                    ? validationHelper.required('Please enter a quantity greater than zero.')
                    : validationHelper.ok();
            },
            WhereToBeReturned: (newValue, persistedValue, hasValue, getField) => {
                const ToBeReturned = getField('ToBeReturned');
                return isNullOrUndefined(persistedValue) && !!ToBeReturned
                    ? validationHelper.required()
                    : validationHelper.ok();
            }
        }
    }
};

const styles = ({ palette, typography, breakpoints }) => ({
    root: {
        maxHeight: 'calc(100vh - 375px)',
        overflow: 'auto',
        padding: 42,
        [breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 175px)',
            padding: 12
        },
        maxWidth: '660px'
    },

    dataList: {
        overflowY: 'auto',
        marginTop: '20px'
    },
    svgLabel: {
        marginLeft: 6,
        [breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    detailsForm: {
        '& > div > form > button': {
            position: 'absolute',
            right: 40,
            bottom: 40,
            minHeight: 38,
            zIndex: 9,
            [breakpoints.down('xs')]: {
                minWidth: 38,
                padding: 8
            }
        },
        '& > div': {
            position: 'unset!important'
        }
    },
    historyDiv: {
        [breakpoints.up('md')]: {
            overflow: 'auto',
            maxHeight: 320
        }
    }
});

export default withStyles(styles)(ValuablesModalDetailsTab);
