import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '../../../component/form/Checkbox';
import Grid from '../../../component/form/Grid';
import Select from '../../../component/form/Select';
import RadioGroup from '../../../component/form/RadioGroup';
import TextField from '../../../component/form/TextField';
import PrimaryButton from '../../../component/form/PrimaryButton';
import AddressAutocomplete from '../../../component/form/AddressAutocomplete';
import { Hidden, IconButton } from '@material-ui/core';
import DeleteIcon from '../../../component/icon/DeleteIcon';
import ComboListAutoComplete from '../../../component/form/ComboListAutoComplete';
import { InlineField, InlineHeader } from '../../../component/form/Inline';
import {
    CHILD_AGE_OPTIONS,
    ChildFieldVerboseNames,
    createMarriageAddressComponents,
    GENDER_OPTIONS,
    LIFE_STATUS_OPTIONS,
    MARITAL_STATUS_OPTIONS,
    MarriageFieldVerboseNames,
    oppositeGender,
    PARENT_TYPE_OPTIONS
} from './FamilyConstants';
import { yearDelta } from '../../../util/date';
import moment from 'moment';
import BiggerMessageBar from '../../../component/BiggerMessageBar';
import TickCircleIcon from '../../../component/icon/TickCircleIcon';
import ValidationPlaceholder from '../../../component/form/ValidationPlaceholder';
import { requirement, validationHelper } from '../../../util/validation';

class Family extends Component {
    state = {
        tempKids: []
    };

    addChild = () => {
        const children = this.getChildren();
        // const { form } = this.props;
        // const surname = form.getField('Surname');

        children.push({
            ID: null,
            Age: '0',
            AgeExtra: 'Years',
            DateOfBirth: '1900-01-01',
            FamilyName: '', //removed surname by request
            GivenName: '',
            Gender: 'Unknown',
            LifeStatus: 'Alive',
            OtherGivenNames: ''
        });

        this.setChildren(children);
    };

    removeChild = i => {
        const children = this.getChildren();
        children.splice(i, 1);
        this.setChildren(children);
    };

    hasChildren = () => {
        return this.getChildren().length > 0;
    };

    onHasChildrenClicked = e => {
        const { form } = this.props;
        if (e.target.checked) {
            if (!this.hasChildren() && this.state.tempKids.length < 1) {
                this.addChild();
            } else {
                form.setField({ ChildrenDetails: this.state.tempKids });
            }
        } else if (!e.target.checked) {
            //            this.openAlertModal('Warning', 'This will remove children from the record. Are you sure?', () =>
            this.setState({ tempKids: form.getField('ChildrenDetails') });
            this.setChildren([]);
            //            );
        }
    };

    getChildren = () => {
        const { form } = this.props;
        if (form.getField('ChildrenDetails')) return form.getField('ChildrenDetails');

        return [];
    };

    setChildren = children => {
        const { form } = this.props;
        form.setField({ ChildrenDetails: children || [] });
    };

    getPreviousMarriages = () => {
        return this.getMarriages().filter((x, i) => i > 0);
    };

    getCurrentRelationship = () => {
        const marriages = this.getMarriages();
        const index = this.getCurrentRelationshipIndex();
        return index !== undefined ? marriages[index] : undefined;
    };

    getCurrentRelationshipIndex = () => {
        return 0;
    };

    getMarriages = () => {
        const { form } = this.props;
        if (form.getField('Marriages')) return form.getField('Marriages');

        return [];
    };

    setMarriages = marriages => {
        const { form } = this.props;
        form.setField({ Marriages: marriages || [] });
    };

    addMarriage = (marriageState = undefined) => {
        const { form } = this.props;
        const gender = form.getField('Gender');
        const marriages = this.getMarriages().map(a => ({ ...a }));

        marriages.push({
            Type: marriageState || 'Divorced',
            Gender: oppositeGender(gender),
            FirstName: '',
            MiddleName: '',
            Surname: '',
            AgeMarried: 0,
            Country: '',
            State: '',
            Suburb: ''
        });

        this.setMarriages(marriages);
    };

    removePreviousMarriage = i => {
        const marriages = this.getMarriages();
        marriages.splice(i, 1);
        this.setMarriages(marriages);
    };

    onHasPreviousMarriageClicked = e => {
        if (e.target.checked && !this.hasPreviousMarriage()) {
            this.addMarriage();
        } else if (!e.target.checked) {
            const index = this.getCurrentRelationshipIndex();
            const marriages = this.getMarriages();
            const newMarriages = index !== undefined ? [marriages[index]] : [];
            this.setMarriages(newMarriages);
        }
    };

    hasPreviousMarriage = () => {
        return this.getPreviousMarriages().length > 0;
    };

    onParentSurnameIsSameAsDeceasedClicked = e => {
        const { form } = this.props;
        const surname = e.target.checked ? form.getField('Surname') : null;

        form.setField({
            'Parent1.Surname': surname,
            'Parent2.Surname': surname
        });
    };

    onSpouseSurnameIsSameAsDeceasedClicked = e => {
        const { form } = this.props;
        const marriages = this.getMarriages();
        const index = this.getCurrentRelationshipIndex();
        marriages[index].CurrentSurname = e.target.checked ? form.getField('Surname') : null;
        this.setMarriages(marriages);
    };

    onCurrentMarriageStatusChanged = e => {
        if (this.getMarriages().length === 0) {
            this.addMarriage(e.target.value);
        }
    };

    showMarriageFields = () => {
        const currentRelationship = this.getCurrentRelationship();
        const lc = currentRelationship && currentRelationship.Type ? currentRelationship.Type.toLowerCase() : undefined;
        switch (lc) {
            case 'married':
            case 'defacto':
            case 'divorced':
            case 'widowed':
                return true;
            case 'never married':
            case 'unknown':
            case undefined:
                return false;
            default:
                throw new Error('unknown marital status ' + lc);
        }
    };

    spouseSurnameSameAsDeceased = () => {
        const { form } = this.props;
        const surname = (form.getField('Surname') || '').toLowerCase();
        const currentRelationship = this.getCurrentRelationship();
        const lc =
            currentRelationship && currentRelationship.CurrentSurname
                ? currentRelationship.CurrentSurname.toLowerCase()
                : undefined;
        return lc === surname;
    };

    parentsSurnameSameAsDeceased = () => {
        const { form } = this.props;
        const surname = (form.getField('Surname') || '').toLowerCase();
        const parent1Surname = (form.getField('Parent1.Surname') || '').toLowerCase();
        const parent2Surname = (form.getField('Parent2.Surname') || '').toLowerCase();
        return parent1Surname === surname && parent2Surname === surname;
    };

    dobIsUnknown = index => {
        const { form } = this.props;
        const childPrefix = `ChildrenDetails[${index}]`;
        return form.getField(`${childPrefix}.DateOfBirth`) === null;
    };

    onDobUnknownChanged = (e, childIndex) => {
        const children = this.getChildren();
        children[childIndex].DateOfBirth = e.target.checked ? null : '1900-01-01';
        this.setChildren(children);
    };

    onDobChanged = (e, childIndex) => {
        const children = this.getChildren();
        const child = children[childIndex];
        const { form } = this.props;
        const DateOfDeath1 = form.getField('DateOfDeath1');
        //const DateOfDeath2 = form.getField('DateOfDeath2');
        const DateOfDeathType = form.getField('DateOfDeathType');

        if (child.DateOfBirth && child.LifeStatus === 'Alive') {
            const dateOfBirth = new Date(e.target.value);
            let deathDate = new Date();
            switch (DateOfDeathType) {
                case 'Approximately':
                case 'On':
                case 'On or about':
                case 'On or After':
                case 'Between':
                    deathDate = new Date(DateOfDeath1);
                    break;
                case 'Unknown':
                default:
                    deathDate = new Date();
                    break;
            }
            child.Age = yearDelta(dateOfBirth, deathDate);
            if (child.Age > 1) {
                child.AgeExtra = 'Years';
                child.Age = String(child.Age);
            } else {
                const delta = ('' + moment(dateOfBirth).from(deathDate)).split(' ');

                child.Age = String(isNaN(delta[0]) ? 1 : delta[0]);
                child.AgeExtra = delta[1].replace(/^(\w)/, e => e.toUpperCase());

                if (['Years', 'Months', 'Weeks', 'Days', 'Hours'].indexOf(child.AgeExtra) === -1)
                    child.AgeExtra = child.AgeExtra + 's';
            }
        }
        this.setChildren(children);
    };

    onAgeChanged = (e, childIndex) => {
        const children = this.getChildren();
        const child = children[childIndex];

        if (child.LifeStatus === 'Alive') {
            const newAge = isNaN(e.target.value) ? child.Age : e.target.value;
            const newCode = !isNaN(e.target.value) ? child.AgeExtra : e.target.value;
            let diff = 0;
            let delta = moment(child.DateOfBirth);
            if (newCode === 'Years') {
                const newYear1 = moment(child.DateOfBirth).year(moment().year());
                const newYear2 = moment().year(moment().year());
                diff = newYear1 - newYear2 < 0 ? 0 : 1; // birthday hasn't happened this year?
            } else {
                delta = moment();
            }
            const newDOB = delta
                .year(moment().year())
                .subtract(Number(newAge) + diff, newCode)
                .format('YYYY-MM-DD');
            child.DateOfBirth = moment(newDOB).isValid() ? newDOB : null;
        }
        this.setChildren(children);
    };

    render() {
        const { form, locked } = this.props;
        const parentsSurnameSameAsDeceased = this.parentsSurnameSameAsDeceased();
        const currentRelationshipIndex = this.getCurrentRelationshipIndex();

        const funeral_changes = form.getField('FamilyChangesFromBDM') || {};
        const prearrangement_changes = form.getField('FamilyChangesFromPrePlan') || {};
        const bdmChangesCount = Object.keys(funeral_changes).length;
        const prePlanChangesCount = Object.keys(prearrangement_changes).length;
        const changesCount = Math.max(bdmChangesCount, prePlanChangesCount);

        return (
            <Fragment>
                {changesCount > 0 && !locked && (
                    <BiggerMessageBar>
                        Client made some changes through {bdmChangesCount > 0 ? 'BDM' : 'Pre-Plan'} form.&nbsp;
                        {
                            <PrimaryButton
                                onClick={bdmChangesCount > 0 ? this.applyBDMChanges : this.applyPrePlanChanges}
                            >
                                <TickCircleIcon />
                                <Hidden smDown> Approve changes</Hidden>
                            </PrimaryButton>
                        }
                    </BiggerMessageBar>
                )}
                <Grid bucket={true}>
                    <Grid pc={1}>
                        <RadioGroup
                            disabled={locked}
                            name={`Marriages[${currentRelationshipIndex}].Type`}
                            label="Current marital status"
                            options={MARITAL_STATUS_OPTIONS}
                            form={form}
                            value={form.getField(`Marriages[${currentRelationshipIndex}].Type`) || 'Unknown'}
                            onChange={this.onCurrentMarriageStatusChanged}
                        />
                    </Grid>

                    {this.showMarriageFields() && this.renderMarriageFields()}
                    <ValidationPlaceholder validationResult={form.getValidation('Marriages', true)}>
                        &nbsp;
                    </ValidationPlaceholder>
                </Grid>

                <Grid bucket={true}>
                    <InlineHeader header="Children">
                        <Checkbox
                            disabled={locked}
                            label="The deceased had children"
                            name="HasChildren"
                            checked={this.hasChildren()}
                            onClick={this.onHasChildrenClicked}
                        />
                    </InlineHeader>

                    {this.hasChildren() ? this.renderChildrenFields() : <br />}
                    <ValidationPlaceholder validationResult={form.getValidation('ChildrenDetails', true)}>
                        &nbsp;
                    </ValidationPlaceholder>
                </Grid>

                <Grid bucket={true}>
                    <InlineHeader header="Parents">
                        <Checkbox
                            disabled={locked}
                            label="Surname same as deceased"
                            name="parentsSurnameSameAsDeceased"
                            checked={parentsSurnameSameAsDeceased}
                            onClick={this.onParentSurnameIsSameAsDeceasedClicked}
                        />
                    </InlineHeader>

                    {this.renderParent('Parent1')}
                    {this.renderParent('Parent2')}
                </Grid>
            </Fragment>
        );
    }

    applyBDMChanges = () => {
        const { form } = this.props;
        const changes = form.getField('FamilyChangesFromBDM');
        form.setField({ ...changes, FamilyChangesFromBDM: {} });
    };

    applyPrePlanChanges = () => {
        const { form } = this.props;
        const changes = form.getField('FamilyChangesFromPrePlan');
        form.setField({ ...changes, FamilyChangesFromPrePlan: {} });
    };

    renderMarriageFields() {
        const { locked } = this.props;
        const marriages = this.getMarriages();
        const spouseSurnameSameAsDeceased = this.spouseSurnameSameAsDeceased();
        const hasPreviousMarriage = this.hasPreviousMarriage();
        const currentRelationshipIndex = this.getCurrentRelationshipIndex();

        return (
            <Fragment>
                <InlineHeader header="Spouse details">
                    <Checkbox
                        disabled={locked}
                        label="Current surname same as deceased"
                        name="spouseSurnameSameAsDeceased"
                        checked={spouseSurnameSameAsDeceased}
                        onClick={this.onSpouseSurnameIsSameAsDeceasedClicked}
                    />
                </InlineHeader>

                {this.renderMarriageDetails(currentRelationshipIndex, `Marriages[${currentRelationshipIndex}].`, null)}

                <InlineHeader header="Previous marriages">
                    <Checkbox
                        disabled={locked}
                        label="The deceased had previous marriages"
                        name="hasPreviousMarriage"
                        onClick={this.onHasPreviousMarriageClicked}
                        checked={hasPreviousMarriage}
                    />
                </InlineHeader>
                {hasPreviousMarriage ? (
                    <Fragment>
                        {marriages.map((obj, i) =>
                            i !== currentRelationshipIndex
                                ? this.renderMarriageDetails(i, `Marriages[${i}].`, () =>
                                      this.removePreviousMarriage(i)
                                  )
                                : undefined
                        )}
                    </Fragment>
                ) : (
                    <br />
                )}
                {hasPreviousMarriage && (
                    <InlineField>
                        <PrimaryButton disabled={locked} onClick={() => this.addMarriage()}>
                            + Add Previous Marriage
                        </PrimaryButton>
                    </InlineField>
                )}
            </Fragment>
        );
    }

    renderMarriageDetails(index, propertyBase, removeMarriage) {
        const { form, classes, locked } = this.props;
        const marriageAddressComponents = createMarriageAddressComponents(index);
        const spouseSurnameSameAsDeceased = this.spouseSurnameSameAsDeceased();
        const maritalStatusOptions = MARITAL_STATUS_OPTIONS.filter(
            e => ['Defacto', 'Never married'].indexOf(e.value) < 0
        ).map(e => {
            const obj = { ...e };
            if (obj.value === 'Married') obj.label = 'Marriage not ended';
            return obj;
        });
        const changes = form.getField('FamilyChangesFromBDM') || form.getField('FamilyChangesFromPrePlan') || {};
        const validationResult = validationHelper.ok();
        if (!!changes && !!changes.Marriages && changes.Marriages.length > 0) {
            const marriageID = form.getField(`${propertyBase}ID`);
            const thatMarriage = changes.Marriages.filter(value => value.ID === marriageID);
            if (thatMarriage.length === 0) {
                validationResult.requirement = requirement.optional;
                validationResult.message = 'That marriage has been removed by the client';
            } else {
                const marriageChanges = [];
                MarriageFieldVerboseNames.forEach(element => {
                    const original = thatMarriage[0][element.Field];
                    const change = form.getField(`${propertyBase}${element.Field}`);
                    if (original !== change && (!!original || !!change)) {
                        marriageChanges.push(`${element.Name}: ${thatMarriage[0][element.Field]}`);
                    }
                });
                if (marriageChanges.length > 0) {
                    validationResult.requirement = requirement.optional;
                    validationResult.message = 'That marriage has been changed (' + marriageChanges.join('; ') + ')';
                }
            }
        }

        return (
            <ValidationPlaceholder validationResult={validationResult}>
                <InlineField key={index} className={classes.listItem}>
                    <Grid container spacing={24}>
                        {!!removeMarriage && (
                            <Grid item xs>
                                <Select
                                    disabled={locked}
                                    label="Reason ended"
                                    name={`${propertyBase}Type`}
                                    form={form}
                                    allowNone={false}
                                    options={maritalStatusOptions}
                                />
                            </Grid>
                        )}
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="First name"
                                name={`${propertyBase}FirstName`}
                                form={form}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="Middle name(s)"
                                name={`${propertyBase}MiddleName`}
                                form={form}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="Surname before this marriage"
                                name={`${propertyBase}Surname`}
                                form={form}
                            />
                        </Grid>
                        {!removeMarriage && !spouseSurnameSameAsDeceased && (
                            <Grid item xs>
                                <TextField
                                    disabled={locked}
                                    titleCase
                                    label="Current Surname"
                                    name={`${propertyBase}CurrentSurname`}
                                    form={form}
                                />
                            </Grid>
                        )}

                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                type="number"
                                inputProps={{ min: '0' }}
                                label="Age married"
                                name={`${propertyBase}AgeMarried`}
                                form={form}
                            />
                        </Grid>
                        <Grid item xs>
                            <Select
                                disabled={locked}
                                label="Gender"
                                name={`${propertyBase}Gender`}
                                form={form}
                                options={GENDER_OPTIONS}
                            />
                        </Grid>
                        <Grid item xs>
                            <AddressAutocomplete
                                disabled={locked}
                                onlySuburbs={true}
                                id={`${propertyBase}`}
                                label="Place of Marriage"
                                placeholder="Search for an address"
                                componentFields={marriageAddressComponents}
                                form={form}
                                allowCustomAddress={true}
                            />
                        </Grid>
                        {!!removeMarriage && !locked && (
                            <Grid item className={classes.alignWithLabel}>
                                <Grid item className={classes.alignChildrenRight}>
                                    <IconButton title={'Delete'} onClick={removeMarriage}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </InlineField>
            </ValidationPlaceholder>
        );
    }

    renderChildrenFields() {
        const { locked } = this.props;
        const children = this.getChildren();

        return (
            <Fragment>
                {children.map((c, i) => this.renderChild(i))}
                <InlineField>
                    <PrimaryButton disabled={locked} onClick={this.addChild}>
                        + Add child
                    </PrimaryButton>
                </InlineField>
            </Fragment>
        );
    }

    renderChild(index) {
        const { form, classes, locked } = this.props;

        const childPrefix = `ChildrenDetails[${index}]`;
        var dobUnknown = this.dobIsUnknown(index);
        var ded = form.getField(`${childPrefix}.LifeStatus`) !== 'Alive';
        var stillborn = form.getField(`${childPrefix}.LifeStatus`) === 'Stillborn';
        const changes = form.getField('FamilyChangesFromBDM') || form.getField('FamilyChangesFromPrePlan') || {};
        const validationResult = validationHelper.ok();
        if (changes.ChildrenDetails !== undefined) {
            const childID = form.getField(`${childPrefix}.ID`);
            const thatChild = changes.ChildrenDetails.filter(value => value.ID === childID);
            if (thatChild.length === 0) {
                validationResult.requirement = requirement.optional;
                validationResult.message = 'That child has been removed by the client';
            } else {
                const childChanges = [];
                ChildFieldVerboseNames.forEach(element => {
                    const original = thatChild[0][element.Field];
                    const change = form.getField(`${childPrefix}.${element.Field}`);
                    if (original !== change && (!!original || !!change)) {
                        childChanges.push(`${element.Name}: ${thatChild[0][element.Field]}`);
                    }
                });
                if (childChanges.length > 0) {
                    validationResult.requirement = requirement.optional;
                    validationResult.message = 'That child has been changed (' + childChanges.join('; ') + ')';
                }
            }
        }

        return (
            <ValidationPlaceholder key={index} validationResult={validationResult}>
                <InlineField className={classes.listItem}>
                    <Grid container spacing={24}>
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="First name"
                                name={`${childPrefix}.GivenName`}
                                form={form}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="Middle name(s)"
                                name={`${childPrefix}.OtherGivenNames`}
                                form={form}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                disabled={locked}
                                titleCase
                                label="Surname"
                                name={`${childPrefix}.FamilyName`}
                                form={form}
                            />
                        </Grid>
                        {1 === 2 && (
                            <Grid item xs>
                                <TextField
                                    disabled={locked}
                                    titleCase
                                    label="Surname at birth"
                                    name={`${childPrefix}.FamilyNameAtBirth`}
                                    form={form}
                                />
                            </Grid>
                        )}
                        <Grid item xs>
                            <Select
                                disabled={locked}
                                allowNone={false}
                                name={`${childPrefix}.Gender`}
                                form={form}
                                options={GENDER_OPTIONS}
                                label={'Gender'}
                            />
                        </Grid>
                        <Grid item xs>
                            <Select
                                disabled={locked}
                                allowNone={false}
                                name={`${childPrefix}.LifeStatus`}
                                form={form}
                                options={LIFE_STATUS_OPTIONS}
                                label={'Life status'}
                            />
                        </Grid>

                        {!dobUnknown && (
                            <Grid item xs>
                                <TextField
                                    disabled={locked}
                                    label="Date of birth"
                                    name={`${childPrefix}.DateOfBirth`}
                                    type="date"
                                    notFuture
                                    form={form}
                                    onChange={e => this.onDobChanged(e, index)}
                                />
                            </Grid>
                        )}

                        <Grid item xs className={classes.alignWithFloatingLabel}>
                            <Checkbox
                                disabled={locked}
                                label="Date&nbsp;of&nbsp;Birth unknown"
                                name="dobUnknown"
                                onChange={e => this.onDobUnknownChanged(e, index)}
                                checked={dobUnknown}
                            />
                        </Grid>

                        {(!!dobUnknown || !!ded || !ded) && !stillborn && (
                            <Grid item xs>
                                <InlineField>
                                    <TextField
                                        disabled={locked}
                                        className={classes.kidAge}
                                        label="Age"
                                        name={`${childPrefix}.Age`}
                                        type="number"
                                        inputProps={{ min: '0' }}
                                        form={form}
                                        onChange={e => this.onAgeChanged(e, index)}
                                        //disabled={!ded}
                                    />
                                    <Select
                                        disabled={locked}
                                        className={classes.alignWithFloatingLabel}
                                        //disabled={!ded}
                                        label={''}
                                        form={form}
                                        options={CHILD_AGE_OPTIONS}
                                        name={`${childPrefix}.AgeExtra`}
                                        onChange={e => this.onAgeChanged(e, index)}
                                    />
                                </InlineField>
                            </Grid>
                        )}
                        {!locked && (
                            <Grid item className={classes.alignWithLabel}>
                                <Grid item className={classes.alignChildrenRight}>
                                    <IconButton title={'Delete'} onClick={() => this.removeChild(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </InlineField>
            </ValidationPlaceholder>
        );
    }

    renderParent(property) {
        const { form, classes, locked } = this.props;

        return (
            <InlineField key={property} className={classes.listItem}>
                <Grid container spacing={24}>
                    <Grid item xs>
                        <Select
                            disabled={locked}
                            label={'Parent Type'}
                            allowNone={false}
                            //className={classes.alignWithFloatingLabel}
                            name={`${property}.Type`}
                            form={form}
                            options={PARENT_TYPE_OPTIONS}
                        />
                    </Grid>
                    {'Parent' === form.getField(`${property}.Type`) && (
                        <Grid item xs>
                            <Select
                                disabled={locked}
                                label={'Gender'}
                                allowNone={false}
                                //className={classes.alignWithFloatingLabel}
                                name={`${property}.Gender`}
                                form={form}
                                options={GENDER_OPTIONS}
                            />
                        </Grid>
                    )}
                    <Grid item xs>
                        <TextField
                            disabled={locked}
                            titleCase
                            label="First name"
                            name={`${property}.FirstName`}
                            form={form}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            disabled={locked}
                            titleCase
                            label="Middle name(s)"
                            name={`${property}.MiddleName`}
                            form={form}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            disabled={locked}
                            titleCase
                            label="Surname at birth"
                            name={`${property}.SurnameAtBirth`}
                            form={form}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            disabled={locked}
                            titleCase
                            label="Surname"
                            name={`${property}.Surname`}
                            form={form}
                        />
                    </Grid>
                    <Grid item xs>
                        <ComboListAutoComplete
                            disabled={locked}
                            placeholder="Occupation"
                            name={`${property}.Occupation`}
                            form={form}
                            className={classes.alignWithFloatingLabel}
                            category={'OCCUPATION'}
                        />
                    </Grid>
                </Grid>
            </InlineField>
        );
    }
}

const styles = () => ({
    alignWithFloatingLabel: {
        marginTop: '16px'
    },
    alignWithLabel: {
        marginTop: '10px'
    },
    padButton: {
        margin: '15px 0'
    },
    kidAge: {
        maxWidth: '5rem',
        marginRight: -10
    },
    alignChildrenRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    listItem: {
        width: '100%',
        padding: '0 6px 0 !important',
        margin: '6px 6px 0',
        borderRadius: 5,
        '& > div ': {
            marginBottom: '16px!important',
            marginTop: 0
        },
        '& > div > div': {
            paddingBottom: '0!important',
            '& input': {
                minWidth: 60
            },
            '& input[type=text]': {
                minWidth: 200
            },
            '& select': {
                minWidth: 80
            }
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.035)'
        }
    }
});

export default withStyles(styles)(Family);
