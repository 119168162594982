import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const LinearProgressIndicator = ({ classes, progress, children, ...props }) => {
    const indicatorStyle = {
        marginLeft: `${progress}%`
        // float: 'right'
    };

    return (
        <div>
            <span style={{ width: '100%' }}>
                <span className={classes.indicator} style={indicatorStyle}>
                    <div />
                    <span>{Math.round(progress)}%</span>
                </span>
                <LinearProgress className={classes.progressBar} variant="determinate" value={progress} />
            </span>
        </div>
    );
};

const styles = ({ palette, funeralHome }) => ({
    indicator: {
        background: '#14A554',
        borderRadius: '5px',
        color: '#FFFFFF',
        padding: '3px 0',
        fontSize: '12px',
        display: 'inline-block',
        lineHeight: 'normal',
        transform: 'translate(-20px)',
        width: '40px',
        marginBottom: '10px',
        '& > span': {
            width: '100%',
            display: 'inline-block',
            textAlign: 'center'
        },
        '& > div': {
            background: '#14a554',
            display: 'inline-block',
            width: 10,
            height: 10,
            position: 'absolute',
            transform: 'rotate(45deg)',
            bottom: -4,
            zIndex: -1,
            left: '38%'
        }
    },

    progressBar: {
        height: '18px',
        '& > :first-child': {
            height: '18px'
        }
    }
});

export default withStyles(styles)(LinearProgressIndicator);
