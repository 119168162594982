export const MUSIC_OPTIONS = [
    { label: 'None', value: '' },
    { label: 'Family', value: 'family' },
    { label: 'Funeral director', value: 'director' },
    { label: 'Musician', value: 'musician' }
];

export const LIFESTORY_OPTIONS = [
    // { label: 'No, there will not be a lifestory presentation.', value: 'N' },
    { label: 'Customer is providing the presentation.', value: 'V' },
    { label: 'Photo files scanned by family, converted to video by us.', value: 'P' },
    { label: 'Funeral home is creating the presentation.', value: 'C' }
];

export const DELIVERY_OPTIONS = [
    { label: 'Deliver to Funeral Home', value: 'Deliver to Funeral Home' },
    { label: 'DropBox', value: 'DropBox' },
    { label: 'Scanning', value: 'Scanning' },
    { label: 'USB', value: 'USB' }
];

export const FLAG_TYPES = [
    { label: 'Australian', value: 'Australian' },
    { label: 'White Ensign', value: 'White Ensign' },
    { label: 'Union Jack', value: 'Union Jack' },
    { label: 'Other', value: 'Other' }
];

export const MUSIC_TYPES = [
    { type: 'EnterMusic', label: 'Enter music', list: 'EnterMusicSong' },
    { type: 'ReflectionMusic', label: 'Reflections music', list: 'ReflectionMusicSongs' },
    { type: 'ExitMusic', label: 'Exit music', list: 'ExitMusicSongs' },
    { type: 'GravesideMusic', label: 'Graveside music', list: 'GravesideMusicSongs' }
];
