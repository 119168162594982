import React, { Component, Fragment } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import TableData from '../form/TableData';
import TextField from '../form/TextField';
import CloseIcon from '../icon/CloseIcon';
import cx from 'classnames';
import { prettyPrice } from '../../util/strings';
import Dialog from '@material-ui/core/Dialog';
import cloneDeep from 'lodash.clonedeep';
import { GST, PreloadProducts, price_amendment_range, ProductConfig } from '../../util/products';
import { OutlineButton, SaveButton } from '../form/PrimaryButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '../form/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import UsdCircleIcon from '../icon/UsdCircleIcon';
import { getClient, getUtilitiesClient } from '../../apollo';
import gql from 'graphql-tag';
import { PurchaseOrderBooking, PurchaseOrderObject } from '../../page/fragments/PurchaseOrders';
import LineItemAutoComplete from '../form/LineItemAutoComplete';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { compose } from 'react-apollo';
import { getProperty, isNullOrUndefined, limitRange, round } from '../../util/objects';
import Spinner from '../Spinner';
import { lookupItemSource } from '../../page/quotes/quotes-modal/QuotesModal';
import { WhiteLoader } from '../WhiteLoader';
import Checkbox from '../form/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import { isRelatedObjectDefined } from '../../util/bookable';
import { ProductPriceOverrides, VariationPriceOverrides } from '../../page/fragments/Product';

class PurchaseOrderModal extends Component {
    state = {
        busy: false,
        sendingXero: false,
        submitBtnDisabled: true,
        booking: null,
        templated: false, // preset bill from billTemplate
        canAlter: false // if booking already has items, can't add additional fees.
    };

    static getDerivedStateFromProps(nextProps, oldState) {
        if (!nextProps.open) return { templated: false, booking: null, sendingXero: false };
        if (!oldState.booking) {
            if (!!nextProps.booking) {
                // copy booking data from props into bill
                const booking = cloneDeep(nextProps.booking);
                const items =
                    !!booking.BookingItems && booking.BookingItems.edges
                        ? booking.BookingItems.edges.map(e => e.node)
                        : booking.BookingItems || [];
                booking.BookingItems = items;
                const noItems = !(items.length > 0 && items.some(e => Number(e.Quantity) > 0));
                return {
                    booking,
                    submitBtnDisabled: noItems,
                    canAlter: !items || items.length < 1 || !!nextProps.canAdd
                };
            }
            return { booking: null, submitBtnDisabled: true };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        const that = this;
        const { booking, busy, templated } = that.state;
        const { contact } = that.props;
        if (isNullOrUndefined(booking) || isNullOrUndefined(booking.BookingItems)) return;
        if (booking.BookingItems.length === 0 && !templated && !!contact) {
            const presets = async () => {
                return await getUtilitiesClient().query({ query: BillTemplatesQuery });
            };

            presets().then(({ data }) => {
                const presetBooking = cloneDeep(booking);
                if (!!data && !!data.readBillTemplates && !!data.readBillTemplates.edges) {
                    const myTemplate = data.readBillTemplates.edges.find(
                        e => e.node.Contact.ID === contact.ID && !e.node.NotCurrent
                    );
                    if (!!myTemplate) {
                        presetBooking.BookingItems = myTemplate.node.BillTemplateItems.map(selected => {
                            const item = {
                                ID: null,
                                ProductID: !!selected.Product ? selected.Product.ID : null,
                                VariationID: !!selected.Variation ? selected.Variation.ID : null
                            };
                            const source = lookupItemSource(selected);
                            const price = source.Cost;
                            item.Price = price * (!!source.GST ? GST + 1 : 1); //incGST
                            item.UnitPrice = price; //exGST
                            item.GST = source.GST;
                            item.Code = source.Code;
                            item.Quantity = !selected.Optional ? selected.Qty : 0;
                            item.Title = source.Title;
                            return item;
                        });
                    }
                }
                const result = { templated: true, canAlter: true };
                if (presetBooking && !!presetBooking.BookingItems && presetBooking.BookingItems.length > 0) {
                    result.booking = presetBooking;
                    result.submitBtnDisabled = false;
                    that.setState(result);
                }
            });
        }

        const unloadedProducts = booking.BookingItems.filter(e =>
            isNullOrUndefined(ProductConfig.productMap[e.ProductID])
        );
        if (unloadedProducts.length > 0) {
            if (!busy) {
                that.setState({ busy: true }, () =>
                    PreloadProducts(that, unloadedProducts).then(() => that.setState({ busy: false }))
                );
            }
        }
    }

    render() {
        const { classes, open, className } = this.props;
        if (!!open)
            return (
                <Dialog
                    classes={{ paper: cx(className, classes.root) }}
                    open={open}
                    aria-labelledby={'PurchaseOrderModal'}
                >
                    <form noValidate onSubmit={e => this.onSubmit(e)}>
                        {this.renderForm()}
                    </form>
                </Dialog>
            );
        return null;
    }

    renderForm() {
        const { classes, label, contact } = this.props;
        const { booking, sendingXero, submitBtnDisabled, canAlter } = this.state;
        const items = (booking && booking.BookingItems) || [];
        const noItems = !(items.length > 0 && items.some(e => Number(e.Quantity) > 0));
        return (
            <Fragment>
                <DialogTitle className={classes.diagHeader}>
                    <UsdCircleIcon className={classes.starButton} />
                    <div className={classes.diagHeaderTitle}>
                        {label || 'Supplier'}:<strong> {contact.Name}</strong>
                    </div>
                    <div className={classes.diagHeaderSubtitle}>Create a new bill</div>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid container spacing={24} pc={1} style={{ margin: 0 }}>
                        {canAlter && (
                            <Grid item xs={12}>
                                {this.renderServiceLookup()}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {!!booking &&
                                !!booking.BookingItems &&
                                booking.BookingItems.length > 0 &&
                                this.renderAdjustmentList()}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.diagActions}>
                    <OutlineButton onClick={() => this.onCancel()}>
                        <CloseIcon />
                        <Hidden xsDown>&nbsp;Close</Hidden>
                    </OutlineButton>

                    <SaveButton type="submit" disabled={sendingXero || submitBtnDisabled || noItems}>
                        {sendingXero ? (
                            <Fragment>
                                <Spinner />
                                <Hidden xsDown>&nbsp;Submitting...</Hidden>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <UsdCircleIcon />
                                <Hidden xsDown>&nbsp;Submit To Xero</Hidden>
                            </Fragment>
                        )}
                    </SaveButton>
                </DialogActions>
            </Fragment>
        );
    }

    renderServiceLookup() {
        const { booking } = this.state;
        return (
            <Fragment>
                <LineItemAutoComplete
                    variations
                    label={'Search for a service fee...'}
                    onSelect={selected => {
                        const item = {
                            ID: null,
                            ProductID: !!selected.Product ? selected.Product.ID : null,
                            VariationID: !!selected.Variation ? selected.Variation.ID : null
                        };
                        const source = lookupItemSource(selected);
                        const price = source.Cost;
                        item.Price = price * (!!source.GST ? GST + 1 : 1); //incGST
                        item.UnitPrice = price; //exGST
                        item.GST = source.GST;
                        item.Code = source.Code;
                        item.Quantity = 1;
                        item.Title = source.Title;
                        if (booking) {
                            const list = booking.BookingItems || [];
                            list.push(item);
                            booking.BookingItems = list;
                        }
                        this.setState({ submitBtnDisabled: false, booking });
                    }}
                />
                {!(!!booking && !!booking.BookingItems && !!booking.BookingItems.length > 0) && (
                    <p>Please search for a service fee to add to this bill.</p>
                )}
            </Fragment>
        );
    }

    onChangeQty(rowIndex, stringValue) {
        const { booking } = this.state;
        if (
            isNullOrUndefined(booking) ||
            isNullOrUndefined(booking.BookingItems) ||
            isNullOrUndefined(booking.BookingItems[rowIndex])
        )
            return;
        booking.BookingItems[rowIndex].Quantity = stringValue;
        this.setState({ booking });
    }

    onChangePrice(rowIndex, stringValue) {
        const { booking } = this.state;

        if (
            isNullOrUndefined(booking) ||
            isNullOrUndefined(booking.BookingItems) ||
            isNullOrUndefined(booking.BookingItems[rowIndex])
        )
            return;
        booking.BookingItems[rowIndex].Price = stringValue;
        this.setState({ booking });
    }

    onBlurPrice(rowIndex, stringValue) {
        const { booking } = this.state;

        if (
            isNullOrUndefined(booking) ||
            isNullOrUndefined(booking.BookingItems) ||
            isNullOrUndefined(booking.BookingItems[rowIndex])
        )
            return;

        const limitedValue = limitRange(
            stringValue,
            booking.BookingItems[rowIndex].UnitPrice,
            price_amendment_range,
            true
        );
        if (isNaN(limitedValue)) {
            return;
        }

        booking.BookingItems[rowIndex].Price = Number(limitedValue);
        this.setState({ booking });
    }

    renderAdjustmentList() {
        const { classes, canEdit, disabled } = this.props;
        const { booking, canAlter, busy } = this.state;

        const { productMap } = ProductConfig;
        let orderTotal = 0;
        const data = booking.BookingItems
            //product might not be loaded
            .filter(e => !isNullOrUndefined(productMap[e.ProductID]))
            .map(e => {
                const needGST = !!e.GST || (!!e.Product && e.Product.GST);
                const price = round(e.UnitPrice * (!!needGST ? GST + 1 : 1), 2); //incGST
                const displayData = {
                    data: {
                        Code: e.Code ? e.Code : e.Product ? productMap[e.ProductID].internalId : e.InternalItemID,
                        Description: e.Title || productMap[e.ProductID].title,
                        Cost: price,
                        Price: !isNullOrUndefined(e.Price) ? e.Price : price,
                        Qty: Number(e.Quantity).toFixed(0),
                        needGST: !!needGST
                    }
                };
                displayData.data = PurchaseOrderModal.reCalcTable(displayData.data);
                orderTotal += Number(displayData.data.Price) * Number(displayData.data.Qty);
                return displayData.data;
            });

        const columns = [
            { id: 'Code', label: 'Product Code' },
            { id: 'Description', label: 'Description of product or service' },
            {
                id: 'Cost',
                label: 'Cost',
                render: val => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right' }
            },
            {
                id: 'Price',
                label: 'Price',
                render: (val, row, rowIndex) => {
                    const gstPrice = data[rowIndex].Price;
                    return (
                        <TextField
                            onChange={e => this.onChangePrice(rowIndex, e.target.value)}
                            onBlur={e => this.onBlurPrice(rowIndex, e.target.value)}
                            style={{ width: 100, margin: '-8px 0' }}
                            type="number"
                            value={gstPrice}
                        />
                    );
                },
                styles: { width: 1 }
            },
            { id: 'Qty', label: 'QTY', styles: { textAlign: 'right', width: 1 } },
            {
                id: 'SubTotal',
                label: 'Subtotal (exGST)',
                render: val => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right', width: '5.5rem' }
            },
            {
                id: 'GST',
                label: 'GST',
                render: (val, row) => {
                    return !!row.needGST ? prettyPrice(val) : 'N/A';
                },
                styles: { textAlign: 'right', width: '5rem' }
            },
            {
                id: 'Total',
                label: 'Total',
                render: val => {
                    return prettyPrice(val);
                },
                styles: { textAlign: 'right', width: '5.5rem' }
            },
            {
                id: 'Qty',
                label: 'Include',
                render: (val, row, rowIndex) => {
                    return (
                        <Checkbox
                            checked={!!Number(val)}
                            onChange={() => this.onChangeQty(rowIndex, Number(val) ? '0' : '1')}
                            disabled={!canAlter}
                        />
                    );
                }
            }
        ];

        if (!canEdit || disabled)
            columns.forEach((e, i, a) => {
                if (e.id === 'Price') delete a[i];
            });

        //if (!canAlter) columns.pop();

        return (
            <div style={{ position: 'relative' }}>
                {!!busy && <WhiteLoader />}
                <Typography variant={'display1'}>Bill Total: {prettyPrice(orderTotal)}</Typography>
                <div className={classes.dataList}>
                    <TableData
                        columns={columns}
                        data={data}
                        footer={[
                            { id: 'SubTotal', label: 'TOTAL' },
                            {
                                id: 'GST',
                                render: (column, data) => {
                                    const sum = data
                                        .map(obj => {
                                            return 1 * obj['GST'];
                                        })
                                        .reduce((sum, obj) => {
                                            return sum + obj;
                                        });
                                    return prettyPrice(sum);
                                }
                            },
                            {
                                id: 'Total',
                                render: (column, data) => {
                                    const sum = data
                                        .map(obj => {
                                            return 1 * obj['Total'];
                                        })
                                        .reduce((sum, obj) => {
                                            return sum + obj;
                                        });
                                    return prettyPrice(sum);
                                },
                                styles: { fontWeight: 'bolder' }
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }

    static reCalcTable(data) {
        data.SubTotal = (data.Qty * 1 * (!!data.needGST ? data.Price / (GST + 1) : data.Price)).toFixed(2);
        data.GST = !!data.needGST ? (data.SubTotal * 0.1).toFixed(2) : 0;
        // data.Price = Number(data.Price).toFixed(2); // can't do this here, it interferes with typing
        data.Total = Number(data.Qty * data.Price).toFixed(2);
        return data;
    }

    onCancel = reload => {
        this.props.onClose(reload);
        //this.clearState();
    };

    clearState() {
        this.setState({ booking: null, sendingXero: false, templated: false });
    }

    onSubmit(e) {
        e.preventDefault();

        const { funeralID, mutation, purchaseOrderField, eventObj } = this.props;
        const { booking, sendingXero } = this.state;

        if (sendingXero) return;

        this.setState({ sendingXero: true });

        createPurchaseOrder(booking, eventObj, purchaseOrderField, funeralID, mutation).then(
            mutation => {
                if (mutation.data) {
                    const payloadProperty = Object.keys(mutation.data)[0];
                    const payload = mutation.data[payloadProperty];
                    const purchaseOrder = getProperty(payload, purchaseOrderField);

                    if (!purchaseOrder) {
                        console.error('unexpected result: ', mutation.data);
                        this.onGqlError('Um, server returned no data?', 'no data returned');
                        return;
                    }

                    return submitToXero(purchaseOrder.ID).then(
                        moreData => {
                            if (moreData && moreData.data.submitPurchaseOrderToXero) {
                                this.props.setSnackbarMessage('Success, your bill was submitted.', true);
                                this.onCancel(true);
                            }
                        },
                        subError => {
                            this.onGqlError('Created bill, but failed to submit to xero.', subError);
                            this.onCancel(true);
                        }
                    );
                }

                this.onGqlError('Um, server returned no data?', 'no data returned');
            },
            error => this.onGqlError('Failed to create bill.', error)
        );
    }

    onGqlError(action, error) {
        this.setState({ sendingXero: false });
        if (error) console.error(error);
        this.props.setSnackbarMessage(action, false, null, new Error(error));
    }
}

const submitToXeroMutation = gql`
    ${PurchaseOrderObject}
    mutation SubmitToXero($id: ID!) {
        submitPurchaseOrderToXero(ID: $id) {
            ...PurchaseOrderObject
        }
    }
`;

const submitToXero = async ID => {
    let asyncQuery;

    asyncQuery = await getClient().mutate({
        mutation: submitToXeroMutation,
        variables: {
            id: ID
        }
    });

    return asyncQuery;
};

const createPurchaseOrder = async (booking, eventObj, purchaseOrderField, funeralId, mutation) => {
    const input = {
        [purchaseOrderField]: {
            FuneralID: funeralId,
            SupplierID: booking.Contact.ID,
            Products: booking.BookingItems.map(e => {
                let UnitPrice = e.Price;
                if (isNullOrUndefined(UnitPrice)) {
                    UnitPrice = e.UnitPrice; // assumed exGST
                } else {
                    const needGST = !!e.GST || (!!e.Product && e.Product.GST) || (!!e.Variation && e.Variation.GST);
                    UnitPrice = UnitPrice / (!!needGST ? GST + 1 : 1); //exGST
                }
                return {
                    ProductID: !!e.Product ? e.Product.ID : e.ProductID,
                    VariationID: !!e.Variation ? e.Variation.ID : e.VariationID,
                    Qty: e.Quantity,
                    UnitPrice
                };
            }).filter(e => Number(e.Qty) > 0)
        }
    };
    if (isRelatedObjectDefined(booking)) {
        input.ID = booking.ID;
    } else if (eventObj && isRelatedObjectDefined(eventObj)) {
        // make sure any relevant relations are added. CMS will use committal for booking details.
        input.CommittalVenueID = funeralId;
        input.CalendarEventID = (eventObj && eventObj.ID) || null;
    }
    if (purchaseOrderField === 'PurchaseOrder') {
        // not a hospital bill, is attached to a booking, so ensure contact ID
        input.ContactID = booking.Contact.ID;
    }

    return await getClient().mutate({
        mutation,
        variables: {
            input
        }
    });
};

export const createCertificationPurchaseOrderMutation = gql`
    ${PurchaseOrderObject}
    mutation createCertificationPurchaseOrderMutation($input: UpdateFuneralCertificationInput!) {
        updateFuneralCertification(input: $input) {
            ID
            RefereePurchaseOrder {
                ...PurchaseOrderObject
            }
            HospitalPurchaseOrder {
                ...PurchaseOrderObject
            }
            DoctorPurchaseOrder {
                ...PurchaseOrderObject
            }
        }
    }
`;

export const createBookingPurchaseOrderMutation = gql`
    ${PurchaseOrderBooking}
    mutation createBookingPurchaseOrderMutation($input: UpdateFuneralBookingInput!) {
        updateFuneralBooking(input: $input) {
            ID
            ...PurchaseOrderBooking
        }
    }
`;

export const createNewBookingPurchaseOrderMutation = gql`
    ${PurchaseOrderBooking}
    mutation createNewBookingPurchaseOrderMutation($input: CreateFuneralBookingInput!) {
        createFuneralBooking(input: $input) {
            ID
            ...PurchaseOrderBooking
        }
    }
`;

const styles = ({ palette, funeralHome }) => ({
    root: {
        maxWidth: '800px',
        width: '100%',
        maxHeight: 'unset'
    },
    diagHeader: {
        background: palette.contentForeground[funeralHome],
        padding: '30px'
    },
    diagHeaderTitle: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'lighter',
        lineHeight: 'normal'
    },
    diagHeaderSubtitle: {
        color: '#FFFFFF',
        fontSize: '36px',
        fontWeight: 'initial',
        lineHeight: 'normal'
    },
    content: {
        width: '100%',
        padding: 18,
        maxHeight: 'calc(100vh - 15rem)',
        overflow: 'auto',
        minHeight: 340
    },
    diagActions: {
        padding: 30,
        paddingTop: 10,
        margin: 0,
        justifyContent: 'space-between'
    },

    regular: {
        fontSize: '16px',
        fontWeight: 'lighter'
    },

    greenButt: {
        border: '1px solid' + palette.button.save,
        backgroundColor: palette.button.save,
        '&:hover': {
            backgroundColor: palette.action.hover,
            color: palette.button.save
        }
    },
    dataList: {
        overflowY: 'auto',
        marginTop: 20,
        marginBottom: 30
    },
    starButton: {
        width: '70px',
        height: '70px',
        float: 'right',
        color: 'rgba(255,255,255,0.5)'
    }
});

const BillTemplatesQuery = gql`
    ${ProductPriceOverrides}
    ${VariationPriceOverrides}
    query BillTemplatesQuery {
        readBillTemplates {
            edges {
                node {
                    ID
                    NotCurrent
                    Contact {
                        ID
                    }
                    BillTemplateItems {
                        ID
                        Qty
                        Optional
                        Product {
                            ID
                            InternalItemID
                            Title
                            BasePrice
                            GST
                            ...ProductPriceOverrides
                        }
                        Variation {
                            ID
                            InternalItemID
                            ShortDescription
                            GST
                            Price
                            ...VariationPriceOverrides
                        }
                    }
                }
            }
        }
    }
`;

export default compose(withSnackbarMessage, withStyles(styles))(PurchaseOrderModal);
