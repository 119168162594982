import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../../component/form/Grid';
import PrimaryButton, { OutlineButton, SaveButton } from '../../../../component/form/PrimaryButton';
import { InlineFieldRightAlignChildren } from '../../../../component/form/Inline';
import BillTo, { BILL_TO_MAPPING } from '../BillTo';
import ModalStepTitle from '../../../../component/form/ModalStepTitle';
import SaveIcon from '../../../../component/icon/SaveIcon';
import CloseIcon from '../../../../component/icon/CloseIcon';
import NextIcon from '../../../../component/icon/NextIcon';
import { DialogActions } from '@material-ui/core';
import { isNullOrUndefined } from '../../../../util/objects';

class BillingDetailsTab extends Component {
    state = {
        billToMapping: {},
        invoiceIndex: -1
    };

    static getDerivedStateFromProps({ invoiceIndex, form }, state) {
        if (invoiceIndex !== state.invoiceIndex) {
            return { invoiceIndex, billToMapping: createBillToMapping() };
        }
        return null;
    }

    render() {
        const { classes, nextTab, onCancel, loading, canEdit, onSubmit, form, parentForm } = this.props;
        const { billToMapping } = this.state;
        if (isNullOrUndefined(form)) return null;
        const isDirty = form.getDirty().length;
        return (
            <Fragment>
                <Grid container spacing={16} className={classes.root}>
                    <ModalStepTitle number={this.props.stepNumber} title="Please confirm the billing details" />

                    <Grid container spacing={16} className={classes.billToWrapper}>
                        <Grid bucket>
                            <BillTo billingTargetForm={form} billingSourceForm={parentForm} mapping={billToMapping} />
                        </Grid>
                    </Grid>
                </Grid>
                <DialogActions className={classes.modalFooter}>
                    <InlineFieldRightAlignChildren lineHeight={'normal'}>
                        <span>
                            <OutlineButton onClick={loading ? undefined : onCancel} color="primary" disabled={loading}>
                                <CloseIcon />
                                <span className={classes.svgLabel}>&nbsp;Cancel</span>
                            </OutlineButton>
                        </span>

                        <PrimaryButton onClick={nextTab} disabled={loading}>
                            <span className={classes.svgLabel}>Next Step&nbsp;</span>
                            <NextIcon />
                        </PrimaryButton>

                        <SaveButton disabled={!canEdit || loading || !isDirty} onClick={() => onSubmit()}>
                            <SaveIcon />
                            <span className={classes.svgLabel}>&nbsp;Save</span>
                        </SaveButton>
                    </InlineFieldRightAlignChildren>
                </DialogActions>
            </Fragment>
        );
    }
}

const createBillToMapping = () => {
    return {
        [BILL_TO_MAPPING.billingSource]: `Customer.BillingSource`,
        [BILL_TO_MAPPING.relationToDeceased]: `Customer.RelationToDeceased`,
        [BILL_TO_MAPPING.givenName]: `Customer.FirstName`,
        [BILL_TO_MAPPING.middleName]: `Customer.MiddleName`,
        [BILL_TO_MAPPING.surname]: `Customer.Surname`,
        [BILL_TO_MAPPING.fullName]: `Customer.CustomerName`,
        [BILL_TO_MAPPING.phone]: `Customer.Phone`,
        [BILL_TO_MAPPING.mobile]: `Customer.Mobile`,
        [BILL_TO_MAPPING.line1]: `Customer.AddressLine1`,
        [BILL_TO_MAPPING.line2]: `Customer.AddressLine2`,
        [BILL_TO_MAPPING.city]: `Customer.Suburb`,
        [BILL_TO_MAPPING.state]: `Customer.State`,
        [BILL_TO_MAPPING.country]: `Customer.Country`,
        [BILL_TO_MAPPING.code]: `Customer.Postcode`,
        [BILL_TO_MAPPING.email]: `Customer.Email`,
        [BILL_TO_MAPPING.billingId]: `Customer.BillingId`
    };
};

export const convertToCustomer = billTo => {
    return {
        AddressLine1: billTo.AddressLine1,
        AddressLine2: billTo.AddressLine2,
        Suburb: billTo.Suburb,
        State: billTo.State,
        Postcode: Number(billTo.Postcode),
        BillingId: billTo.BillingId,
        Country: billTo.Country,
        Mobile: billTo.Mobile,
        Phone: billTo.Phone,
        Contact: billTo.Contact,
        FirstName: billTo.FirstName,
        MiddleName: billTo.MiddleName,
        Surname: billTo.Surname,
        CustomerName: billTo.Name,
        BillingSource: billTo.BillingSource,
        RelationToDeceased: billTo.RelationToDeceased,
        Email: billTo.Email
    };
};

const styles = () => ({
    root: {
        margin: '0',
        padding: '50px 30px 30px 30px',
        height: 'auto',
        alignContent: 'start',
        flexDirection: 'column',
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%'
    },

    billToWrapper: {
        width: '100%',
        display: 'block',
        height: 450 /* room for autocomplete dropdown*/
    },

    alignWithFloatingLabel: {
        marginTop: 17
    },

    alignWithLabel: {
        marginTop: 10
    }
});

export default withStyles(styles)(BillingDetailsTab);
