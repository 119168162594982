import gql from 'graphql-tag';

export default gql`
    fragment RunsheetPDFs on Funeral {
        RunsheetPDFs {
            ... on File {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
`;
