import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DialogActions } from '@material-ui/core';
import Grid from '../../../../component/form/Grid';
import TextField from '../../../../component/form/TextField';
import PrimaryButton, { AltButton, OutlineButton, SaveButton } from '../../../../component/form/PrimaryButton';
import ModalStepTitle from '../../../../component/form/ModalStepTitle';
import CloseIcon from '../../../../component/icon/CloseIcon';
import BackIcon from '../../../../component/icon/BackIcon';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import Spinner from '../../../../component/Spinner';
import { joinDefined } from '../../../../util/strings';
import ModalStepHeader from '../../../../component/form/ModalStepHeader';
import { InlineFieldRightAlignChildren } from '../../../../component/form/Inline';
import EnvelopeIcon from '../../../../component/icon/EnvelopeIcon';
import Hidden from '@material-ui/core/Hidden';
import BiggerMessageBar from '../../../../component/BiggerMessageBar';
import Checkbox from '../../../../component/form/Checkbox';
import NextIcon from '../../../../component/icon/NextIcon';
import SaveIcon from '../../../../component/icon/SaveIcon';

class InvoicePDFTab extends Component {
    state = {
        sentStatus: !!this.props.invoice.InvoiceSent
    };

    render() {
        const { classes, onCancel, loading, invoice, previousTab, nextTab } = this.props;
        const { sentStatus } = this.state;
        const isSent = !!invoice.InvoiceSent;
        const canSend = invoice.XeroStatus === 'AUTHORISED' || invoice.XeroStatus === 'PAID';
        return (
            <Fragment>
                <Grid container spacing={16} className={classes.root}>
                    <ModalStepTitle
                        header={invoice.IsPurchase ? 'Bill' : 'Invoice'}
                        title={`#${invoice.ID} ${invoice.LegacyKey ? '(' + invoice.LegacyKey + ')' : ''}`}
                    />
                    <Grid item xs={12} className={classes.modalContent}>
                        {/*<ColumnLayout className={classes.columnRoot}>*/}
                        <Grid container spacing={24} className={classes.columnRoot}>
                            <Grid item xs={12} lg={8}>
                                {this.renderPDF()}
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                {(!invoice.IsPurchase && this.renderSendDetails()) || this.renderBillDetails()}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <DialogActions className={classes.modalFooter}>
                    <OutlineButton onClick={loading ? undefined : onCancel} className={classes.back} disabled={loading}>
                        <CloseIcon />
                        <span className={classes.svgLabel}>&nbsp;Close</span>
                    </OutlineButton>

                    <PrimaryButton onClick={previousTab} disabled={loading} className={classes.back}>
                        <BackIcon />
                        <span className={classes.svgLabel}>&nbsp;Back</span>
                    </PrimaryButton>

                    <InlineFieldRightAlignChildren lineHeight={'normal'}>
                        <span />
                        <PrimaryButton onClick={nextTab} disabled={loading} className={classes.back}>
                            <Hidden smDown>Payments&nbsp;</Hidden>
                            <NextIcon />
                        </PrimaryButton>
                        {!invoice.IsPurchase && (
                            <Fragment>
                                {invoice.Customer && invoice.Customer.Email && canSend && isSent === sentStatus && (
                                    <AltButton
                                        onClick={this.handleSendInvoice}
                                        disabled={loading || !canSend}
                                        title={(invoice.InvoiceSent ? 'Res' : 'S') + 'end Invoice'}
                                    >
                                        <EnvelopeIcon />
                                        <Hidden smDown>&nbsp;{isSent ? 'Res' : 'S'}end Invoice</Hidden>
                                    </AltButton>
                                )}

                                <SaveButton
                                    onClick={this.handleSaveInvoice}
                                    disabled={loading || isSent === sentStatus}
                                >
                                    <SaveIcon />
                                    <Hidden smDown>&nbsp;Save</Hidden>
                                </SaveButton>
                            </Fragment>
                        )}
                    </InlineFieldRightAlignChildren>
                </DialogActions>
            </Fragment>
        );
    }

    renderPDF() {
        const { invoice } = this.props;
        return (
            <Query query={queryReadInvoicePDF} variables={{ ID: invoice.ID }}>
                {({ data, networkStatus, error, loading }) => {
                    if (loading) {
                        return (
                            <Fragment>
                                <Spinner /> ...fetching PDF from Xero
                            </Fragment>
                        );
                    }
                    let { readInvoicePDF } = data || {};

                    if (readInvoicePDF) {
                        readInvoicePDF = 'data:application/pdf;base64,' + readInvoicePDF;
                        return (
                            <iframe src={readInvoicePDF} style={{ width: '100%', height: 510 }} title="invoice-pdf" />
                        );
                    }

                    return 'PDF not found.';
                }}
            </Query>
        );
    }

    renderSendDetails() {
        const { invoice } = this.props;
        const { sentStatus } = this.state;
        const isSent = !!invoice.InvoiceSent;
        const canSend = invoice.XeroStatus === 'AUTHORISED' || invoice.XeroStatus === 'PAID';
        return (
            <Grid container={true} spacing={16}>
                {!!canSend && !isSent && (
                    <Grid item xs={12}>
                        <BiggerMessageBar messageType={'warning'}>Customer has not been sent invoice.</BiggerMessageBar>
                    </Grid>
                )}
                {!canSend && (
                    <Grid item xs={12}>
                        <BiggerMessageBar messageType={'info'}>
                            Cannot send invoice unless it is authorised.
                        </BiggerMessageBar>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <ModalStepHeader header={'Send to Customer'} />
                    <TextField
                        label="Send To"
                        value={
                            joinDefined(
                                [invoice.Customer.FirstName, invoice.Customer.MiddleName, invoice.Customer.Surname],
                                ' '
                            ) || invoice.Customer.CustomerName
                        }
                        readOnly={true}
                    />

                    <TextField label="Email" value={invoice.Customer.Email || '(none)'} readOnly={true} />

                    {!!canSend && (
                        <Fragment>
                            {invoice.Customer.Email ? (
                                <p>
                                    The customer has an email address, so the invoice
                                    {(!canSend &&
                                        ' will be automatically sent via email when it becomes authorised.') ||
                                        ' can be sent via email (or just printed & posted.)'}
                                </p>
                            ) : (
                                <p>
                                    The customer does not have an email address, so the invoice must be printed &
                                    posted.
                                </p>
                            )}
                            <Checkbox
                                onClick={e => this.setState({ sentStatus: !!e.target.checked })}
                                checked={!!sentStatus}
                                label={
                                    invoice.Customer.Email
                                        ? 'Invoice has already been sent'
                                        : 'Invoice was printed and posted'
                                }
                            />
                        </Fragment>
                    )}
                </Grid>
            </Grid>
        );
    }

    renderBillDetails() {
        const { purchaseOrders, invoice } = this.props;
        const bill = purchaseOrders.find(po => po.PO.Invoice.ID === invoice.ID);

        return (
            <Grid container={true} spacing={16}>
                <Grid item xs={12}>
                    <ModalStepHeader header={'Bill Raised For'} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Supplier"
                        value={
                            joinDefined(
                                [invoice.Customer.FirstName, invoice.Customer.MiddleName, invoice.Customer.Surname],
                                ' '
                            ) || invoice.Customer.CustomerName
                        }
                        readOnly
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField label="Service" value={(bill && bill.Supplier) || '(unknown)'} readOnly />
                </Grid>
                <Grid item xs={12}>
                    <TextField label="Confirmed" value={(bill && bill.Confirmed) || '(unknown)'} readOnly />
                </Grid>
            </Grid>
        );
    }

    handleSendInvoice = () => {
        const { onSendEmail } = this.props;
        if (onSendEmail) onSendEmail();
    };

    handleSaveInvoice = () => {
        const { onSubmit, form } = this.props;
        const { sentStatus } = this.state;
        form.setField({ InvoiceSent: !!sentStatus });
        if (onSubmit) onSubmit();
    };
}

const queryReadInvoicePDF = gql`
    query($ID: ID!) {
        readInvoicePDF(ID: $ID)
    }
`;

const styles = () => ({
    root: {
        margin: '0',
        padding: '50px 30px 30px 30px',
        height: 'auto',
        alignContent: 'start',
        flexDirection: 'column',
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%'
    },

    columnRoot: {
        width: '100%'
    },

    pdfGrid: {
        height: 510
    },

    alignWithFloatingLabel: {
        marginTop: 17
    },

    alignWithLabel: {
        marginTop: 10
    },

    back: {
        float: 'left'
    }
});

export default withStyles(styles)(InvoicePDFTab);
