import gql from 'graphql-tag';
import { getClient } from '../../apollo';
import { dateTimeToString, niceDateFromString, niceDateTimeFromString, niceTimeFromString } from '../../util/date';
import { getUser } from '../../util/sessions';
import { joinDefined } from '../../util/strings';
import { Arrangement } from '../funeral/FuneralFragments';
import { buildTabDataFragment } from '../../util/functions';

export const MortuaryItemFragment = buildTabDataFragment(
    [
        gql`
            fragment MortuaryItem on Funeral {
                ID
                LegacyKey
                Cancelled
                FirstName
                MiddleName
                Surname
                Gender

                DateOfBirth
                DateOfDeath1
                DateOfDeath2
                DateOfDeathType
                Age
                AgeCode

                Certification {
                    ID
                    TypeOfBdmDeathCertificate
                    Referee {
                        ID
                    }
                    FileCorDisposalOrder {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                    FileMedCertCause {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                    FileStatDecForm {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                    FileCorCremPermit {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                    FileCremCertificate {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                    FileCremBookingForm {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                    FileRefereeingDoctorForm {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                    FileBurialBookingForm {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                    FileEmbalmingCert {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                    FileDisclaimer {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                }

                RefereeDoctorDateSigned
                RefereeCremationCheck
                RemovePacemaker

                ReadyForMortuary
                DateTransferredToMortuary
                DatetimeReadyForMortuaryCheckout
                DatetimeOfMortuaryCheckout
                MortuaryActionReturnedReason

                FuneralDressing {
                    ID
                    Status
                    Received
                    Comment
                    GreekShroud
                    Shroud
                    Date
                    Time
                }

                Disposal {
                    ID
                    CrematedOrBuried
                    Date
                    Time
                    Start
                    LocationFlattened
                    Location {
                        ID
                        Name
                    }
                    DisposalBookingItems {
                        ID
                        Title
                        Quantity
                        Comment
                        Returns
                        Product {
                            ID
                            CoffinTrim
                            InternalItemID
                            Parent {
                                ... on ProductCategory {
                                    ID
                                    URLSegment
                                }
                                ... on CoreProductCategory {
                                    ID
                                    URLSegment
                                }
                            }
                            ProductCategories {
                                ... on ProductCategory {
                                    ID
                                    URLSegment
                                }
                                ... on CoreProductCategory {
                                    ID
                                    URLSegment
                                }
                            }
                        }
                        Variation {
                            ID
                            InternalItemID
                        }
                    }
                }

                Clergy {
                    ID
                    Contact {
                        ID
                        Name
                        ClergyCategory
                    }
                }

                RslMasonic {
                    ID
                    Contact {
                        ID
                        Name
                    }
                }

                ValuableItems {
                    ID
                    Description
                    Quantity
                    ToBePlaced
                    CurrentLocation
                    CurrentLocationDetail
                    WhereToBeReturned
                    ToBeReturned
                    Note
                }

                PlaceOfService {
                    ID
                    Date
                    Time
                    Start
                    Type
                    LocationFlattened
                    Location {
                        ID
                        Name
                    }
                }

                PlaceOfViewingRequired
                PlaceOfViewingItems {
                    ID
                    Date
                    Time
                    Start
                    Type
                    LocationFlattened
                    Location {
                        ID
                        Name
                    }
                }

                RefreshmentsVenue {
                    ID
                    Date
                    Time
                    Start
                    Type
                    LocationFlattened
                    Location {
                        ID
                        Name
                    }
                }

                Florists {
                    ID
                    Confirmed
                    Comment
                    Contact {
                        ID
                        Name
                    }
                    BookingItems {
                        edges {
                            node {
                                ID
                                Quantity
                                Comment
                                Product {
                                    ID
                                    ProductCategories {
                                        ... on ProductCategory {
                                            ID
                                            URLSegment
                                        }
                                        ... on CoreProductCategory {
                                            ID
                                            URLSegment
                                        }
                                    }
                                }
                                Variation {
                                    ID
                                }
                            }
                        }
                    }
                }
                DateOfService
                CoffinComment

                DeceasedPhotos {
                    ... on File {
                        ID
                        AbsoluteLink
                        Name
                    }
                    ... on Image {
                        ID
                        AbsoluteLink
                        Name
                    }
                }

                Grave {
                    ID
                    GroundDescription
                    DepthOption
                    ReservationRequired
                    ReservationName
                    PortionGardenName
                    BeamRowLot
                    SideSection
                    Number
                }

                MortuaryWatchData
            }
        `,
        Arrangement
    ],
    'Funeral'
);

const ReleaseToCheckoutQuery = gql`
    mutation SaveFuneral($input: UpdateFuneralInput!) {
        updateFuneral(input: $input) {
            ID
            ReadyForMortuary
            DateTransferredToMortuary
            DatetimeReadyForMortuaryCheckout
            DatetimeOfMortuaryCheckout
            MortuaryActionReturnedReason
            MortuaryWatchData
            EstimatedDeceasedWeight
        }
    }
`;

export const releaseToCheckout = funeralId => {
    const input = {
        ID: funeralId,
        ReadyForMortuary: true,
        DatetimeReadyForMortuaryCheckout: dateTimeToString(new Date())
    };

    return getClient()
        .mutate({ mutation: ReleaseToCheckoutQuery, variables: { input } })
        .then(
            ({ data }) => {
                return data.updateFuneral;
            },
            e => console.error('failed to get release to checkout', e)
        );
};

export const unreleaseFromCheckout = (funeralId, reason) => {
    const input = {
        ID: funeralId,
        DatetimeReadyForMortuaryCheckout: null,
        DatetimeOfMortuaryCheckout: null,
        MortuaryActionReturnedReason:
            dateTimeToString(new Date()) +
            ' released back from Mortuary Checkout by ' +
            joinDefined([getUser().FirstName, getUser().Surname], ' ') +
            ' because ' +
            reason
    };

    return getClient()
        .mutate({ mutation: ReleaseToCheckoutQuery, variables: { input } })
        .then(
            () => {},
            e => console.error('failed to get unrelease from checkout', e)
        );
};

export const releaseFromCheckout = (funeralId, weight) => {
    const input = {
        ID: funeralId,
        DatetimeOfMortuaryCheckout: dateTimeToString(new Date()),
        EstimatedDeceasedWeight: weight
    };

    return getClient()
        .mutate({ mutation: ReleaseToCheckoutQuery, variables: { input } })
        .then(
            ({ data }) => {
                return data.updateFuneral;
            },
            e => console.error('failed to get release from checkout', e)
        );
};

export const getDressingStatus = form => {
    const status = form.getState('FuneralDressing.Status');
    const received = form.getState('FuneralDressing.Received');
    const GreekShroud = form.getState('FuneralDressing.GreekShroud');
    const Shroud = form.getState('FuneralDressing.Shroud');

    const viewings = form.getState('PlaceOfViewingItems') || [];
    const viewing = viewings.find(e => e.Type === 'Family Wish to Dress') || false;

    const thing =
        viewing &&
        joinDefined(
            [
                'Viewing where family wish to dress: ',
                niceDateTimeFromString(viewing.Start),
                !!viewing.LocationFlattened && 'at ' + viewing.LocationFlattened
            ],
            ' '
        );

    const result = () => {
        if (!status || status.length === 0) {
            return 'No dressing has been selected';
        } else if (
            status === 'Clothing collected at time of Arrangement' ||
            status === 'Clothing will be delivered to Funeral Home'
        ) {
            if (!!received) {
                return 'Clothing has been collected';
            } else if (status === 'Clothing will be delivered to Funeral Home') {
                const whenD = niceDateFromString(form.getState('FuneralDressing.Date'));
                const whenT = niceTimeFromString(form.getState('FuneralDressing.Time'));
                return status + ((!!whenD && ' on ' + joinDefined([whenD, whenT], ' at ')) || '');
            } else {
                return status;
            }
        }
        return status;
    };

    return joinDefined([result(), thing, GreekShroud && 'Use Greek Shroud', Shroud && 'Use Shroud'], '. ') + '.';
};
