import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { InlineField } from '../../../component/form/Inline';
import TextField from '../../../component/form/TextField';
import FuneralAutoComplete from '../../../component/form/FuneralAutoComplete';
import ActionButton from '../../../component/form/ActionButton';
import Select from '../../../component/form/Select';
import CheckableStar from '../../workQueue2/CheckableStar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '../../../component/form/Grid';
import Label from '../../../component/form/Label';

import { joinDefined } from '../../../util/strings';
import SaveIcon from '../../../component/icon/SaveIcon';
import CloseIcon from '../../../component/icon/CloseIcon';
import { OutlineButton, SaveButton } from '../../../component/form/PrimaryButton';
import cloneDeep from 'lodash.clonedeep';
import { CreateTask, TAB_URL_SEGMENTS } from '../components/TaskConstants';
import { isRelatedObjectDefined } from '../../../util/bookable';
import UserCircle, { GetInitials } from '../../../component/form/UserCircle';
import UserCircleIcon from '../../../component/icon/UserCircleIcon';
import TrashIcon from '../../../component/icon/TrashIcon';
import ClockIcon from '../../../component/icon/ClockIcon';
import StaffAutoComplete from '../../../component/form/StaffAutoComplete';
import moment from 'moment';
import GatedComponent from '../../../component/GatedComponent';
import { getUser } from '../../../util/sessions';

class CreateEditTaskModal extends Component {
    state = {
        onClose: () => {}
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.open === false && nextProps.open === true) {
            const task = nextProps.task ? cloneDeep(nextProps.task) : CreateTask(nextProps.user);
            const assignedMembers = task.node.AssignedMembers.map(assignedUser => {
                return {
                    fullName: joinDefined([assignedUser.FirstName, assignedUser.Surname], ' '),
                    abbreviation: GetInitials(assignedUser),
                    user: assignedUser
                };
            });

            this.setState({ task, assignedMembers });
        }
    }

    render() {
        const { classes, open, className } = this.props;
        const { task } = this.state;
        return (
            <Dialog
                classes={{ paper: cx(className, classes.root) }}
                open={open}
                onClose={this.onClose}
                aria-labelledby={'CreateEditTaskModal'}
            >
                <form onSubmit={e => this.onSubmit(e)}>{task && this.renderForm()}</form>
            </Dialog>
        );
    }

    renderForm() {
        const { task } = this.state;
        const { classes } = this.props;
        const me = getUser();
        const myTask = task && task.node.AssignedMembers.find(e => !!e && Number(e.ID) === Number(me.ID));
        const myStar = myTask && myTask._join && myTask._join.TaskMembers && myTask._join.TaskMembers.Starred;

        return (
            <Fragment>
                <DialogTitle id={'ViewTaskModal'} className={classes.diagHeader}>
                    <CheckableStar
                        className={classes.starButton}
                        value={!!myStar}
                        onChange={checked =>
                            this.setState({
                                task: {
                                    node: {
                                        ...task.node,
                                        AssignedMembers: task.node.AssignedMembers.map(e => {
                                            e._join = {
                                                TaskMembers: {
                                                    Starred:
                                                        Number(e.ID) === Number(me.ID)
                                                            ? checked
                                                                ? new Date()
                                                                : null
                                                            : e._join &&
                                                              e._join.TaskMembers &&
                                                              e._join.TaskMembers.Starred
                                                }
                                            };
                                            return e;
                                        })
                                    }
                                }
                            })
                        }
                    />
                    <div className={classes.diagHeaderTitle}>Task Manager</div>
                    <div className={classes.diagHeaderSubtitle}>
                        {this.props.task ? 'Edit existing task' : 'Create a custom task'}
                    </div>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid container spacing={32} pc={1}>
                        <Grid item xs={8}>
                            {this.renderLeftColumn()}
                        </Grid>
                        <Grid item xs={4}>
                            {this.renderRightColumn()}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.diagActions}>
                    <div style={{ width: '100%' }}>
                        <OutlineButton onClick={this.onCancel}>
                            <CloseIcon />
                            &nbsp;Cancel
                        </OutlineButton>

                        <SaveButton type="submit" style={{ float: 'right' }}>
                            <SaveIcon />
                            &nbsp;Save
                        </SaveButton>
                    </div>
                </DialogActions>
            </Fragment>
        );
    }

    renderLeftColumn() {
        const { classes } = this.props;
        const { task } = this.state;

        return (
            <Fragment>
                <InlineField className={classes.multiField}>
                    <span>
                        <Label text="Task Name:" />
                    </span>
                    <span>
                        <TextField
                            placeholder="Enter a name..."
                            value={task.node.Title}
                            onChange={e => this.setField('Title', e.target.value)}
                            required={true}
                        />
                    </span>
                </InlineField>

                <InlineField className={classes.multiField}>
                    <span>
                        <Label text="Description:" />
                    </span>
                    <span>
                        <TextField
                            rows={12}
                            multiline={true}
                            placeholder="Enter a description"
                            value={task.node.Description}
                            onChange={e => this.setField('Description', e.target.value)}
                            required={true}
                        />
                    </span>
                </InlineField>

                <InlineField className={classes.multiField}>
                    <span>
                        <Label text="Action:" />
                    </span>
                    <span>
                        <TextField
                            placeholder="Add a Bill, email the Applicant..."
                            value={task.node.RequiredAction}
                            onChange={e => this.setField('RequiredAction', e.target.value)}
                        />
                    </span>
                </InlineField>

                <InlineField className={classes.multiField}>
                    <span>
                        <Label text="Funeral:" />
                    </span>
                    <span>
                        <FuneralAutoComplete
                            value={isRelatedObjectDefined(task.node.Funeral) ? task.node.Funeral : null}
                            onSelect={(_, funeral) => this.setField('Funeral', funeral)}
                        />
                    </span>
                </InlineField>
                {isRelatedObjectDefined(task.node.Funeral) && (
                    <InlineField className={classes.multiField}>
                        <span>
                            <Label text="Section:" />
                        </span>
                        <span>
                            <Select
                                disabled={!isRelatedObjectDefined(task.node.Funeral)}
                                options={TAB_URL_SEGMENTS}
                                value={task.node.TabURLSegment}
                                onChange={e => this.setField('TabURLSegment', e.target.value)}
                                required={true}
                            />
                        </span>
                    </InlineField>
                )}
                {/*assignedMembers.length > 0 ? (
                    <Fragment>
                        <InlineField>
                            <Label text="Assigned To:" />
                        </InlineField>

                        <InlineField>
                            {assignedMembers.map((obj, i) => (
                                <UserCircle
                                    key={i}
                                    abbreviation={obj.abbreviation}
                                    user={obj.fullName}
                                    onDelete={e => this.removeAssignedUser(i)}
                                />
                            ))}
                        </InlineField>
                    </Fragment>
                ) : (
                    <Fragment>
                        <InlineField>
                            <Label text="This task has not been assigned to anyone yet" />
                        </InlineField>
                    </Fragment>
                )*/}
            </Fragment>
        );
    }

    renderRightColumn() {
        const { classes } = this.props;
        const { task, assignedMembers } = this.state;

        return (
            <Fragment>
                <div className={classes.singleField}>Due Date:</div>
                <div className={classes.singleField}>
                    <ActionButton
                        type="date"
                        icon={<ClockIcon />}
                        text="Add Due Date"
                        controlprops={{
                            value: moment(task.node.Due).format('YYYY-MM-DD'),
                            onChange: e => {
                                task.node.Due = e.target.value;
                                this.setState({ task });
                            }
                        }}
                        startopen={!!task.node.Due ? true : undefined}
                    />
                </div>
                <div className={classes.singleField}>Actions:</div>
                <GatedComponent showComponentCode={'FM_ACCESS_Tasks_Delete'}>
                    {() => {
                        return (
                            <div className={classes.singleField}>
                                <ActionButton
                                    onClick={() => this.toggleArchiveTask()}
                                    icon={<TrashIcon />}
                                    text={!task.node.Archived ? 'Archive' : 'Restore'}
                                />
                            </div>
                        );
                    }}
                </GatedComponent>
                {task.node.Archived && (
                    <div className={classes.singleField}>
                        <i>Note: This task was archived on {moment(task.node.Archived).format('Do MMM YYYY')}</i>
                    </div>
                )}
                <GatedComponent showComponentCode={'FM_ACCESS_Tasks_Reassign'}>
                    {() => {
                        return (
                            <div className={classes.singleField}>
                                <ActionButton
                                    icon={<UserCircleIcon />}
                                    text="Assign Staff"
                                    control={
                                        <StaffAutoComplete onSelect={(e, result) => this.onSelectStaff(e, result)} />
                                    }
                                />
                            </div>
                        );
                    }}
                </GatedComponent>
                <div className={classes.singleField}>Assigned to:</div>
                {assignedMembers.length > 0 ? (
                    <div className={classes.memberIcons}>
                        {assignedMembers.map((obj, i) => (
                            <UserCircle
                                key={i}
                                abbreviation={obj.abbreviation}
                                user={obj.fullName}
                                onDelete={e => this.removeAssignedUser(i)}
                            />
                        ))}
                    </div>
                ) : (
                    <div className={classes.singleField}>
                        <small>This task has not been assigned to anyone yet.</small>
                    </div>
                )}
            </Fragment>
        );
    }

    removeAssignedUser(index) {
        const { assignedMembers } = this.state;
        assignedMembers.splice(index, 1);
        this.setState({ assignedMembers });
    }

    onSelectStaff(e, user) {
        const { assignedMembers } = this.state;

        assignedMembers.push({
            fullName: joinDefined([user.FirstName, user.Surname], ' '),
            abbreviation: GetInitials(user),
            user: { ...user }
        });

        this.setState({ assignedMembers });
    }

    setField(propertyName, value) {
        const { task } = this.state;
        task.node[propertyName] = value;
        this.setState({ task });
    }

    toggleArchiveTask() {
        const { task } = this.state;
        task.node.Archived = task.node.Archived ? null : new Date();
        this.setState({ task });
    }

    onCancel = () => {
        this.props.onClose();
        this.clearState();
    };

    clearState() {
        this.setState({ task: null });
    }

    onSubmit(e) {
        e.preventDefault();
        const { onSubmit } = this.props;
        const { task, assignedMembers } = this.state;

        //capture the assigned members
        task.node.AssignedMembers = assignedMembers.map(x => x.user);

        if (onSubmit) onSubmit(task);

        this.onCancel();
    }
}

const styles = ({ palette, transitions }) => ({
    root: {
        maxWidth: '800px',
        width: '800px'
    },
    diagHeader: {
        background: palette.contentForeground.none,
        padding: '30px'
    },
    diagHeaderTitle: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'lighter',
        lineHeight: 'normal'
    },
    diagHeaderSubtitle: {
        color: '#FFFFFF',
        fontSize: '36px',
        fontWeight: 'initial',
        lineHeight: 'normal'
    },
    content: {
        width: '100%',
        padding: '30px 34px 24px 64px'
    },
    diagActions: {
        padding: '15px 57px 24px 57px'
    },
    subContent: {
        padding: '25px 0 0 0'
    },
    starButton: {
        width: '70px',
        height: '70px',
        float: 'right'
    },

    title: {
        fontSize: '33px',
        color: palette.contentForeground.none
    },

    funeralId: {
        fontSize: '21px',
        fontWeight: 'lighter'
    },

    regular: {
        fontSize: '16px',
        fontWeight: 'lighter'
    },

    toggleActivity: {
        fontSize: '12px'
    },

    auditType: {
        fontSize: '14px'
    },
    auditDetails: {
        fontSize: '13px',
        fontWeight: 'lighter'
    },
    audit: {
        transition: transitions.create(['width'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.enteringScreen
        })
    },
    singleField: {
        margin: '0 0 15px 0',
        width: '100%'
    },
    memberIcons: {
        margin: -3,
        '& > span': {
            margin: 3
        }
    },
    multiField: {
        margin: '0 0 10px 0',
        '& > :nth-child(1)': {
            width: '20%'
        },
        '& > :nth-child(2)': {
            width: '80%'
        },
        width: '100%'
    }
});

export default withStyles(styles)(CreateEditTaskModal);
