import gql from 'graphql-tag';

export default gql`
    fragment Certification on Funeral {
        Certification {
            ID
            TypeOfBdmDeathCertificate
            #DoctorsName
            #DoctorsCode
            #DoctorsAddress
            CoronersType
            #CoronersName
            #CoronersCode
            #CoronersDistrict
            #DisposalOrder
            CoronersCause
            Doctor {
                ID
                Name
            }
            Hospital {
                ID
                Name
            }
            Coroner {
                ID
                Name
                District
            }
            Referee {
                ID
                Name
            }
            RefereePurchaseOrder {
                ID
                Invoice {
                    ID
                    XeroID
                }
            }
            HospitalPurchaseOrder {
                ID
                Invoice {
                    ID
                    XeroID
                }
            }
            DoctorPurchaseOrder {
                ID
                Invoice {
                    ID
                    XeroID
                }
            }
            FileCorDisposalOrder {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
            FileMedCertCause {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
            FileStatDecForm {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
            FileCorCremPermit {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
            FileCremCertificate {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
            FileCremBookingForm {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
            FileRefereeingDoctorForm {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
            FileBurialBookingForm {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
            FileEmbalmingCert {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
            FileDisclaimer {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
        }
    }
`;

export const Prearrangement = gql`
    fragment PrearrangementCertification on Prearrangement {
        Certification {
            ID
            TypeOfBdmDeathCertificate
            #DoctorsName
            #DoctorsCode
            #DoctorsAddress
            CoronersType
            #CoronersName
            #CoronersCode
            #CoronersDistrict
            #DisposalOrder
            CoronersCause
            Doctor {
                ID
                Name
            }
            Hospital {
                ID
                Name
            }
            Coroner {
                ID
                Name
                District
            }
            Referee {
                ID
                Name
            }
        }
    }
`;

/*
 */
