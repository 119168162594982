import React, { Fragment } from 'react';
import { isNullOrUndefined } from '../../util/objects';
import { getCoffin, getCoffinDescription } from '../mortuary/Coffin/CoffinListConstants';
import { isRelatedObjectDefined } from '../../util/bookable';
import { joinDefined } from '../../util/strings';
import moment from 'moment';
import { niceDateTimeFromString, niceTimeFromString } from '../../util/date';
import { DISPOSAL_METHOD_OPTIONS } from '../funeral/details/DetailsConstants';
import { CONTACT_CLERGY_OPTIONS, CONTACT_CLERGY_RELIGIOUS } from '../contacts/contactConstants';

const showBookingLine = item => {
    if (!!item.Cancelled) return null;
    const bookingItems = !isNullOrUndefined(item.BookingItems)
        ? (item.BookingItems.edges || item.BookingItems).map(bookingItem => bookingItem.node || bookingItem)
        : [];
    return (
        'By ' +
        (item.Contact.Name || '(supplier not chosen)') +
        (item.Confirmed ? ': ' : ' NOT CONFIRMED: ') +
        ((bookingItems.some(e => e.Quantity) &&
            joinDefined(
                [bookingItems.map(b => ` ${b.Quantity}x ${b.Title ? b.Title : '(unknown item)'}`), item.Comment, ' '],
                '. '
            )) ||
            '(no item selected)')
    );
};

const showBookingLimo = item => {
    if (!!item.Cancelled) return null;
    return (
        'By ' +
        (item.Contact.Name || '(supplier not chosen)') +
        (item.Confirmed ? ': ' : ' NOT CONFIRMED: ') +
        `${niceDateTimeFromString(`${item.DeliveryDate} ${item.DeliveryTime}`)} (${item.Duration} hrs)` +
        (item.DeliveryAddress ? ` - ${item.DeliveryAddress}` : '')
    );
};

const showValuableLine = (item, idx, len) => {
    return (
        (len > 1 ? 'ITEM #' + (1 + idx) + ': ' : '') +
        joinDefined(
            [
                (item.Quantity || 1) + 'x ' + item.Description,
                'Place ' + (item.ToBePlaced || '(unknown)'),
                'Currently ' + (item.CurrentLocation || '(unknown)') + ': ' + (item.CurrentLocationDetail || ''),
                item.ToBeReturned ? 'Return to ' + (item.WhereToBeReturned || '(unknown)') : 'Remain with deceased',
                item.Signed ? 'Signed' : 'NOT SIGNED',
                item.Note
            ],
            ', '
        )
    );
};

const showBearingLine = (item, isGraveside) => {
    //    if (!!isGraveside && item.Type !== 'CarryGraveside') return null;
    //    if (!isGraveside && item.Type === 'CarryGraveside') return null;
    return (
        (item.Type ? (item.Type.replace(/[A-Z]/g, x => ' ' + x) + ': ').toUpperCase() : 'Unspecified bearing ') +
        joinDefined(
            [
                niceTimeFromString(item.Date + ' ' + item.Time),
                item.Bearers,
                item.Address,
                // item.Duration,
                item.NumberOfStaffRequired > 0 ? item.NumberOfStaffRequired + 'x by Staff' : null,
                item.NumberOfFamilyRequired > 0 ? item.NumberOfFamilyRequired + 'x by Family' : null,
                item.Comment
            ],
            ', '
        )
    );
};

export const renderRunSheetDetails = (data, classes) => {
    let actualCoffin = '';
    let selectedCoffin = '';
    let coffinDescription = '';
    let coffinExtras = [];
    let placeOfService = '';
    let followToCommital = '';
    let privateCommittal = '';
    let noAttendCommittal = '';
    let celebrant = '';
    let donations = '';
    let refreshments = '';
    let refreshmentsMenu = '';
    let florist = '';
    let viewing = [];
    let checklist = '';
    let dressing = '';
    let enterMusicSongs = '';
    let reflectionMusicSongs = '';
    let exitMusicSongs = '';
    let gravesideMusicSongs = '';
    let hasDefaultMusos = '';
    let hasCars = '';
    let hasLimos = '';
    let hasHearse = '';
    let hasTouches = '';
    let hasCatering = '';
    let hasGraveBearings = '';
    let hasValuables = '';
    let hasStationary = '';
    let hasRSLMasonic = '';
    let hasNamesheets = '';
    let hasLifestory = '';
    let hasMassbook = '';
    let hasWebcast = '';
    let hasFlag = '';
    let disposalType = '';
    let cremationReferee = '';
    let applicantName = '';
    let disposalComment = null;
    let isReligious = '';

    if (!isRelatedObjectDefined(data.Funeral)) return null;

    const renderReflectionRoom = data => {
        return (
            <Fragment>
                <span className={classes.fullWidth}>
                    Reflection Room, {niceDateTimeFromString(data.Start)}
                    {` (${Number(
                        moment(data.End)
                            .diff(data.Start, 'hours', true)
                            .toFixed(3)
                    )}hrs) - ${data.LocationFlattened || '(Location not set)'}. `}
                </span>

                {data.Applicant && <span className={classes.fullWidth}>Applicant is attending.</span>}
                {data.AuthorisedContact && <span className={classes.fullWidth}>Authorised Contact is attending.</span>}
                {data.AttendeeNames && <span className={classes.fullWidth}>Attendees: {data.AttendeeNames}.</span>}

                {!!data.NumberOfPeople && (
                    <span className={classes.fullWidth}>Number of people: {data.NumberOfPeople}.</span>
                )}
            </Fragment>
        );
    };

    if (!isNullOrUndefined(data.Funeral.Disposal)) {
        actualCoffin = getCoffin(data.Funeral, false);
        selectedCoffin = getCoffin(data.Funeral, true);
        coffinDescription =
            getCoffinDescription(selectedCoffin || actualCoffin) +
            (!!actualCoffin && !!actualCoffin.Product.CoffinTrim ? ' - ' + actualCoffin.Product.CoffinTrim : '');
        coffinExtras =
            data.Funeral.Disposal.DisposalBookingItems &&
            data.Funeral.Disposal.DisposalBookingItems.filter(
                e =>
                    e.Quantity > 0 &&
                    !(
                        (!!selectedCoffin && e.Product.ID === selectedCoffin.Product.ID) ||
                        (!!actualCoffin && e.Product.ID === actualCoffin.Product.ID)
                    )
            ).map(e => {
                let title = e.Quantity + 'x ' + e.Title;
                if (
                    e.Product.Parent.URLSegment === 'fittings' ||
                    !!e.Product.ProductCategories.find(ee => ee.URLSegment === 'fittings')
                ) {
                    // title += !!e.Returns ? ' RETURN TO FAMILY' : ' STAY ON COFFIN';
                }
                return title;
            });

        disposalComment = !isNullOrUndefined(data.Funeral.Disposal.CemeterySection)
            ? data.Funeral.Disposal.CemeterySection
            : '';
        disposalType =
            (
                DISPOSAL_METHOD_OPTIONS.find(e => e.value === data.Funeral.Disposal.CrematedOrBuried) || {
                    label: data.Funeral.Disposal.CrematedOrBuried || '(disposal not set)'
                }
            ).label +
            `, ${niceDateTimeFromString(data.Funeral.Disposal.Start)} (${Number(
                moment(data.Funeral.Disposal.End)
                    .diff(data.Funeral.Disposal.Start, 'hours', true)
                    .toFixed(3)
            )}hrs) - ${data.Funeral.Disposal.LocationFlattened || '(Location not set)'}. `;

        cremationReferee =
            isRelatedObjectDefined(data.Funeral.Disposal) && data.Funeral.Disposal.CrematedOrBuried === 'Cremated'
                ? data.Funeral.Certification.TypeOfBdmDeathCertificate === 'Coroner'
                    ? 'Not Required'
                    : !Number(data.Funeral.Certification.Referee.ID)
                    ? 'Not Assigned'
                    : !data.Funeral.RefereeCremationCheck
                    ? 'Assigned'
                    : 'Completed'
                : '';
    }

    if (!isNullOrUndefined(data.Funeral.FollowToDisposal)) {
        followToCommital = data.Funeral.FollowToDisposal;
    }

    if (!isNullOrUndefined(data.Funeral.Disposal.Private)) {
        privateCommittal = data.Funeral.Disposal.Private;
    }
    if (!isNullOrUndefined(data.Funeral.Disposal.NoAttendance)) {
        noAttendCommittal = data.Funeral.Disposal.NoAttendance;
    }

    if (!isNullOrUndefined(data.Funeral.Clergy)) {
        celebrant = joinDefined(
            data.Funeral.Clergy.map(item => {
                const faith =
                    //CONTACT_CLERGY_RELIGIOUS(item.Contact.ClergyCategory) &&
                    CONTACT_CLERGY_OPTIONS.find(e => e.value === item.Contact.ClergyCategory);
                const religion = !!faith && faith.label;
                return joinDefined([item.Contact.Name, religion, item.Comment], ', ');
            }),
            '. '
        );
        const clergies = data.Funeral.Clergy.filter(item => CONTACT_CLERGY_RELIGIOUS(item.Contact.ClergyCategory));
        if (clergies.length > 0) {
            clergies.forEach(clergy => {
                const faith = CONTACT_CLERGY_OPTIONS.find(e => e.value === clergy.Contact.ClergyCategory);
                isReligious = joinDefined([isReligious, !!faith && faith.label], ' / ');
            });
        }
        if (!isReligious) isReligious = 'NO';
    }

    if (!isNullOrUndefined(data.Funeral.DonationsName)) {
        donations += '#1 ' + data.Funeral.DonationsName + '. ';
    }
    if (!isNullOrUndefined(data.Funeral.Donations2Name)) {
        donations += '#2 ' + data.Funeral.Donations2Name + '. ';
    }

    if (!!data.Funeral.WebcastRequired) {
        hasWebcast +=
            joinDefined(
                [
                    `YES`,
                    data.Funeral.WebcastComment,
                    `${!!data.Funeral.WebcastConfirmed ? '' : ' NOT CONFIRMED '}`,
                    `${!!data.Funeral.WebcastEmailedLinkToFamily ? '' : ' NOT SENT'}`
                ],
                ', '
            ) + '.';
    }

    if (!!data.Funeral.NamesheetsRequired) {
        hasNamesheets += `${data.Funeral.NamesheetsDetails || 'YES'}`;
    } else if (!!data.Funeral.NamesheetsNotRequired) {
        hasNamesheets += `NO NAME SHEETS`;
    } else {
        hasNamesheets += `(unknown)`;
    }

    if (!!data.Funeral.MassbookRequired) {
        hasMassbook += `${data.Funeral.MassbookDetails || 'YES'}`;
    }

    if (!!data.Funeral.FlagRequired) {
        hasFlag += `${data.Funeral.FlagType}${!!data.Funeral.FlagDetails ? ' - ' + data.Funeral.FlagDetails : ''}`;
    }

    if (!!data.Funeral.HearseRequired) {
        if (!!data.Funeral.HearseToPassHouse) {
            hasHearse +=
                'Pass house before service: ' +
                niceDateTimeFromString(data.Funeral.HearseToPassHouseDate + ' ' + data.Funeral.HearseToPassHouseTime) +
                '. ';
        }
        if (!!data.Funeral.HearseToPassHouseAfterService) {
            hasHearse +=
                'Pass house after service: ' +
                niceDateTimeFromString(
                    data.Funeral.HearseToPassHouseAfterServiceDate +
                        ' ' +
                        data.Funeral.HearseToPassHouseAfterServiceTime
                ) +
                '. ';
        }
        if (!!data.Funeral.HearseDriveAway) {
            hasHearse +=
                'Drive Away: ' +
                niceDateTimeFromString(data.Funeral.HearseDriveAwayDate + ' ' + data.Funeral.HearseDriveAwayTime) +
                '. ';
        }
        if (!hasHearse) hasHearse = 'Required. ';
    }

    if (!!followToCommital && !!hasHearse) hasHearse += 'Follow to committal. ';
    if (!!data.Funeral.HearseComment && !!hasHearse) hasHearse += data.Funeral.HearseComment;

    if (isRelatedObjectDefined(data.Funeral.PlaceOfService)) {
        placeOfService = !!data.Funeral.PlaceOfService.Private ? 'Private ' : '';
        placeOfService += data.Funeral.PlaceOfService.Type || '(Type not set)';
        placeOfService += `,  ${(!!data.Funeral.PlaceOfService.Start &&
            niceDateTimeFromString(data.Funeral.PlaceOfService.Start) +
                ' (' +
                Number(
                    moment(data.Funeral.PlaceOfService.End)
                        .diff(data.Funeral.PlaceOfService.Start, 'hours', true)
                        .toFixed(3)
                ) +
                'hrs)') ||
            '(Time not set)'} - 
        ${(!!data.Funeral.PlaceOfService.LocationFlattened && data.Funeral.PlaceOfService.LocationFlattened) ||
            '(Location not set)'}.`;
        placeOfService += !data.Funeral.PlaceOfService.Comment ? '' : ' / ' + data.Funeral.PlaceOfService.Comment;
    }

    if (isRelatedObjectDefined(data.Funeral.Lifestory)) {
        if (!!data.Funeral.Lifestory.Presentation) {
            hasLifestory =
                ' By ' +
                (data.Funeral.Lifestory.PresentationByWho ? data.Funeral.Lifestory.PresentationByWho : ' director') +
                ' / ' +
                (data.Funeral.Lifestory.PresentationFamilyToPrepareWhat
                    ? data.Funeral.Lifestory.PresentationFamilyToPrepareWhat
                    : 'Complete presentation') +
                (!!data.Funeral.Lifestory.PresentationFamilyToPreparePowerpointReceived ? '' : ' / NOT RECEIVED') +
                (data.Funeral.Lifestory.PresentationFamilyToPreparePowerpointNotes
                    ? ' / ' + data.Funeral.Lifestory.PresentationFamilyToPreparePowerpointNotes
                    : '');
        } else if (!!data.Funeral.Lifestory.FoyerPhotoRequired) {
            hasLifestory = 'Foyer Photo Only.';
        }
    }

    if (
        isRelatedObjectDefined(data.Funeral.RefreshmentsVenue) &&
        data.Funeral.RefreshmentsVenue.Type !== 'Not Required'
    ) {
        if (!!data.Funeral.RefreshmentsVenue.Start) {
            refreshments =
                niceDateTimeFromString(data.Funeral.RefreshmentsVenue.Start) +
                ` (${Number(
                    moment(data.Funeral.RefreshmentsVenue.End)
                        .diff(data.Funeral.RefreshmentsVenue.Start, 'hours', true)
                        .toFixed(3)
                )}hrs) `;
        } else {
            refreshments = '(Time not set)';
        }

        refreshments +=
            ' for ' +
            (data.Funeral.RefreshmentsVenue.NumberOfPeople || '(number not set)') +
            ' people - ' +
            (data.Funeral.RefreshmentsVenue.LocationFlattened || '(Location not set)') +
            '. ';

        const catering = data.Funeral.RefreshmentsVenue.CateringStaffAllocations || [];
        if (catering.length > 0) {
            //refreshments +=
            //    ' Catering Staff: ' +
            hasCatering +=
                (joinDefined(
                    catering.map(e => !!e.Confirmed && !!e.CateringStaff && e.CateringStaff.Name),
                    ', '
                ) || '(not assigned)') + '. ';
        }
        if (!isNullOrUndefined(data.Funeral.Caterers) && data.Funeral.Caterers.length > 0) {
            refreshmentsMenu = joinDefined(
                data.Funeral.Caterers.map(item => showBookingLine(item)),
                '. | '
            );
        }
        refreshments += !data.Funeral.RefreshmentsVenue.Comment ? '' : ' / ' + data.Funeral.RefreshmentsVenue.Comment;
    } else {
        refreshments = 'Not required';
    }

    if (!isNullOrUndefined(data.Funeral.Florists) && data.Funeral.Florists.length > 0) {
        florist = data.Funeral.Florists.map((item, idx, theList) => (
            <>
                {theList.length > 1 && <strong> #{1 + idx} </strong>}
                {showBookingLine(item)}.
            </>
        ));
    }

    if (
        (!isNullOrUndefined(data.Funeral.RslMasonic) && data.Funeral.RslMasonic.length > 0) ||
        !!data.Funeral.LastpostRequired ||
        !!data.Funeral.ReveilleRequired ||
        !!data.Funeral.PoppiesRequired
    ) {
        hasRSLMasonic = joinDefined(
            data.Funeral.RslMasonic.map(item => showBookingLine(item)),
            ', '
        );
        hasRSLMasonic += joinDefined(
            [
                !!data.Funeral.LastpostRequired ? '+Last Post' : null,
                !!data.Funeral.ReveilleRequired ? '+Reveille' : null,
                !!data.Funeral.PoppiesRequired ? '+Poppies' : null,
                data.Funeral.RslMasonicComment
            ],
            ' / '
        );
    }

    if (!isNullOrUndefined(data.Funeral.CoffinBearings) && data.Funeral.CoffinBearings.length > 0) {
        //hasServiceBearings = joinDefined(data.Funeral.CoffinBearings.map(item => showBearingLine(item)), ' / ');
        hasGraveBearings = joinDefined(
            data.Funeral.CoffinBearings.map(item => showBearingLine(item, true)),
            '. | '
        );
    }

    if (!isNullOrUndefined(data.Funeral.ValuableItems) && data.Funeral.ValuableItems.length > 0) {
        hasValuables = joinDefined(
            data.Funeral.ValuableItems.map((item, idx, arr) => showValuableLine(item, idx, arr.length)),
            '. | '
        );
    }

    if (data.Funeral.MemorialStationaryBy === 'Family') {
        hasStationary = 'By family.';
    } else if (!isNullOrUndefined(data.Funeral.MemorialStationary) && data.Funeral.MemorialStationary.length > 0) {
        hasStationary = joinDefined(
            data.Funeral.MemorialStationary.map(item => showBookingLine(item)),
            ', '
        );
    }

    if (!isNullOrUndefined(data.Funeral.PersonalisedTouches) && data.Funeral.PersonalisedTouches.length > 0) {
        hasTouches = joinDefined(
            data.Funeral.PersonalisedTouches.map(item => showBookingLine(item)),
            '. | '
        );
    }

    if (
        !!data.Funeral.PlaceOfViewingRequired &&
        !isNullOrUndefined(data.Funeral.PlaceOfViewingItems) &&
        data.Funeral.PlaceOfViewingItems.length > 0
    ) {
        viewing = data.Funeral.PlaceOfViewingItems.map((item, i, a) => {
            const type = item.Type ? item.Type : '(Type not set)';
            const privateViewing = item.ViewingPrivate;
            const advertiseViewing = item.TypeOfViewingAdv;
            const time = (!!item.Start && `${niceDateTimeFromString(item.Start)}`) || '(Time not set)';
            const diff = Number(
                moment(item.End)
                    .diff(item.Start, 'hours', true)
                    .toFixed(3)
            );
            const place = item.LocationFlattened;
            const notes = !item.Comment ? '' : ' / ' + item.Comment;
            return (
                <Fragment>
                    {privateViewing && 'PRIVATE '}
                    {type}, {advertiseViewing ? 'ADVERTISED ' : ' '}
                    {time + (diff ? ' (' + diff + 'hrs) - ' : ' - ') + (place || '(Location not set)') + '. ' + notes}
                </Fragment>
            );
        });
    }
    if (data.Funeral.ReflectionRoomRequired === 'Required' && isRelatedObjectDefined(data.Funeral.ReflectionRoom)) {
        viewing.push(renderReflectionRoom(data.Funeral.ReflectionRoom));
    }

    if (!!data.Funeral.CarRequired) {
        if (!isNullOrUndefined(data.Funeral.Cars) && data.Funeral.Cars.length > 0) {
            hasCars = joinDefined(
                data.Funeral.Cars.map((item, i, a) => {
                    const type = `${a.length > 1 ? ' CAR #' + (1 + i) + ': ' : ''}`;
                    const time =
                        (!!item.Date && `${niceDateTimeFromString(item.Date + ' ' + item.Time)}`) || '(Time not set)';
                    const diff = item.Duration;
                    const place = item.Address;
                    return type + time + (diff ? ' (' + diff + 'hrs) - ' : ' - ') + (place || '(Location not set)');
                }),
                '. '
            );
        } else {
            hasCars = 'Required. ';
        }
    }

    if (!!data.Funeral.LimoRequired) {
        if (!isNullOrUndefined(data.Funeral.LimoBookings) && data.Funeral.LimoBookings.length > 0) {
            hasLimos = joinDefined(
                data.Funeral.LimoBookings.filter(showBookingLimo).map((item, i, a) => {
                    const type = `${a.length > 1 ? ' LIMO #' + (1 + i) + ': ' : ''} `;
                    const line = showBookingLimo(item);
                    return type + line;
                }),
                '. '
            );
        } else if (!isNullOrUndefined(data.Funeral.Limos) && data.Funeral.Limos.length > 0) {
            hasLimos = joinDefined(
                data.Funeral.Limos.map((item, i, a) => {
                    const type = `${a.length > 1 ? ' LIMO #' + (1 + i) + ': ' : ''} `;
                    const time =
                        (!!item.Date && `${niceDateTimeFromString(item.Date + ' ' + item.Time)}`) || '(Time not set)';
                    const diff = item.Duration;
                    const place = item.Address;
                    return type + time + (diff ? ' (' + diff + 'hrs) - ' : ' - ') + (place || '(Location not set)');
                }),
                '. '
            );
        } else {
            hasLimos = 'Required. ';
        }
    }

    if (!isNullOrUndefined(data.Funeral.SpecialInstructions) && data.Funeral.SpecialInstructions.length > 0) {
        checklist = joinDefined(
            data.Funeral.SpecialInstructions.map(item =>
                !!item.Check ? item.Action + (!!item.Comment ? ' (' + item.Comment + ')' : '') : null
            ),
            ' / '
        );
    }

    if (isRelatedObjectDefined(data.Funeral.FuneralDressing)) {
        const dateString = joinDefined([data.Funeral.FuneralDressing.Date, data.Funeral.FuneralDressing.Time], ' ');
        const niceDate = dateString.length > 0 ? niceDateTimeFromString(dateString) : '';

        dressing = joinDefined(
            [
                data.Funeral.FuneralDressing.Status,

                data.Funeral.FuneralDressing.Status === 'Clothing collected at time of Arrangement' ||
                data.Funeral.FuneralDressing.Status === 'Clothing will be delivered to Funeral Home'
                    ? !!data.Funeral.FuneralDressing.Received
                        ? 'was received'
                        : 'NOT RECEIVED ' + niceDate
                    : null,

                !!data.Funeral.FuneralDressing.Shroud ? '+Shroud' : null,
                !!data.Funeral.FuneralDressing.GreekShroud ? '+Greek Shroud' : null,

                data.Funeral.FuneralDressing.Comment
            ],
            ' / '
        );
    }

    if (!!data.Funeral.EnterMusicByWho && !isNullOrUndefined(data.Funeral.EnterMusicSong)) {
        enterMusicSongs += joinDefined(
            [data.Funeral.EnterMusicNotes].concat(
                data.Funeral.EnterMusicSong.map(item => `"${item.Name}" by ${item.Artist || '(unknown)'}`)
            ),
            ', '
        );
    }
    if (!!data.Funeral.ExitMusicByWho && !isNullOrUndefined(data.Funeral.ExitMusicSongs)) {
        exitMusicSongs += joinDefined(
            [data.Funeral.ExitMusicNotes].concat(
                data.Funeral.ExitMusicSongs.map(item => `"${item.Name}" by ${item.Artist || '(unknown)'}`)
            ),
            ', '
        );
    }
    if (!!data.Funeral.GravesideMusicByWho && !isNullOrUndefined(data.Funeral.GravesideMusicSongs)) {
        gravesideMusicSongs += joinDefined(
            [data.Funeral.GravesideMusicNotes].concat(
                data.Funeral.GravesideMusicSongs.map(item => `"${item.Name}" by ${item.Artist || '(unknown)'}`)
            ),
            ', '
        );
    }
    if (!!data.Funeral.ReflectionMusicByWho && !isNullOrUndefined(data.Funeral.ReflectionMusicSongs)) {
        reflectionMusicSongs += joinDefined(
            [data.Funeral.ReflectionMusicNotes].concat(
                data.Funeral.ReflectionMusicSongs.map(
                    item =>
                        `"${item.Name}" by ${item.Artist || '(unknown)'}` +
                        (!!item.IncludeInLifeStory ? ' - played during life story' : '') +
                        (!!item.ForFlowerPlacement ? ' - played during flowers' : '') +
                        (!!item.OtherText ? ' - ' + item.OtherText : '')
                )
            ),
            ', '
        );
    }

    if (data.Funeral.EnterMusicByWho === 'musician') {
        if (!!data.Funeral.EnterMusicians && data.Funeral.EnterMusicians.length > 0) {
            enterMusicSongs +=
                ' performed by ' +
                joinDefined(
                    data.Funeral.EnterMusicians.map(
                        item =>
                            `${
                                !!item.Contact.Name
                                    ? item.Contact.Name + (item.Confirmed ? '' : ' NOT CONFIRMED')
                                    : '(not set)'
                            }`
                    ),
                    ', '
                );
        } else {
            enterMusicSongs +=
                ' performed by ' +
                joinDefined(
                    data.Funeral.DefaultMusicians.map(
                        item =>
                            `${
                                !!item.Contact.Name
                                    ? item.Contact.Name + (item.Confirmed ? '' : ' NOT CONFIRMED')
                                    : '(not set)'
                            }`
                    ),
                    ', '
                );
        }
    }
    if (data.Funeral.ExitMusicByWho === 'musician') {
        if (!!data.Funeral.ExitMusicians && data.Funeral.ExitMusicians.length > 0) {
            exitMusicSongs +=
                ' performed by ' +
                joinDefined(
                    data.Funeral.ExitMusicians.map(
                        item =>
                            `${
                                !!item.Contact.Name
                                    ? item.Contact.Name + (item.Confirmed ? '' : ' NOT CONFIRMED')
                                    : '(not set)'
                            }`
                    ),
                    ', '
                );
        } else {
            exitMusicSongs +=
                ' performed by ' +
                joinDefined(
                    data.Funeral.DefaultMusicians.map(
                        item =>
                            `${
                                !!item.Contact.Name
                                    ? item.Contact.Name + (item.Confirmed ? '' : ' NOT CONFIRMED')
                                    : '(not set)'
                            }`
                    ),
                    ', '
                );
        }
    }
    if (data.Funeral.GravesideMusicByWho === 'musician') {
        if (!!data.Funeral.GravesideMusicians && data.Funeral.GravesideMusicians.length > 0) {
            gravesideMusicSongs +=
                ' performed by ' +
                joinDefined(
                    data.Funeral.GravesideMusicians.map(
                        item =>
                            `${
                                !!item.Contact.Name
                                    ? item.Contact.Name + (item.Confirmed ? '' : ' NOT CONFIRMED')
                                    : '(not set)'
                            }`
                    ),
                    ', '
                );
        } else {
            gravesideMusicSongs +=
                ' performed by ' +
                joinDefined(
                    data.Funeral.DefaultMusicians.map(
                        item =>
                            `${
                                !!item.Contact.Name
                                    ? item.Contact.Name + (item.Confirmed ? '' : ' NOT CONFIRMED')
                                    : '(not set)'
                            }`
                    ),
                    ', '
                );
        }
    }
    if (data.Funeral.ReflectionMusicByWho === 'musician') {
        if (!!data.Funeral.ReflectionMusicians && data.Funeral.ReflectionMusicians.length > 0) {
            reflectionMusicSongs +=
                ' performed by ' +
                joinDefined(
                    data.Funeral.ReflectionMusicians.map(
                        item =>
                            `${
                                !!item.Contact.Name
                                    ? item.Contact.Name + (item.Confirmed ? '' : ' NOT CONFIRMED')
                                    : '(not set)'
                            }`
                    ),
                    ', '
                );
        } else {
            reflectionMusicSongs +=
                ' performed by ' +
                joinDefined(
                    data.Funeral.DefaultMusicians.map(
                        item =>
                            `${
                                !!item.Contact.Name
                                    ? item.Contact.Name + (item.Confirmed ? '' : ' NOT CONFIRMED')
                                    : '(not set)'
                            }`
                    ),
                    ', '
                );
        }
    }

    if (!!data.Funeral.DefaultMusicians && data.Funeral.DefaultMusicians.length > 0) {
        hasDefaultMusos += joinDefined(
            data.Funeral.DefaultMusicians.map(
                item =>
                    `${
                        !!item.Contact.Name ? item.Contact.Name + (item.Confirmed ? '' : ' NOT CONFIRMED') : '(not set)'
                    }`
            ),
            ', '
        );
    }

    if (isRelatedObjectDefined(data.Funeral.Informant)) {
        applicantName = joinDefined(
            [data.Funeral.Informant.InformantsName, data.Funeral.Informant.RelationshipToDeceased],
            ' - '
        );
    }

    // GRAVESIDE SERVICE
    if (
        data.ClassName === 'FuneralManagerFuneralDisposal' &&
        !!data.Funeral.PlaceOfService &&
        data.Funeral.PlaceOfService.Type === 'Graveside'
    ) {
        return (
            <Fragment>
                <span className={classes.fullWidth}>
                    {!!privateCommittal ? <strong>PRIVATE COMMITTAL:</strong> : <strong>COMMITTAL:</strong>}{' '}
                    <strong>GRAVESIDE SERVICE </strong>
                    {disposalType}
                    {!!noAttendCommittal && <strong> NO ATTENDANCE. </strong>}
                    {!!disposalComment}
                </span>

                <span className={classes.fullWidth}>
                    <strong>COFFIN:</strong> {coffinDescription !== '' ? coffinDescription : '(Coffin not set)'}
                    {'. ADORNMENTS: ' + (joinDefined(coffinExtras, ' | ') || 'None')}
                    {!!data.Funeral.CoffinComment ? '. ' + data.Funeral.CoffinComment : '.'}
                </span>
                {!!hasGraveBearings && (
                    <span className={classes.fullWidth}>
                        <strong>BEARERS:</strong> {hasGraveBearings}.
                    </span>
                )}
                {!!cremationReferee && (
                    <span className={classes.fullWidth}>
                        <strong>REFEREE:</strong> {cremationReferee}
                    </span>
                )}
                {!!celebrant && (
                    <span className={classes.fullWidth}>
                        <strong>CELEBRANT:</strong> {celebrant}.
                    </span>
                )}
                {!!donations && (
                    <span className={classes.fullWidth}>
                        <strong>DONATIONS:</strong> {donations}
                    </span>
                )}
                {!!florist && (
                    <span className={classes.fullWidth}>
                        <strong>FLOWERS:</strong> {florist}
                    </span>
                )}
                {!!hasRSLMasonic && (
                    <span className={classes.fullWidth}>
                        <strong>RSL/MASONIC:</strong> {hasRSLMasonic}
                    </span>
                )}
                {!!hasFlag && (
                    <span className={classes.fullWidth}>
                        <strong>FLAG:</strong> {hasFlag}
                    </span>
                )}
                {viewing.length > 0 ? (
                    <span className={classes.fullWidth}>
                        <strong>VIEWINGS:</strong>
                        {viewing.map((e, i, a) => {
                            return (
                                <Fragment key={i}>
                                    {a.length > 1 && <strong> #{1 + i} </strong>}
                                    {e}
                                </Fragment>
                            );
                        })}
                    </span>
                ) : (
                    <span className={classes.fullWidth}>
                        <strong>VIEWINGS:</strong> Not required.
                    </span>
                )}
                {!!checklist && (
                    <span className={classes.fullWidth}>
                        <strong>SPECIAL INSTRUCTIONS:</strong> {checklist}.
                    </span>
                )}
                {!!hasTouches && (
                    <span className={classes.fullWidth}>
                        <strong>PERSONAL TOUCHES:</strong> {hasTouches}
                    </span>
                )}
                {!!hasStationary && (
                    <span className={classes.fullWidth}>
                        <strong>MEMORIAL STATIONERY:</strong> {hasStationary}
                    </span>
                )}
                {!!hasWebcast && (
                    <span className={classes.fullWidth}>
                        <strong>WEBCAST:</strong> {hasWebcast}
                    </span>
                )}
                {!!hasNamesheets && (
                    <span className={classes.fullWidth}>
                        <strong>NAMESHEETS:</strong> {hasNamesheets}
                    </span>
                )}
                {!!hasMassbook && (
                    <span className={classes.fullWidth}>
                        <strong>MASSBOOK:</strong> {hasMassbook}
                    </span>
                )}
                {!!hasLifestory && (
                    <span className={classes.fullWidth}>
                        <strong>LIFE STORY PRESENTATION:</strong> {hasLifestory}
                    </span>
                )}

                {!!hasHearse && (
                    <span className={classes.fullWidth}>
                        <strong>HEARSE:</strong> {hasHearse}
                    </span>
                )}
                {!!hasCars && (
                    <span className={classes.fullWidth}>
                        <strong>CAR: </strong> {hasCars}
                    </span>
                )}
                {!!hasLimos && (
                    <span className={classes.fullWidth}>
                        <strong>LIMO: </strong> {hasLimos}
                    </span>
                )}

                {hasValuables && (
                    <span className={classes.fullWidth}>
                        <strong>VALUABLES:</strong> {hasValuables}.
                    </span>
                )}
                {!!dressing && (
                    <span className={classes.fullWidth}>
                        <strong>DRESSING:</strong> {dressing}.
                    </span>
                )}
                {!!isReligious && (
                    <span className={classes.fullWidth}>
                        <strong>RELIGIOUS:</strong> {isReligious}.
                    </span>
                )}

                {(!!enterMusicSongs || !!reflectionMusicSongs || !!exitMusicSongs || !!gravesideMusicSongs) && (
                    <span className={classes.fullWidth}>
                        <strong>MUSIC: </strong>
                        {!!enterMusicSongs && 'ENTER MUSIC: ' + enterMusicSongs + '. '}
                        {!!reflectionMusicSongs && 'REFLECTION MUSIC: ' + reflectionMusicSongs + '. '}
                        {!!exitMusicSongs && 'EXIT MUSIC: ' + exitMusicSongs + '. '}
                        {!!gravesideMusicSongs && 'GRAVESIDE MUSIC: ' + gravesideMusicSongs + '. '}
                    </span>
                )}
                {!!hasDefaultMusos && (
                    <span className={classes.fullWidth}>
                        <strong>MUSICIANS:</strong> {hasDefaultMusos}
                    </span>
                )}
                {!!refreshments && (
                    <span className={classes.fullWidth}>
                        <strong>REFRESHMENTS:</strong> {refreshments}
                    </span>
                )}
                {!!applicantName && (
                    <span className={classes.fullWidth}>
                        <strong>APPLICANT:</strong> {applicantName}.
                    </span>
                )}
            </Fragment>
        );
    }

    // COMMITTAL
    if (data.ClassName === 'FuneralManagerFuneralDisposal') {
        return (
            <Fragment>
                <span className={classes.fullWidth}>
                    {!!privateCommittal ? <strong>PRIVATE COMMITTAL:</strong> : <strong>COMMITTAL:</strong>}{' '}
                    {disposalType}
                    {!!followToCommital && <strong> FOLLOW TO COMMITTAL. </strong>}
                    {!!noAttendCommittal && <strong> NO ATTENDANCE. </strong>}
                    {!!disposalComment}
                </span>

                {!!cremationReferee && (
                    <span className={classes.fullWidth}>
                        <strong>REFEREE:</strong> {cremationReferee}
                    </span>
                )}

                <span className={classes.fullWidth}>
                    <strong>COFFIN:</strong> {!!coffinDescription ? coffinDescription : '(Coffin not set)'}
                    {'. ADORNMENTS: ' + (joinDefined(coffinExtras, ' | ') || 'None')}
                    {!!data.Funeral.CoffinComment ? '. ' + data.Funeral.CoffinComment : '.'}
                </span>
                {!!hasGraveBearings && (
                    <span className={classes.fullWidth}>
                        <strong>BEARERS:</strong> {hasGraveBearings}.
                    </span>
                )}

                {!!celebrant && (
                    <span className={classes.fullWidth}>
                        <strong>CELEBRANT:</strong> {celebrant}.
                    </span>
                )}

                {!!checklist && (
                    <span className={classes.fullWidth}>
                        <strong>SPECIAL INSTRUCTIONS:</strong> {checklist}.
                    </span>
                )}

                {!!hasHearse && (
                    <span className={classes.fullWidth}>
                        <strong>HEARSE:</strong> {hasHearse}
                    </span>
                )}
                {!!hasCars && (
                    <span className={classes.fullWidth}>
                        <strong>CAR: </strong> {hasCars}
                    </span>
                )}
                {!!hasLimos && (
                    <span className={classes.fullWidth}>
                        <strong>LIMO: </strong> {hasLimos}
                    </span>
                )}

                {hasValuables && (
                    <span className={classes.fullWidth}>
                        <strong>VALUABLES:</strong> {hasValuables}.
                    </span>
                )}
                {!!dressing && (
                    <span className={classes.fullWidth}>
                        <strong>DRESSING:</strong> {dressing}
                    </span>
                )}
                {!!isReligious && (
                    <span className={classes.fullWidth}>
                        <strong>RELIGIOUS:</strong> {isReligious}.
                    </span>
                )}

                {!!gravesideMusicSongs && (
                    <span className={classes.fullWidth}>
                        <strong>GRAVESIDE MUSIC:</strong> {gravesideMusicSongs || 'none'}.
                    </span>
                )}

                {!!applicantName && (
                    <span className={classes.fullWidth}>
                        <strong>APPLICANT:</strong> {applicantName}.
                    </span>
                )}
            </Fragment>
        );
    }

    // VIEWING
    if (data.ClassName === 'FuneralManagerFuneralPlaceOfViewing') {
        const item = data.Funeral.PlaceOfViewingItems.find(e => e.ID === data.ID);
        const type = item.Type ? item.Type.toUpperCase() : '(Type not set)';
        const privateViewing = item.ViewingPrivate;
        const advertiseViewing = item.TypeOfViewingAdv;
        const time = (!!item.Start && `${niceDateTimeFromString(item.Start)}`) || '(Time not set)';
        const diff = Number(
            moment(item.End)
                .diff(item.Start, 'hours', true)
                .toFixed(3)
        );
        const place = item.LocationFlattened;
        const notes = !item.Comment ? '' : ' / ' + item.Comment;
        const thisViewing = (
            <Fragment>
                {advertiseViewing && ' ADVERTISED '}
                {time + (diff ? ' (' + diff + 'hrs) - ' : ' - ') + (place || '(Location not set)') + '.' + notes}
            </Fragment>
        );

        return (
            <Fragment>
                <span className={classes.fullWidth}>
                    <strong>
                        {privateViewing && ' PRIVATE '}
                        {type}:
                    </strong>{' '}
                    {thisViewing}
                </span>

                <span className={classes.fullWidth}>
                    <strong>COFFIN:</strong> {coffinDescription !== '' ? coffinDescription : '(Coffin not set)'}
                    {'. ADORNMENTS: ' + (joinDefined(coffinExtras, ' | ') || 'None')}
                    {!!data.Funeral.CoffinComment ? '. ' + data.Funeral.CoffinComment : '.'}
                </span>

                {!!hasCars && (
                    <span className={classes.fullWidth}>
                        <strong>CAR: </strong> {hasCars}
                    </span>
                )}
                {!!hasLimos && (
                    <span className={classes.fullWidth}>
                        <strong>LIMO: </strong> {hasLimos}
                    </span>
                )}

                {hasValuables && (
                    <span className={classes.fullWidth}>
                        <strong>VALUABLES:</strong> {hasValuables}.
                    </span>
                )}
                {!!dressing && (
                    <span className={classes.fullWidth}>
                        <strong>DRESSING:</strong> {dressing}.
                    </span>
                )}

                {!!applicantName && (
                    <span className={classes.fullWidth}>
                        <strong>APPLICANT:</strong> {applicantName}.
                    </span>
                )}
            </Fragment>
        );
    }

    // SERVICE (not graveside service)
    if (data.ClassName === 'FuneralManagerFuneralPlaceOfService') {
        return (
            <Fragment>
                <span className={classes.fullWidth}>
                    <strong>SERVICE:</strong> {placeOfService}
                    {!!followToCommital && <strong> FOLLOW TO COMMITTAL. </strong>}
                </span>

                <span className={classes.fullWidth}>
                    <strong>COFFIN:</strong> {coffinDescription !== '' ? coffinDescription : '(Coffin not set)'}
                    {'. ADORNMENTS: ' + (joinDefined(coffinExtras, ' | ') || 'None')}
                    {!!data.Funeral.CoffinComment ? '. ' + data.Funeral.CoffinComment : '.'}
                </span>
                {!!hasGraveBearings && (
                    <span className={classes.fullWidth}>
                        <strong>BEARERS:</strong> {hasGraveBearings}.
                    </span>
                )}

                <span className={classes.fullWidth}>
                    {!!privateCommittal ? <strong>PRIVATE COMMITTAL:</strong> : <strong>COMMITTAL:</strong>}{' '}
                    {disposalType}
                    {!!noAttendCommittal && <strong> NO ATTENDANCE. </strong>}
                </span>

                {!!cremationReferee && (
                    <span className={classes.fullWidth}>
                        <strong>REFEREE:</strong> {cremationReferee}
                    </span>
                )}
                {!!celebrant && (
                    <span className={classes.fullWidth}>
                        <strong>CELEBRANT:</strong> {celebrant}.
                    </span>
                )}
                {!!donations && (
                    <span className={classes.fullWidth}>
                        <strong>DONATIONS:</strong> {donations}
                    </span>
                )}
                {!!florist && (
                    <span className={classes.fullWidth}>
                        <strong>FLOWERS:</strong> {florist}
                    </span>
                )}
                {!!hasRSLMasonic && (
                    <span className={classes.fullWidth}>
                        <strong>RSL/MASONIC:</strong> {hasRSLMasonic}
                    </span>
                )}
                {!!hasFlag && (
                    <span className={classes.fullWidth}>
                        <strong>FLAG:</strong> {hasFlag}
                    </span>
                )}
                {viewing.length > 0 ? (
                    <span className={classes.fullWidth}>
                        <strong>VIEWINGS:</strong>
                        {viewing.map((e, i, a) => {
                            return (
                                <Fragment key={i}>
                                    {a.length > 1 && <strong> #{1 + i} </strong>}
                                    {e}
                                </Fragment>
                            );
                        })}
                    </span>
                ) : (
                    <span className={classes.fullWidth}>
                        <strong>VIEWINGS:</strong> Not required.
                    </span>
                )}
                {!!checklist && (
                    <span className={classes.fullWidth}>
                        <strong>SPECIAL INSTRUCTIONS:</strong> {checklist}.
                    </span>
                )}
                {!!hasTouches && (
                    <span className={classes.fullWidth}>
                        <strong>PERSONAL TOUCHES:</strong> {hasTouches}
                    </span>
                )}
                {!!hasStationary && (
                    <span className={classes.fullWidth}>
                        <strong>MEMORIAL STATIONERY:</strong> {hasStationary}
                    </span>
                )}
                {!!hasWebcast && (
                    <span className={classes.fullWidth}>
                        <strong>WEBCAST:</strong> {hasWebcast}
                    </span>
                )}
                {!!hasNamesheets && (
                    <span className={classes.fullWidth}>
                        <strong>NAMESHEETS:</strong> {hasNamesheets}
                    </span>
                )}
                {!!hasMassbook && (
                    <span className={classes.fullWidth}>
                        <strong>MASSBOOK:</strong> {hasMassbook}
                    </span>
                )}
                {!!hasLifestory && (
                    <span className={classes.fullWidth}>
                        <strong>LIFE STORY PRESENTATION:</strong> {hasLifestory}
                    </span>
                )}

                {!!hasHearse && (
                    <span className={classes.fullWidth}>
                        <strong>HEARSE:</strong> {hasHearse}
                    </span>
                )}
                {!!hasCars && (
                    <span className={classes.fullWidth}>
                        <strong>CAR: </strong> {hasCars}
                    </span>
                )}
                {!!hasLimos && (
                    <span className={classes.fullWidth}>
                        <strong>LIMO: </strong> {hasLimos}
                    </span>
                )}

                {hasValuables && (
                    <span className={classes.fullWidth}>
                        <strong>VALUABLES:</strong> {hasValuables}.
                    </span>
                )}
                {!!dressing && (
                    <span className={classes.fullWidth}>
                        <strong>DRESSING:</strong> {dressing}.
                    </span>
                )}
                {!!isReligious && (
                    <span className={classes.fullWidth}>
                        <strong>RELIGIOUS:</strong> {isReligious}.
                    </span>
                )}

                {(!!enterMusicSongs || !!reflectionMusicSongs || !!exitMusicSongs || !!gravesideMusicSongs) && (
                    <span className={classes.fullWidth}>
                        <strong>MUSIC: </strong>
                        {!!enterMusicSongs && 'ENTER MUSIC: ' + enterMusicSongs + '. '}
                        {!!reflectionMusicSongs && 'REFLECTION MUSIC: ' + reflectionMusicSongs + '. '}
                        {!!exitMusicSongs && 'EXIT MUSIC: ' + exitMusicSongs + '. '}
                        {!!gravesideMusicSongs && 'GRAVESIDE MUSIC: ' + gravesideMusicSongs + '. '}
                    </span>
                )}
                {!!hasDefaultMusos && (
                    <span className={classes.fullWidth}>
                        <strong>MUSICIANS:</strong> {hasDefaultMusos}
                    </span>
                )}
                {!!refreshments && (
                    <span className={classes.fullWidth}>
                        <strong>REFRESHMENTS:</strong> {refreshments}
                        {!!hasCatering && <span>CATERING STAFF: {hasCatering}</span>}
                        {!!refreshmentsMenu && <span>CATERING MENU: {refreshmentsMenu}</span>}
                    </span>
                )}

                {!!applicantName && (
                    <span className={classes.fullWidth}>
                        <strong>APPLICANT:</strong> {applicantName}.
                    </span>
                )}
            </Fragment>
        );
    }

    // REFRESHMENTS
    if (data.ClassName === 'FuneralManagerRefreshmentsVenue') {
        return (
            <Fragment>
                {!!refreshments && (
                    <span className={classes.fullWidth}>
                        <strong>REFRESHMENTS:</strong> {refreshments}
                    </span>
                )}
                {!!hasCatering && (
                    <span className={classes.fullWidth}>
                        <strong>CATERING STAFF:</strong> {hasCatering}
                    </span>
                )}
                {!!refreshmentsMenu && (
                    <span className={classes.fullWidth}>
                        <strong>CATERING MENU:</strong> {refreshmentsMenu}
                    </span>
                )}
                {!!celebrant && (
                    <span className={classes.fullWidth}>
                        <strong>CELEBRANT:</strong> {celebrant}.
                    </span>
                )}
                {!!hasCars && (
                    <span className={classes.fullWidth}>
                        <strong>CAR: </strong> {hasCars}
                    </span>
                )}
                {!!hasLimos && (
                    <span className={classes.fullWidth}>
                        <strong>LIMO: </strong> {hasLimos}
                    </span>
                )}
                {!!applicantName && (
                    <span className={classes.fullWidth}>
                        <strong>APPLICANT:</strong> {applicantName}.
                    </span>
                )}
            </Fragment>
        );
    }
    // REFLECTION ROOM
    if (data.ClassName === 'FuneralManagerReflectionRoom') {
        return (
            <Fragment>
                {renderReflectionRoom(data)}
                {!!applicantName && (
                    <span className={classes.fullWidth}>
                        <strong>APPLICANT:</strong> {applicantName}.
                    </span>
                )}
            </Fragment>
        );
    }
};
