import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { compose, Mutation } from 'react-apollo';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ContactView from './ContactView';
import ContactCharges from './ContactCharges';
import ContactAddNote from './ContactAddNote';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '../../component/icon/DeleteIcon';
import EditIcon from '../../component/icon/EditIcon';
import EnvelopeSquareIcon from '../../component/icon/EnvelopeSquareIcon';
import FaxIcon from '../../component/icon/FaxIcon';
import MapMarkerIcon from '../../component/icon/MapMarkerIcon';
import PhoneSquareIcon from '../../component/icon/PhoneSquareIcon';
import UsdCircleIcon from '../../component/icon/UsdCircleIcon';
import MobileIcon from '../../component/icon/MobileIcon';
import { OutlineButton } from '../../component/form/PrimaryButton';
import { prettyEmail, prettyPhone } from '../../util/strings';
import { CONTACT_CATEGORY_OPTIONS, CONTACT_CLERGY_OPTIONS } from './contactConstants';
import cx from 'classnames';
import gql from 'graphql-tag';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import EditContactModal from '../../component/EditContactModal';
import GatedComponent from '../../component/GatedComponent';

// To disable the delete button, pass in disableDelete=1 props
class ContactCard extends React.Component {
    state = {
        item: null,
        addressBookModalOpen: false
    };

    static getDerivedStateFromProps({ contact }, { item }) {
        if (contact && (!item || contact.ID !== item.ID)) {
            return { item: { ...contact } };
        }

        return null;
    }

    updateContact = data => {
        const newState = { addressBookModalOpen: false };
        if (data) newState.item = data;
        this.setState(newState);
    };

    updateNote = note => {
        const newState = { ...this.state.item };
        newState.Notes = note.length ? note : null;
        this.setState({ item: newState });
    };

    onSaveCompleted = data => {
        const item = data.updateAddressBook;
        const newState = { ...this.state.item };
        newState.NotCurrent = item.NotCurrent;
        this.setState({ item: newState });
    };

    render() {
        const { classes } = this.props;
        const isGone = !!(this.state.item && this.state.item.NotCurrent);
        const { addressBookModalOpen } = this.state;

        return (
            <Card className={cx(classes.card, isGone ? classes.deleted : '')}>
                <CardContent>
                    <div className={classes.headerButtons}>{this.renderActionButtonsRightTop()}</div>
                    {this.renderName()}
                    {this.renderSubtitle()}
                    <div className={classes.contactDetails}>
                        {this.renderAddress()}
                        {this.renderDetails()}
                    </div>
                    <div className={classes.footerButtons}>{this.renderActionButtonsRightBottom()}</div>
                </CardContent>
                <GatedComponent showComponentCode={'FM_ACCESS_Contacts_Create'}>
                    {() => {
                        return (
                            <EditContactModal
                                onClose={this.updateContact}
                                open={!!addressBookModalOpen}
                                contactId={this.state.item.ID}
                            />
                        );
                    }}
                </GatedComponent>
            </Card>
        );
    }

    renderSubtitle() {
        const { classes } = this.props;
        const hasContact = this.state.item.Contact && this.state.item.Contact.length > 0;
        const cat = CONTACT_CATEGORY_OPTIONS.find(e => {
            return e.value === this.state.item.AddressBookCategory;
        });
        const clg = CONTACT_CLERGY_OPTIONS.find(e => {
            return e.value === this.state.item.ClergyCategory;
        });
        return (
            <div>
                <Typography className={classes.name} component="h2">
                    {hasContact && <span className={classes.name}>{this.state.item.Contact}</span>}
                    {this.state.item.AddressBookCategory && (
                        <Typography className={classes.category} component="span">
                            {cat ? cat.label : this.state.item.AddressBookCategory}
                        </Typography>
                    )}
                    {this.state.item.ClergyCategory && (
                        <Typography className={classes.category} component="span">
                            {clg ? clg.label : this.state.item.ClergyCategory}
                            {clg &&
                                (Number(clg.value) === 9 || Number(clg.value) === 11) &&
                                this.state.item.Religion && <span> - {this.state.item.Religion}</span>}
                        </Typography>
                    )}
                </Typography>
            </div>
        );
    }

    renderName() {
        const { classes } = this.props;
        return (
            <Typography className={classes.companyName} variant="headline" component="h2">
                {this.state.item.Name}
            </Typography>
        );
    }

    renderActionButtonsRightTop() {
        const { classes, disableDelete } = this.props;
        const { mutation, variables } = this.getRetirementMutation();
        return (
            <Fragment>
                <IconButton
                    className={classes.buttonEdit}
                    aria-label="Edit"
                    title="Edit"
                    onClick={() => this.setState({ addressBookModalOpen: true })}
                >
                    <EditIcon />
                </IconButton>

                {!disableDelete && (
                    <GatedComponent showComponentCode={'FM_ACCESS_Contacts_Delete'}>
                        {() => {
                            return (
                                <Mutation
                                    mutation={mutation}
                                    onCompleted={this.onSaveCompleted}
                                    onError={e => this.handleMutateError(e)}
                                >
                                    {(mutate, { loading }) => {
                                        const disabled = loading;
                                        return (
                                            <IconButton
                                                className={
                                                    !this.state.item.NotCurrent
                                                        ? classes.buttonDelete
                                                        : classes.buttonRestore
                                                }
                                                aria-label="Delete"
                                                title={this.state.item.NotCurrent ? 'Restore' : 'Retire'}
                                                disabled={disabled}
                                                onClick={e => this.onDeleteClicked(mutate, !disabled && variables, e)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        );
                                    }}
                                </Mutation>
                            );
                        }}
                    </GatedComponent>
                )}
            </Fragment>
        );
    }

    renderActionButtonsRightBottom() {
        const { classes } = this.props;
        const contact = this.state.item;
        return (
            <Typography className={classes.actionRightBottom} component="p">
                {contact.ClergyCategory > 0 && (
                    <ContactCharges contactId={contact.ID}>
                        <IconButton
                            className={contact.ClergyBaseRate > 0 ? classes.buttonInvoice : classes.buttonNoInvoice}
                            aria-label="Charges"
                            title="Charges"
                        >
                            <UsdCircleIcon />
                        </IconButton>
                    </ContactCharges>
                )}
                <GatedComponent showComponentCode={'FM_ACCESS_Contacts_View'}>
                    {() => {
                        return (
                            <ContactAddNote
                                onClose={this.updateNote}
                                contactId={contact.ID}
                                contactNotes={contact.Notes}
                                contact={contact}
                            />
                        );
                    }}
                </GatedComponent>
                <ContactView contactId={contact.ID}>
                    <OutlineButton color={'purple'} title={'View All'}>
                        Details...
                    </OutlineButton>
                </ContactView>
            </Typography>
        );
    }

    renderAddress() {
        const contact = this.state.item;
        if (!(contact.State || contact.Suburb || contact.Postcode)) return;

        const { classes } = this.props;

        const addressData = [];
        if (contact.Address) addressData.push(contact.Address);
        else {
            if (contact.AddressLine1) addressData.push(contact.AddressLine1);
            if (contact.AddressLine2) addressData.push(contact.AddressLine2);
        }
        if (contact.State) addressData.push(contact.State);
        if (contact.Suburb) addressData.push(contact.Suburb);
        if (contact.Postcode) addressData.push(contact.Postcode);

        return (
            <div className={cx(classes.textWithIcon, classes.addressDetails)}>
                <MapMarkerIcon className={classes.iconLight} title={'Address'} />
                <span>{addressData.join(', ')}</span>
            </div>
        );
    }

    renderDetails() {
        const { classes } = this.props;
        const contact = this.state.item;

        // contact.Email = 'thisisanemail@test.com.au';
        // contact.Fax = '0212341234';
        // contact.Phone = '0212341234';
        // contact.Mobile = '0400123123';

        return (
            <div className={classes.flexContainer}>
                {contact.Phone && (
                    <div className={classes.textWithIcon}>
                        <PhoneSquareIcon className={classes.iconLight} title={'Phone'} />
                        {prettyPhone(contact.Phone)}
                    </div>
                )}
                {contact.Mobile && (
                    <div className={classes.textWithIcon}>
                        <MobileIcon className={classes.iconLight} title={'Mobile'} />
                        {prettyPhone(contact.Mobile)}
                    </div>
                )}
                {contact.Fax && (
                    <div className={classes.textWithIcon}>
                        <FaxIcon className={classes.iconLight} title={'Fax'} />
                        {prettyPhone(contact.Fax)}
                    </div>
                )}
                {contact.Email && (
                    <div className={classes.textWithIcon}>
                        <EnvelopeSquareIcon className={classes.iconLight} title={'Email'} />
                        {prettyEmail(contact.Email)}
                    </div>
                )}
            </div>
        );
    }

    onDeleteClicked(mutate, variables, e) {
        e.preventDefault();
        let msg = "You're about to retire this contact.\nAre you sure?";
        if (!variables.input.NotCurrent) msg = "You're about to restore this contact.\nAre you sure?";
        const axe = window.confirm(msg);
        if (axe) {
            if (variables) mutate({ variables });
        }
    }

    getRetirementMutation() {
        const item = this.state.item;
        return {
            mutation: gql`
                mutation RetireContact($input: UpdateAddressBookInput!) {
                    updateAddressBook(input: $input) {
                        ID
                        NotCurrent
                    }
                }
            `,
            variables: {
                input: { NotCurrent: !item.NotCurrent, ID: item.ID }
            }
        };
    }

    handleMutateError(e) {
        console.error('A GQL Mutation Error Ocurred:', e);
        this.props.setSnackbarMessage(
            'Oops, there was an error - your changes have NOT saved.',
            false,
            null,
            new Error(e)
        );
    }
}

const styles = ({ palette, breakpoints, typography }) => ({
    flexContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            marginTop: '10px',
            minWidth: '50%'
        }
    },
    card: {
        minWidth: 275,
        position: 'relative',
        padding: 0,
        minHeight: 230,
        overflow: 'hidden',
        margin: -10
    },
    deleted: {
        opacity: 0.5,
        background: '#ffffffcc'
    },
    contactDetails: {
        marginBottom: 24
    },
    name: {
        marginTop: '10px',
        fontSize: '1rem',
        color: palette.highlight.blue,
        '& > span': {
            marginRight: '10px'
        }
    },
    category: {
        color: palette.tag.foreground,
        backgroundColor: palette.tag.background,
        borderRadius: 5,
        display: 'inline-block',
        padding: '0.125rem 0.5rem',
        margin: '0.05rem 10px 0 0 !important',
        fontSize: '0.875rem'
    },
    companyName: {
        fontWeight: typography.fontWeightMedium,
        fontSize: '1rem'
    },
    buttonDelete: {
        height: '32px',
        width: '32px',
        '& svg': {
            height: '18px'
        }
    },
    buttonRestore: {
        height: '32px',
        width: '32px',
        '& svg': {
            height: '18px',
            transform: 'rotate(180deg)'
        }
    },
    buttonEdit: {
        height: '32px',
        width: '32px',
        '& svg': {
            height: '18px'
        }
    },
    actionRightBottom: {
        textAlign: 'right'
    },
    buttonNoInvoice: {
        opacity: 0.5
    },
    iconLight: {
        color: palette.custom.lightishGrey,
        marginRight: '10px'
    },
    textWithIcon: {
        display: 'flex',
        fontSize: '0.75rem',
        wordBreak: 'break-word',
        alignItems: 'center'
    },
    addressDetails: {
        margin: '15px 0 0 0'
    },
    headerButtons: {
        float: 'right',
        marginTop: -8,
        marginRight: -16
    },
    footerButtons: {
        bottom: 8,
        right: 16,
        position: 'absolute'
    }
});

export default compose(withRouter, withSnackbarMessage, withStyles(styles))(ContactCard);
