import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import cx from 'classnames';

class TabbedModal extends Component {
    static defaultProps = {
        onClose: () => {}
    };

    state = {};

    render() {
        const {
            classes,
            className,
            contentClassName,
            tabs,
            onChange,
            children,
            open,
            fullScreen,
            ...other
        } = this.props;

        return (
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                className={classes.diagRoot}
                classes={{ paper: cx(className, classes.root, { [classes.rootFullScreen]: other.fullScreen }) }}
                open={open}
                fullScreen={fullScreen}
            >
                <div className={classes.tabHeaders}>{tabs.map(this.renderTabButton)}</div>

                <Paper className={cx(classes.paper, contentClassName)} elevation={0}>
                    {children}
                </Paper>
            </Dialog>
        );
    }

    renderTabButton = (tab, index) => {
        const { classes, tabIndex, onChange, tabs } = this.props;
        const highlighted = tabIndex !== index;
        const tabLabel = tabs[index].label || tab;
        const tabKey = tabs[index].key || tab;
        return (
            <ButtonBase
                focusRipple
                disabled={!highlighted}
                className={cx(classes.tabButton, { [classes.tabButtonHighlighted]: !highlighted })}
                key={index}
                onClick={() => onChange(index)}
            >
                <Typography variant="headline">{tabLabel}</Typography>
                <div className={classes.badgeContainer}>
                    <div title={'Please fix required fields'} className={classes.badgeError} tab-error={tabKey} />
                    <div
                        title={'Please fix suggested fields'}
                        className={classes.badgeSuggested}
                        tab-suggested={tabKey}
                    />
                </div>
            </ButtonBase>
        );
    };
}

const styles = ({ palette, typography, breakpoints }) => ({
    diagRoot: {
        overflowY: 'auto'
    },
    root: {
        background: 'none',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        overflow: 'hidden',
        maxWidth: '95vw',
        maxHeight: '95vh'
    },
    rootFullScreen: {
        justifyContent: 'center',
        height: 'auto',
        maxHeight: 'unset'
    },
    paper: {
        borderRadius: 8,
        borderTopLeftRadius: 0,
        padding: 0,
        // overflowY: 'auto',
        // padding: '42px',
        // display: 'flex',
        background: palette.common.white,
        [breakpoints.down('xs')]: {
            borderRadius: '0 0 8px 8px'
        }
    },
    tabHeaders: {
        padding: 0,
        paddingRight: 12,
        [breakpoints.down('xs')]: {
            background: palette.common.white,
            padding: '12px 12px 0 12px',
            borderRadius: '8px 8px 0 0'
        }
    },
    tabButton: {
        flex: '0 0 auto',
        padding: 40,
        backgroundColor: palette.primary.main,
        borderBottom: `2px solid ${palette.primary.main}`,
        '& > h1': {
            color: palette.common.white,
            fontWeight: typography.fontWeightLight,
            [breakpoints.down('xs')]: {
                fontSize: '0.875rem'
            }
        },
        '&:first-child': {
            borderTopLeftRadius: 8
        },
        '&:last-child': {
            borderTopRightRadius: 8
        },
        [breakpoints.down(breakpoints.custom.dt)]: {
            padding: 20
        },
        [breakpoints.down('xs')]: {
            display: 'none',
            padding: 5,
            width: '100%',
            backgroundColor: palette.primary.main + '99',
            '&:first-child': {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
            },
            '&:last-child': {
                borderTopRightRadius: 0
            }
        }
    },
    tabButtonHighlighted: {
        backgroundColor: palette.common.white,
        [breakpoints.down('xs')]: {
            display: 'block',
            backgroundColor: palette.common.white
        },
        '& > h1': {
            color: palette.primary.main,
            fontWeight: typography.fontWeightMedium,
            [breakpoints.down('xs')]: {
                fontSize: '1.125rem',
                opacity: 1
            }
        }
    },
    badgeContainer: {
        display: 'flex',
        '& > *': {
            borderRadius: '50% 50%',
            alignContent: 'center',
            height: '20px',
            width: '20px',
            fontSize: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '3px',
            display: 'none' //flex
        }
    },
    badgeError: {
        color: '#FFFFFF',
        backgroundColor: palette.validation.error
    },
    badgeSuggested: {
        color: '#FFFFFF',
        backgroundColor: palette.validation.suggested
    },
    loading: {},
    modalMagick: {}
});

export default withStyles(styles)(TabbedModal);
