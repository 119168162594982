import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import Grid from '../../component/form/Grid';
import { withStyles } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table, { TableCell, TableRow } from '../../component/form/Table';
import CompletedIcon from '../../component/icon/CompletedIcon';
import CommentsIcon from '../../component/icon/CommentsIcon';
import LinkButton from '../../component/form/LinkButton';
import QuestionMarkCircleIcon from '../../component/icon/QuestionMarkCircleIcon';
import Inline, { inlineAlignment } from '../workQueue2/extras/Inline';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Hidden from '@material-ui/core/Hidden/Hidden';
import { getRequiredDocuments } from '../funeral/certification/CertificationConstants';
import moment from 'moment';
import { withRouter } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { GetDocumentForLocation } from './DocumentationConstants';
import { applyUrlParams } from '../../util/strings';
import { getOfficeFromKey } from '../../util/brands';
import { createForm } from '../../util/form';
import RadioGroup from '../../component/form/RadioGroup';
import PrimaryButton, { AltButton } from '../../component/form/PrimaryButton';
import TabDeclaration from './Tab_Declaration';
import EditIcon from '../../component/icon/EditIcon';
import EnvelopeIcon from '../../component/icon/EnvelopeIcon';
import DocumentEmailModal from './modals/DocumentEmailModal';
import { dateToString, niceTimeFromString } from '../../util/date';
import ArrangementIcon from '../../component/icon/ArrangementIcon';
import { TABS } from '../funeral/funeralConstants';
import DocumentHistoryModal from './modals/DocumentHistoryModal';
import { getServiceURLHostname } from '../../apollo';

class Documentation_Summary extends Component {
    constructor() {
        super();
        this.changeWidth = this.changeWidth.bind(this);
        this.state = {
            width: '100%',
            form: createForm(this, { ApplicantIsContact: true }),
            documentEmailEditModal: {
                open: false,
                documentEmailId: null
            },
            openSendEmailModal: false,
            openCorrespondenceTab: false,
            documentNotesModal: {
                open: false,
                documentName: null,
                documentNotes: null
            }
        };
    }

    changeWidth() {
        let width = Math.random() * 10;
        this.setState({ width: width });
    }

    renderFillableForm(name, dateSent, dateApproved, target, progress, notes, index) {
        return (
            <TableRow key={index}>
                {progress === 100 ? (
                    <TableCell>
                        <CompletedIcon className="icon" />
                    </TableCell>
                ) : progress === 0 ? (
                    <TableCell>
                        <QuestionMarkCircleIcon className="icon question" />
                    </TableCell>
                ) : (
                    <TableCell>
                        <CircularProgress className="icon" thickness={23} value={progress} variant="static" />
                    </TableCell>
                )}
                <TableCell>
                    <LinkButton
                        className="link"
                        text={name}
                        target={'blank'}
                        href={
                            target && target.startsWith('//') ? target : applyUrlParams(target, this.props.match.params)
                        }
                    />
                </TableCell>
                <TableCell>{dateSent ? moment(dateSent).format('D/MM/YYYY') : '-'}</TableCell>
                <TableCell>{dateApproved ? moment(dateApproved).format('D/MM/YYYY') : '-'}</TableCell>
                <TableCell>
                    {(notes && (
                        <IconButton
                            title={'Notes'}
                            onClick={() => {
                                this.setState({
                                    documentNotesModal: {
                                        open: true,
                                        documentName: name,
                                        documentNotes: notes
                                    }
                                });
                            }}
                        >
                            <CommentsIcon />
                        </IconButton>
                    )) ||
                        '-'}
                </TableCell>
            </TableRow>
        );
    }

    renderPlainDocument(document, requiredDocument, index) {
        if (document && document.ID > 0) {
            //got document record here, let's check it
            return (
                <TableRow key={index}>
                    <TableCell>
                        <CompletedIcon className="icon" />
                    </TableCell>
                    <TableCell>{requiredDocument.description}</TableCell>
                    <TableCell>{moment(document.Created).format('D/MM/YYYY')}</TableCell>
                </TableRow>
            );
        } else if (document && document.target) {
            //user facing form here
            return (
                <TableRow key={index}>
                    <TableCell>
                        <CompletedIcon className="icon" />
                    </TableCell>
                    <TableCell>
                        <LinkButton className="link" text={requiredDocument} target={'blank'} href={document.target} />
                    </TableCell>
                    <TableCell>{document.completed ? moment(document.completed).format('D/MM/YYYY') : '-'}</TableCell>
                </TableRow>
            );
        } else {
            //something else that is clearly not filled yet
            return (
                <TableRow key={index}>
                    <TableCell>
                        <QuestionMarkCircleIcon className="icon question" />
                    </TableCell>
                    <TableCell>{requiredDocument.description}</TableCell>
                    <TableCell>-</TableCell>
                </TableRow>
            );
        }
    }

    findForm(forms, type) {
        const mathcing_forms = [];
        for (let i = 0; i < forms.length; i++) {
            if (forms[i].Type === type) {
                mathcing_forms.push(forms[i]);
            }
        }
        if (mathcing_forms.length === 0) return false;
        let best_form = { Progress: -1, Signed: false };
        mathcing_forms.forEach(form => {
            if (!!form.Signed) best_form = form;
            if (best_form.Progress < form.Progress && !best_form.Signed) {
                best_form = form;
            }
        });
        return best_form;
    }

    renderArrangementSummary(brand) {
        const { form, classes } = this.props;
        if (!form) return null;
        const requiredDocuments = getRequiredDocuments(form);
        const fillableForms = [];
        const plainDocuments = [];
        const arrangementProgress = { Complete: 0, Count: 0, Percent: 0 };

        const ArrangementForms = form.getField('Arrangement.ArrangementForms') || [];

        for (let i = 0; i < requiredDocuments.length; i++) {
            const document = GetDocumentForLocation(requiredDocuments[i].fieldName, form);

            if (document === false) {
                const plainDocument = form.getField(requiredDocuments[i].fieldName);
                arrangementProgress.Complete += plainDocument && plainDocument.ID > 0 ? 1 : 0;
                arrangementProgress.Count++;
                plainDocuments.push(this.renderPlainDocument(plainDocument, requiredDocuments[i], i));
            } else {
                const arrangementForm = this.findForm(ArrangementForms, document.FormID);

                if (arrangementForm) {
                    fillableForms.push(
                        this.renderFillableForm(
                            requiredDocuments[i].description,
                            arrangementForm.Created,
                            arrangementForm.SignedOn,
                            document.path,
                            Math.round(parseFloat(arrangementForm.Progress) * 100),
                            arrangementForm.Notes,
                            i
                        )
                    );
                    arrangementProgress.Complete += parseFloat(arrangementForm.Progress);
                    arrangementProgress.Count++;
                } else {
                    fillableForms.push(
                        this.renderFillableForm(requiredDocuments[i].description, null, null, document.path, 0, '', i)
                    );
                    arrangementProgress.Count++;
                }
            }
        }
        if (form.getField('LatestBDMForm.ID')) {
            plainDocuments.push(
                this.renderPlainDocument(
                    {
                        target:
                            '//' +
                            getServiceURLHostname() +
                            '/funeral-registration-form?Type=funeral&ID=' +
                            form.getField('ID') +
                            '&Key=' +
                            form.getField('LegacyKey') +
                            '&FormID=' +
                            form.getField('LatestBDMForm.ID'),
                        completed: false
                    },
                    'Funeral Registration Form',
                    requiredDocuments.length + 2
                )
            );
        } else {
            fillableForms.push(
                this.renderFillableForm(
                    'Funeral Registration Form',
                    null,
                    null,
                    '//' +
                        getServiceURLHostname() +
                        '/funeral-registration-form?Type=funeral&ID=' +
                        form.getField('ID') +
                        '&Key=' +
                        form.getField('LegacyKey') +
                        '&FormID=latest',
                    0,
                    '',
                    requiredDocuments.length + 1
                )
            );
        }
        if (form.getField('ApprovedByClient')) {
            plainDocuments.push(
                this.renderPlainDocument(
                    {
                        target:
                            '//' +
                            getServiceURLHostname() +
                            '/arrangement-confirmation-page?Type=funeral&ID=' +
                            form.getField('ID') +
                            '&Key=' +
                            form.getField('LegacyKey'),
                        completed: form.getField('ApprovedOn')
                    },
                    'Arrangement Confirmation Page',
                    requiredDocuments.length + 2
                )
            );
        } else {
            fillableForms.push(
                this.renderFillableForm(
                    'Arrangement Confirmation Page',
                    null,
                    null,
                    '//' +
                        getServiceURLHostname() +
                        '/arrangement-confirmation-page?Type=funeral&ID=' +
                        form.getField('ID') +
                        '&Key=' +
                        form.getField('LegacyKey'),
                    0,
                    form.getField('RejectedOn') ? form.getField('RejectionNote') : '',
                    requiredDocuments.length + 2
                )
            );
        }

        arrangementProgress.Percent = Math.round((arrangementProgress.Complete * 100) / arrangementProgress.Count);

        return (
            <Grid item xs={12}>
                <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon className="icon" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="summary-accordion"
                    >
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <Inline center>
                                {arrangementProgress.Percent === 100 ? (
                                    <div className="progress-details">
                                        <CompletedIcon className="large-icon" />
                                        <p>Completed</p>
                                    </div>
                                ) : (
                                    <div className="progress-details">
                                        <div className={classes.outline}>
                                            <CircularProgress
                                                className="icon"
                                                thickness={23}
                                                value={arrangementProgress.Percent}
                                                variant="static"
                                            />
                                        </div>
                                        <p>{arrangementProgress.Percent + '% Complete'}</p>
                                    </div>
                                )}

                                <h3>Documentation</h3>
                            </Inline>
                        </Inline>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container>
                            <Grid item xs={12}>
                                <Table
                                    columns={['Status', 'Document Name', 'Date Sent', 'Date Approved', 'Notes']}
                                    className={`${classes.summaryTable} summary-table`}
                                >
                                    {fillableForms.length > 0 && fillableForms}
                                </Table>
                                {fillableForms.length === 0 && (
                                    <div className="progress-details">
                                        <p>Currently there are no relevant online forms available.</p>
                                    </div>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <h4>Documentation arranged by {brand ? brand.label : 'Funeral Director'}:</h4>
                                <Table
                                    columns={['Status', 'Document Name', 'Date Completed']}
                                    className={`${classes.summaryTable} summary-table`}
                                >
                                    {plainDocuments}
                                </Table>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        );
    }

    renderFuneralSummary() {
        const { form, classes } = this.props;
        const AdminCheckCategories = TABS.map(x => {
            return { title: x.title || x.label, key: x.key };
        });

        const funeralList = [];
        const funeralProgress = { Total: { Complete: 0, Count: 0, Percent: 0 }, Keys: [] };
        const funeralAdminChecks = form.getField('AdminChecks') || [];

        if (funeralAdminChecks.length > 0) {
            for (let i = 0; i < funeralAdminChecks.length; i++) {
                const check = funeralAdminChecks[i];
                const categoryKey = check.TabURLSegment;
                if (
                    categoryKey === 'mortuary' ||
                    categoryKey === 'bdms' ||
                    categoryKey === 'summary' ||
                    categoryKey === 'billing' ||
                    check.Active === false
                )
                    continue;

                if (funeralProgress[categoryKey] === undefined) {
                    funeralProgress[categoryKey] = { Complete: 0, Count: 0, Percent: 0 };
                    funeralProgress.Keys.push(categoryKey);
                }

                funeralProgress[categoryKey].Count++;
                funeralProgress.Total.Count++;
                if (check._join.FuneralAdminCheck.Checked !== null) {
                    funeralProgress[categoryKey].Complete++;
                    funeralProgress.Total.Complete++;
                }
            }

            for (let i = 0; i < funeralProgress.Keys.length; i++) {
                const categoryKey = funeralProgress.Keys[i];
                const checkCategory = funeralProgress[categoryKey];
                const categoryTitle = AdminCheckCategories.find(e => e.key === categoryKey);
                checkCategory.Percent = Math.round((checkCategory.Complete * 100) / checkCategory.Count);

                funeralList.push(
                    <TableRow>
                        {checkCategory.Percent === 100 ? (
                            <TableCell>
                                <CompletedIcon className="large-icon" />
                            </TableCell>
                        ) : checkCategory.Percent === 0 ? (
                            <TableCell>
                                <QuestionMarkCircleIcon className="icon question" />
                            </TableCell>
                        ) : (
                            <TableCell>
                                <div className={classes.outlineTable}>
                                    <CircularProgress
                                        className="icon"
                                        thickness={23}
                                        value={checkCategory.Percent}
                                        variant="static"
                                    />
                                </div>
                            </TableCell>
                        )}
                        <TableCell>
                            <LinkButton
                                className="link"
                                text={categoryTitle && categoryTitle.title}
                                href={applyUrlParams('/funeral/:key/:id/' + categoryKey, this.props.match.params)}
                            />
                        </TableCell>
                        <TableCell>
                            <p>
                                {checkCategory.Complete}/{checkCategory.Count} items complete
                            </p>
                        </TableCell>
                    </TableRow>
                );
            }

            funeralProgress.Total.Percent = Math.round(
                (funeralProgress.Total.Complete * 100) / funeralProgress.Total.Count
            );
        }

        return (
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon className="icon" />}
                        aria-controls="panel1b-content"
                        id="panel1b-header"
                        className="summary-accordion"
                    >
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <Inline center>
                                {funeralProgress.Total.Percent === 100 ? (
                                    <div className="progress-details">
                                        <CompletedIcon className="large-icon" />
                                        <p>Completed</p>
                                    </div>
                                ) : (
                                    <div className="progress-details">
                                        <div className={classes.outline}>
                                            <CircularProgress
                                                className="icon"
                                                thickness={23}
                                                value={funeralProgress.Total.Percent}
                                                variant="static"
                                            />
                                        </div>
                                        <p>{funeralProgress.Total.Percent + '% Complete'}</p>
                                    </div>
                                )}
                                <h3>Funeral Details</h3>
                            </Inline>
                        </Inline>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container>
                            <Grid item xs={12}>
                                <Table className={`${classes.summaryTable} summary-table`}>{funeralList}</Table>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        );
    }

    renderAuthorisedContact() {
        const { form } = this.state;
        const { classes } = this.props;
        return (
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon className="icon" />}
                        aria-controls="panel1b-content"
                        id="panel1b-header"
                        className="summary-accordion"
                    >
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <Inline center>
                                <div className="progress-details">
                                    <ArrangementIcon className={`${classes.branded} large-icon`} />
                                </div>
                                <h3>Authorised Contact</h3>
                            </Inline>
                        </Inline>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container>
                            <Grid item xs={12}>
                                <Inline>
                                    I, the funeral applicant, will remain the person of contact
                                    <RadioGroup
                                        className="doc-radio"
                                        options={[
                                            {
                                                label: 'Yes',
                                                value: 'true'
                                            },
                                            {
                                                label: 'No',
                                                value: ''
                                            }
                                        ]}
                                        name="ApplicantIsContact"
                                        form={form}
                                    />
                                </Inline>
                                {this.renderButton()}
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        );
    }

    renderButton() {
        const { form } = this.state;
        const { match } = this.props;
        if (form.getField('ApplicantIsContact'))
            return (
                <AltButton href={applyUrlParams('/documentation/:key/:id/letter-of-authority', match.params)}>
                    Complete Letter of Authority
                </AltButton>
            );
    }

    renderDeclarationSummary() {
        const { form } = this.props;
        return <TabDeclaration onSendEmailOptionSelect={bool => this.onSendEmailOptionSelect(bool)} form={form} />;
    }

    onSendEmailOptionSelect(bool) {
        this.setState({ openCorrespondenceTab: bool });
        this.forceUpdate();
    }

    renderCorrenspondenceSummary() {
        const { openCorrespondenceTab } = this.state;
        const { form, classes } = this.props;
        const documentEmails = form.getField('Arrangement.DocumentEmails') || [];
        return (
            <Grid item xs={12}>
                <ExpansionPanel
                    expanded={openCorrespondenceTab}
                    onChange={e => this.setState({ openCorrespondenceTab: !openCorrespondenceTab })}
                    disabled={!form.getField('Arrangement.AgreeTC') || !form.getField('Arrangement.OptToSendEmail')}
                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon className="icon" />}
                        aria-controls="panel1b-content"
                        id="panel1b-header"
                        className="summary-accordion"
                    >
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <Inline center>
                                <div className="progress-details">
                                    <EnvelopeIcon className={`${classes.branded} large-icon`} />
                                </div>
                                <h3>Correspondence</h3>
                            </Inline>
                        </Inline>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <Grid container>
                            {documentEmails.length > 0 && (
                                <Fragment>
                                    <Grid item xs={12}>
                                        <h4>Emailed Documents</h4>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Table columns={['Date', 'Time', 'Documents Sent', 'Edit']}>
                                            {documentEmails.map(documentEmail => (
                                                <TableRow pad key={'email-' + documentEmail.ID}>
                                                    <TableCell dataLabel="Date">
                                                        {dateToString(documentEmail.EmailSent) || ' - '}
                                                    </TableCell>
                                                    <TableCell dataLabel="Time">
                                                        {niceTimeFromString(documentEmail.EmailSent) || ' - '}
                                                    </TableCell>
                                                    <TableCell dataLabel="Sent to">
                                                        {getPopupDocumentName(form)}
                                                    </TableCell>
                                                    <TableCell dataLabel="Approved">
                                                        <IconButton
                                                            title={'Edit Email'}
                                                            onClick={() => {
                                                                this.handleShowEditProofModal(documentEmail.ID);
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </Table>
                                    </Grid>
                                </Fragment>
                            )}

                            <Grid item xs={12} style={{ paddingTop: 20 }}>
                                <PrimaryButton onClick={() => this.handleShowEditProofModal(null)}>
                                    <EnvelopeIcon />
                                    Send Documents...
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        );
    }

    handleShowEditProofModal(id) {
        this.setState({
            documentEmailEditModal: {
                open: true,
                documentEmailId: id
            }
        });
    }

    render() {
        const { form, match } = this.props;
        const { documentEmailEditModal, documentNotesModal } = this.state;
        const { key } = match.params;
        const logo = key && getOfficeFromKey(key);
        const isCremation = form.getField('Disposal.CrematedOrBuried') === 'Cremated';
        const locationID = form.getField('Disposal.Location.ID');
        const needLOA = isCremation && locationID === 6463;
        return (
            <Grid container className="summary-paper">
                <Grid item xs={12} md={2}>
                    <Inline center>
                        {logo && <img src={logo.logo} alt={logo.label} className="logo" />}
                        <Hidden mdUp>
                            <h2>Arrangement Summary</h2>
                        </Hidden>
                    </Inline>
                </Grid>

                <Grid item xs={12} md={10}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Hidden smDown>
                                <h2>Arrangement Summary</h2>
                            </Hidden>
                        </Grid>

                        {this.renderArrangementSummary(logo)}
                        {this.renderFuneralSummary()}
                        {needLOA && this.renderAuthorisedContact()}
                        {this.renderDeclarationSummary()}
                        {this.renderCorrenspondenceSummary()}
                    </Grid>
                </Grid>

                <DocumentEmailModal
                    open={documentEmailEditModal.open}
                    emailId={documentEmailEditModal.documentEmailId}
                    onClose={() =>
                        this.setState({
                            documentEmailEditModal: {
                                open: false,
                                documentEmailId: null
                            }
                        })
                    }
                    parentForm={form}
                    arrangementID={form.getField('Arrangement.ID')}
                />

                <DocumentHistoryModal
                    open={documentNotesModal.open}
                    notes={documentNotesModal.documentNotes}
                    name={documentNotesModal.documentName}
                    onClose={() =>
                        this.setState({
                            documentNotesModal: {
                                open: false,
                                documentNotes: '',
                                documentName: ''
                            }
                        })
                    }
                />
            </Grid>
        );
    }
}

const styles = ({ spacing, typography, palette, breakpoints, transitions, funeralHome }) => ({
    summaryTable: {
        '& th': {
            color: palette.primary.main + '!important'
        }
    },

    branded: {
        color: palette.primary.main
    },
    outline: {
        border: '2px solid' + palette.primary.main,
        borderRadius: '99px',
        height: '1.95em',
        width: '1.95em',
        margin: '0 auto'
    },
    outlineTable: {
        border: '2px solid' + palette.primary.main,
        borderRadius: '99px',
        height: '1.62rem',
        width: '1.62rem',
        margin: '0 auto'
    }
});

export const getPopupDocumentName = form => {
    const disposalType = form.getField('Disposal.CrematedOrBuried');
    if (disposalType === 'Cremated') {
        return 'Statutory Declaration Form (A-Form), Crematorium Booking Form';
    } else {
        return 'Burial Booking Form';
    }
};

export default compose(withRouter, withStyles(styles))(Documentation_Summary);
