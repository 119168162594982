import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TabbedModal from '../../../../component/form/TabbedModal';
import ValuablesModalListTab from './ValuablesModalListTab';
import ValuablesModalDetailsTab from './ValuablesModalDetailsTab';
import DataForm from '../../../../component/form/DataForm';
import PrimaryButton, { OutlineButton } from '../../../../component/form/PrimaryButton';
import CloseIcon from '../../../../component/icon/CloseIcon';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Query from 'react-apollo/Query';
import gql from 'graphql-tag';
import BackIcon from '../../../../component/icon/BackIcon';
import Spinner from '../../../../component/Spinner';
import Inline from '../../../workQueue2/extras/Inline';
import cloneDeep from 'lodash.clonedeep';

const TABS = [
    { label: 'View All Valuables', key: 'ViewValuables' },
    { label: 'Valuable Details', key: 'EditValuable' }
];

class ValuablesModal extends Component {
    state = {
        tabIndex: 0,
        currentRecordId: 0
    };

    form = {};

    static getDerivedStateFromProps({ open, addNew }, state) {
        const newState = { open };
        if (state.open !== open && open) {
            // when the open state changes to true we reset the state
            newState.tabIndex = 0;
            newState.currentRecordId = 0;
        }
        if (addNew && !state.open) {
            newState.tabIndex = 1;
            newState.currentRecordId = 0;
        }
        if (!newState.tabIndex) {
            const badgeErr = document.querySelector('[tab-error=EditValuable]');
            const badgeSug = document.querySelector('[tab-suggested=EditValuable]');
            if (badgeErr) badgeErr.style.display = 'none';
            if (badgeSug) badgeSug.style.display = 'none';
        }
        return newState;
    }

    onChangeTab = tabIndex => {
        this.setState({ tabIndex: tabIndex, currentRecordId: 0 });
    };

    nextTab = () => {
        this.setState({ tabIndex: this.state.tabIndex + 1 });
    };

    prevTab = () => {
        this.setState({ tabIndex: this.state.tabIndex - 1, currentRecordId: 0 });
    };

    byeClose = form => {
        this.props.onClose();
    };

    onEditRecord = recordId => {
        if (recordId) {
            this.setState({
                currentRecordId: recordId,
                tabIndex: 1
            });
        }
    };

    onDeleteRecord = recordId => {
        const valuables = this.form.getField('valuableItems');
        if (recordId) {
            delete valuables['id' + recordId];
            this.form.setField({ valuableItems: valuables });
        }
    };

    onCreateRecord = (recordId, refetch) => {
        if (recordId && recordId > 0) {
            const me = this;
            const { form } = this.props;
            refetch().then(result => {
                const { data } = result;
                if (data) {
                    const newValuables = data.readFuneralValuableItems.edges.map(e => e.node);
                    form.setField({ ValuableItems: newValuables }, false);
                    const { tabIndex } = me.state;
                    if (tabIndex)
                        me.setState({
                            currentRecordId: recordId
                        });
                }
            });
        }
    };

    render() {
        const { classes, form, addNew, disabled, ...other } = this.props;
        const { tabIndex, currentRecordId } = this.state;
        const myTabs = TABS;
        if (disabled) {
            delete myTabs[1];
        } else {
            myTabs[1].label = currentRecordId > 0 ? 'Edit Valuable' : 'Add Valuable';
        }

        return (
            <TabbedModal
                disabled={disabled}
                tabs={myTabs}
                tabIndex={tabIndex}
                className={classes.root}
                onChange={this.onChangeTab}
                {...other}
            >
                <Query query={valuablesQuery} variables={{ funeralid: form.getField('ID') }}>
                    {results => this.renderContent(results)}
                </Query>
            </TabbedModal>
        );
    }

    renderContent = ({ error, loading, data, refetch }) => {
        if (error) {
            console.error(error);
            return <div>Error: Can't read data!</div>;
        } else if (loading) {
            return (
                <div style={{ padding: 42 }}>
                    <Spinner /> Loading data, please wait...
                </div>
            );
        }

        const { classes, disabled } = this.props;
        const { tabIndex } = this.state;
        const mutation = this.state.currentRecordId > 0 ? valuableUpdate : valuableCreate;
        const funeralId = this.props.form.getField('ID');
        const formData = { valuableItems: {} };

        if (
            data &&
            data.readFuneralValuableItems &&
            data.readFuneralValuableItems.edges &&
            data.readFuneralValuableItems.edges.length
        ) {
            data.readFuneralValuableItems.edges.forEach(i => {
                const { node } = i;
                formData.valuableItems['id' + node.ID] = { ...node };
                formData.valuableItems['id' + node.ID].Changes = node.Changes.edges.map(e => e.node);
            });
        }

        return (
            <DataForm
                id={this.state.currentRecordId}
                loading={loading}
                error={error}
                data={formData}
                mutation={valuablesUpdate}
                variables={this.cleanupData}
                customStateModifier={this.savedData}
                buttonStyles={classes.saveButton}
            >
                {(form, save) => {
                    this.form = form;
                    return (
                        <Fragment>
                            {tabIndex === 0 && (
                                <ValuablesModalListTab
                                    form={form}
                                    funeralId={funeralId}
                                    onEditRecord={this.onEditRecord}
                                    onDeleteRecord={this.onDeleteRecord}
                                    disabled={disabled}
                                />
                            )}
                            {tabIndex === 1 && (
                                <ValuablesModalDetailsTab
                                    form={form}
                                    mutation={mutation}
                                    funeralId={funeralId}
                                    recordId={this.state.currentRecordId}
                                    onCreated={e => this.onCreateRecord(e, refetch)}
                                />
                            )}
                            <DialogActions style={{ padding: 40, paddingTop: 10, margin: 0 }}>
                                <div className={classes.actions}>{this.getActions(form, save)}</div>
                            </DialogActions>
                        </Fragment>
                    );
                }}
            </DataForm>
        );
    };

    savedData = (form, data) => {
        const { ValuableItems } = data;
        const newItems = ValuableItems.filter(e => !!e) || [];

        // silently update parent form
        this.props.form.setField({ ValuableItems: newItems }, false);

        // update modal form. note its data is in weird format, so couldn't use form.onSaved
        const formData = { valuableItems: {} };
        newItems.forEach(node => {
            formData.valuableItems['id' + node.ID] = { ...node };
        });
        let updatedState = { isDirty: false };
        Object.assign(form.original, form.original, formData);
        updatedState.fields = cloneDeep(form.original);
        form.setState(updatedState);
    };

    cleanupData = e => {
        if (!e || !e.valuableItems) {
            return false;
        }
        const changeList = Object.values(e.valuableItems);
        const newList = Object.values(this.form.getField('valuableItems') || {}).map(ex => {
            const newItem = { item: { ID: ex.ID } };
            const updated = changeList.find(ne => ne.ID === ex.ID);
            if (updated) {
                newItem.item = updated;
                delete newItem.item.Funeral;
                delete newItem.item.Changes;
                delete newItem.item.__typename;
            }
            return newItem.item;
        });

        const data = { ValuableItems: newList };
        data.ID = this.props.form.getField('ID');
        return { ...data };
    };

    getActions(form, save) {
        const { loading } = form;
        const { classes, disabled } = this.props;
        const { tabIndex } = this.state;

        return (
            <Fragment>
                {(tabIndex === 1 && (
                    <OutlineButton onClick={() => this.prevTab()} color="primary" title={'View All'}>
                        <BackIcon />
                        <span className={classes.svgLabel}>View All</span>
                    </OutlineButton>
                )) || (
                    <Inline nowrap>
                        <OutlineButton
                            title={'Cancel'}
                            onClick={
                                loading
                                    ? undefined
                                    : () => {
                                          this.byeClose(form);
                                      }
                            }
                            color="primary"
                            disabled={loading}
                        >
                            <CloseIcon />
                            <span className={classes.svgLabel}>Close</span>
                        </OutlineButton>
                        <PrimaryButton title={'Add Valuable'} disabled={disabled} onClick={e => this.onEditRecord('0')}>
                            +<span className={classes.svgLabel}>Add Valuable</span>
                        </PrimaryButton>
                    </Inline>
                )}
                {tabIndex === 0 && <span className={classes.listForm}>{save}</span>}
            </Fragment>
        );
    }
}

const valuableCreate = gql`
    mutation CreateFuneralValuableItem($input: CreateFuneralValuableItemInput!) {
        createFuneralValuableItem(input: $input) {
            ID
            Created
            Description
            ToBePlaced
            CurrentLocation
            CurrentLocationDetail
            Quantity
            ToBeReturned
            WhereToBeReturned
            Signed
            Note
            Funeral {
                ID
            }
        }
    }
`;

const valuableUpdate = gql`
    mutation UpdateFuneralValuableItem($input: UpdateFuneralValuableItemInput!) {
        updateFuneralValuableItem(input: $input) {
            ID
            Created
            Description
            ToBePlaced
            CurrentLocation
            CurrentLocationDetail
            Quantity
            ToBeReturned
            WhereToBeReturned
            Signed
            Note
            Funeral {
                ID
            }
            Changes {
                edges {
                    node {
                        ID
                        LocationDetail
                        Created
                        Status
                        Location
                        UserName
                    }
                }
            }
        }
    }
`;

const valuablesQuery = gql`
    query($limit: Int, $funeralid: ID) {
        readFuneralValuableItems(limit: $limit, funeralid: $funeralid) {
            edges {
                node {
                    ID
                    Created
                    Description
                    ToBePlaced
                    CurrentLocation
                    CurrentLocationDetail
                    Quantity
                    ToBeReturned
                    WhereToBeReturned
                    Signed
                    Note
                    Funeral {
                        ID
                    }
                    Changes {
                        edges {
                            node {
                                ID
                                LocationDetail
                                Created
                                Status
                                Location
                                UserName
                            }
                        }
                    }
                }
            }
        }
    }
`;
const valuablesUpdate = gql`
    mutation UpdateFuneralValuables($input: UpdateFuneralInput!) {
        updateFuneral(input: $input) {
            ID
            ValuableItems {
                ID
                Created
                Description
                ToBePlaced
                CurrentLocation
                CurrentLocationDetail
                Quantity
                ToBeReturned
                WhereToBeReturned
                Signed
                Note
                Funeral {
                    ID
                }
                Changes {
                    edges {
                        node {
                            ID
                            LocationDetail
                            Created
                            Status
                            Location
                            UserName
                        }
                    }
                }
            }
        }
    }
`;

const styles = ({ breakpoints }) => ({
    root: {
        display: 'flex',
        minHeight: '33vh'
    },
    actions: {
        margin: 0,
        width: '100%',
        textAlign: 'right',
        boxShadow: '-10px -25px 25px 0px #ffffff',
        zIndex: 1,
        '& button': {
            float: 'left',
            [breakpoints.down('xs')]: {
                padding: 8,
                minWidth: 38,
                minHeight: 38
            }
        }
    },
    svgLabel: {
        marginLeft: 6,
        [breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    saveButton: {
        position: 'static'
    },
    listForm: {
        '& button': {
            position: 'absolute',
            right: 40,
            bottom: 40
        }
    }
});

export default withStyles(styles)(ValuablesModal);
