import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TabbedModal from '../../../../component/form/TabbedModal';
import BillingDetailsTab, { convertToCustomer } from './BillingDetailsTab';
import InvoiceDetailsTab from './InvoiceDetailsTab';
import PaymentHistoryTab from './PaymentHistoryTab';
import { withSnackbarMessage } from '../../../../context/SnackbarMessage';
import { compose } from 'react-apollo/index';
import {
    authoriseToXeroFunc,
    createInvoiceFunc,
    sendInvoiceXeroFunc,
    submitToXeroFunc,
    updateInvoiceFunc
} from './GetSaveInvoice';
import cloneDeep from 'lodash.clonedeep';
import InvoicePDFTab from './InvoicePDFTab';
import { isRelatedObjectUndefined } from '../../../../util/graphql';
import { createForm } from '../../../../util/form';
import { ProductConfig, QueryKeys } from '../../../../util/products';
import { isCategory } from '../../../mortuary/Coffin/CoffinListConstants';
import { isNullOrUndefined } from '../../../../util/objects';
import moment from 'moment';
import { PO_DOCTOR_LIST, PO_SUPPLIER_LIST } from '../../funeralConstants';
import { WhiteLoader } from '../../../../component/WhiteLoader';

const EDITABLE_TABS = [{ label: 'Billing' }, { label: 'Line Items' }, { label: 'Preview Invoice' }];
const READONLY_TABS = [{ label: 'View Invoice' }, { label: 'Payments' }];
const EXPORTED_XERO_TABS = [{ label: 'View Invoice' }, { label: 'View PDF' }, { label: 'Payments' }];

class InvoiceModal extends Component {
    state = {
        tabIndex: 0,
        invoiceIndex: null,
        loading: false,
        form: null
    };

    static getDerivedStateFromProps({ open, invoiceIndex, defaultBillTo, form }, state) {
        const invoice = form.getField(`Invoices[${invoiceIndex}]`);
        const legacyKey = invoice && invoice.LegacyKey;
        const xeroID = invoice && invoice.XeroID;
        const newState = { open };

        if (state.open !== open && open) {
            newState.tabIndex = 0;
            newState.cart = [];

            if (!isNullOrUndefined(invoice)) {
                let clonedInvoice = cloneDeep(invoice);
                if (isRelatedObjectUndefined(clonedInvoice)) clonedInvoice.Customer = convertToCustomer(defaultBillTo);
                //customer needs a 'billing source'.
                if (!clonedInvoice.Customer.BillingSource)
                    clonedInvoice.Customer.BillingSource = !!clonedInvoice.Customer.RelationToDeceased
                        ? 'FamilyMember'
                        : 'Other';
                const oldContext = state.form !== null ? state.form.context : null;
                newState.form = createForm(oldContext, clonedInvoice);
                newState.invoiceIndex = invoiceIndex;
            } else {
                newState.form = null;
                newState.invoiceIndex = null;
            }

            newState.defaultBillTo = defaultBillTo;
            newState.canEdit = !legacyKey && !xeroID;
            newState.hasExportedToXero = !!xeroID;
            newState.existing = invoiceIndex !== null;
        }
        return newState;
    }

    componentDidUpdate(_, oldState) {
        const { loading, open, invoiceIndex, form } = this.state;
        if (open && invoiceIndex === null && !loading) {
            //no invoice! create one
            this.createInvoice();
            return;
        }

        if (oldState.form === null && form !== null) form.context = this;
    }

    render() {
        const { classes, onClose, quotes } = this.props;
        const {
            tabIndex,
            loading,
            invoiceIndex,
            defaultBillTo,
            canEdit,
            existing,
            hasExportedToXero,
            form
        } = this.state;
        const invoice = this.props.form.getField(`Invoices[${invoiceIndex}]`);
        const label = invoice && invoice.IsPurchase ? 'Bill' : 'Invoice';

        if (isNullOrUndefined(form)) return null;

        let tab;
        let tab_names;

        if (canEdit && !hasExportedToXero) {
            tab_names = EDITABLE_TABS.map(obj => obj.label.replace(/Invoice/, label));

            if (tabIndex === 0) {
                tab = (
                    <BillingDetailsTab
                        stepNumber="One"
                        onSubmit={() => this.onSubmit()}
                        nextTab={this.nextTab}
                        loading={loading}
                        onCancel={onClose}
                        invoiceIndex={invoiceIndex}
                        defaultBillTo={defaultBillTo}
                        canEdit={canEdit}
                        existing={existing}
                        form={form}
                        parentForm={this.props.form}
                    />
                );
            } else if (tabIndex === 1) {
                tab = (
                    <InvoiceDetailsTab
                        stepNumber="Two"
                        form={form}
                        onSubmit={() => this.onSubmit()}
                        // onSubmitToXero={() => this.onSubmitToXero()}
                        previousTab={this.previousTab}
                        nextTab={this.nextTab}
                        loading={loading}
                        onCancel={onClose}
                        invoice={form.state}
                        invoiceIndex={invoiceIndex}
                        defaultBillTo={defaultBillTo}
                        prefill={{
                            quotes: getQuotesForLineItems(quotes),
                            purchaseOrder: getPurchaseOrderLineItems(this.props.form)
                        }}
                        canEdit={canEdit}
                        canSubmit={false}
                        existing={existing}
                        hasExportedToXero={hasExportedToXero}
                    />
                );
            } else if (tabIndex === 2) {
                tab = (
                    <InvoiceDetailsTab
                        stepNumber="Three"
                        form={form}
                        onSubmit={() => this.onSubmit()}
                        onSubmitToXero={() => this.onSubmitToXero()}
                        previousTab={this.previousTab}
                        nextTab={this.nextTab}
                        loading={loading}
                        onCancel={onClose}
                        invoice={form.state}
                        invoiceIndex={invoiceIndex}
                        defaultBillTo={defaultBillTo}
                        canEdit={false}
                        canSubmit={true}
                        existing={existing}
                        hasExportedToXero={hasExportedToXero}
                    />
                );
            }
        } else if (!canEdit && hasExportedToXero) {
            tab_names = EXPORTED_XERO_TABS.map(obj => obj.label.replace(/Invoice/, label));

            if (tabIndex === 0) {
                tab = (
                    <InvoiceDetailsTab
                        form={form}
                        onSubmit={() => this.onSubmit()}
                        nextTab={this.nextTab}
                        loading={loading}
                        onCancel={onClose}
                        invoice={form.state}
                        invoiceIndex={invoiceIndex}
                        defaultBillTo={defaultBillTo}
                        canEdit={canEdit}
                        existing={existing}
                        hasExportedToXero={hasExportedToXero}
                        onAuthoriseToXero={() => this.onAuthoriseToXero()}
                        onEdit={this.onEdit}
                    />
                );
            } else if (tabIndex === 1) {
                tab = (
                    <InvoicePDFTab
                        form={form}
                        onSubmit={() => this.onSubmit()}
                        nextTab={this.nextTab}
                        previousTab={this.previousTab}
                        loading={loading}
                        onCancel={onClose}
                        invoice={form.state}
                        invoiceIndex={invoiceIndex}
                        purchaseOrders={getPurchaseOrderList(this.props.form)}
                        canEdit={canEdit}
                        existing={existing}
                        onSendEmail={this.sendInvoiceXero} // authorise also sends
                    />
                );
            } else if (tabIndex === 2) {
                tab = (
                    <PaymentHistoryTab
                        form={form}
                        onSubmit={() => this.onSubmit()}
                        nextTab={this.nextTab}
                        previousTab={this.previousTab}
                        loading={loading}
                        onCancel={onClose}
                        invoice={form.state}
                        invoiceIndex={invoiceIndex}
                        defaultBillTo={defaultBillTo}
                        canEdit={canEdit}
                        existing={existing}
                    />
                );
            }
        } else {
            tab_names = READONLY_TABS.map(obj => obj.label.replace(/Invoice/, label));

            if (tabIndex === 0) {
                tab = (
                    <InvoiceDetailsTab
                        form={form}
                        onSubmit={() => this.onSubmit()}
                        nextTab={this.nextTab}
                        loading={loading}
                        onCancel={onClose}
                        invoice={form.state}
                        invoiceIndex={invoiceIndex}
                        defaultBillTo={defaultBillTo}
                        canEdit={canEdit}
                        existing={existing}
                        hasExportedToXero={hasExportedToXero}
                    />
                );
            } else if (tabIndex === 1) {
                tab = (
                    <PaymentHistoryTab
                        form={form}
                        onSubmit={() => this.onSubmit()}
                        nextTab={this.nextTab}
                        previousTab={this.previousTab}
                        loading={loading}
                        onCancel={onClose}
                        invoice={form.state}
                        invoiceIndex={invoiceIndex}
                        defaultBillTo={defaultBillTo}
                        canEdit={canEdit}
                        existing={existing}
                    />
                );
            }
        }

        return (
            <TabbedModal
                className={classes.modalMagick}
                tabs={tab_names}
                tabIndex={tabIndex}
                onChange={this.onChangeTab}
                {...this.props}
            >
                {tab}
                {!!loading && <WhiteLoader />}
            </TabbedModal>
        );
    }

    onChangeTab = tabIndex => {
        this.setState({ tabIndex });
    };

    nextTab = () => {
        this.setState({ tabIndex: this.state.tabIndex + 1 });
    };

    previousTab = () => {
        this.setState({ tabIndex: this.state.tabIndex - 1 });
    };

    onEdit = () => {
        this.setState({
            hasExportedToXero: false,
            canEdit: true,
            tabIndex: 1
        });
    };

    onSubmit() {
        this.updateInvoice();
    }

    onSubmitToXero() {
        const me = this;
        this.submitToXero().then(() => {
            me.setState({ tabIndex: 1 });
        });
    }

    onAuthoriseToXero() {
        const me = this;
        this.authoriseToXero().then(() => {
            me.setState({ tabIndex: 1 });
        });
    }

    createInvoice() {
        const { defaultBillTo } = this.props;
        const parentForm = this.props.form;
        if (parentForm.loading !== false) return;

        this.setState({ loading: true });
        const that = this;

        const dos = moment(parentForm.getField('DateOfService'));
        const due = (!!dos && dos.isValid() && dos) || moment();

        //add form details
        const form = createForm(that, {
            Funeral: { ID: parentForm.getField('ID') },
            InvoiceItems: [],
            SalesRegisterTransactions: [],
            InvoiceDate: moment().format('YYYY-MM-DD'),
            InvoiceDueDate: due.add(21, 'days').format('YYYY-MM-DD')
        });
        form.setField({ Customer: convertToCustomer(defaultBillTo) });

        const Invoices = parentForm.getField('Invoices') || [];
        const invoiceIndex = Invoices.length;
        that.setState({ loading: false, invoiceIndex, form });
        return null;
    }

    updateInvoice() {
        const { invoiceIndex } = this.state;
        let invoice = this.state.form.state;
        const parentForm = this.props.form;

        this.setState({ loading: true });
        const that = this;
        const original = parentForm.getField(`Invoices[${invoiceIndex}]`);

        if (!invoice.ID || invoice.ID === '0')
            return createInvoiceFunc(invoice).then(
                ({ data }) => {
                    const Invoices = parentForm.getField('Invoices') || [];
                    const createdInvoice = cloneDeep(data.createInvoice);
                    Invoices.push(createdInvoice);
                    parentForm.setField({ Invoices }, false);

                    const invoiceIndex = Invoices.length - 1;
                    that.setState({ loading: false, invoiceIndex, form: createForm(that, createdInvoice) });
                    this.props.setSnackbarMessage('Success, your invoice was created.', true);
                },
                e => that.onGqlError('Failed to create invoice.', e)
            );

        if (!invoice.XeroID) {
            return updateInvoiceFunc(invoice, original).then(
                ({ data }) => {
                    const Invoices = parentForm.getField('Invoices') || [];
                    const updatedInvoice = cloneDeep(data.updateInvoice);
                    Object.assign(Invoices[invoiceIndex], updatedInvoice);
                    parentForm.setField({ Invoices }, false);
                    that.setState({ loading: false, form: createForm(that, updatedInvoice) });
                    this.props.setSnackbarMessage('Success, your invoice was updated.', true);
                },
                e => that.onGqlError('Failed to update invoice.', e)
            );
        } else {
            // update case after exported to xero
            return updateInvoiceFunc(invoice, original).then(
                result => {
                    return submitToXeroFunc(invoice).then(
                        ({ data }) => {
                            const Invoices = parentForm.getField('Invoices') || [];
                            const updatedInvoice = cloneDeep(data.submitInvoiceToXero);
                            Object.assign(Invoices[invoiceIndex], updatedInvoice);
                            parentForm.setField({ Invoices }, false);
                            that.setState({
                                loading: false,
                                invoice: updatedInvoice,
                                hasExportedToXero: true,
                                canEdit: false
                            });
                            this.props.setSnackbarMessage('Success, your invoice was updated.', true);
                        },
                        e => that.onGqlError('Failed to submit invoice to xero.', e)
                    );
                },
                e => that.onGqlError('Failed to update invoice.', e)
            );
        }
    }

    authoriseToXero = () => {
        const parentForm = this.props.form;
        const { invoiceIndex, form } = this.state;

        this.setState({ loading: true });
        const that = this;

        return authoriseToXeroFunc(form.state).then(
            ({ data }) => {
                const Invoices = parentForm.getField('Invoices') || [];
                const updatedInvoice = cloneDeep(data.authoriseInvoiceToXero);
                Object.assign(Invoices[invoiceIndex], updatedInvoice);
                parentForm.setField({ Invoices }, false);
                that.setState({
                    loading: false,
                    form: createForm(that, cloneDeep(updatedInvoice)),
                    hasExportedToXero: true,
                    canEdit: false
                });
                this.props.setSnackbarMessage('Success, your invoice was updated.', true);
            },
            e => that.onGqlError('Failed to authorise invoice.', e)
        );
    };

    sendInvoiceXero = () => {
        const parentForm = this.props.form;
        const { invoiceIndex, form } = this.state;

        this.setState({ loading: true });
        const that = this;

        return sendInvoiceXeroFunc(form.state).then(
            ({ data }) => {
                const Invoices = parentForm.getField('Invoices') || [];
                const updatedInvoice = cloneDeep(data.sendInvoiceXero);
                Object.assign(Invoices[invoiceIndex], updatedInvoice);
                parentForm.setField({ Invoices }, false);
                that.setState({
                    loading: false,
                    form: createForm(that, cloneDeep(updatedInvoice)),
                    hasExportedToXero: true,
                    canEdit: false
                });
                this.props.setSnackbarMessage('Success, your invoice was sent.', true);
            },
            e => that.onGqlError('Failed to send invoice.', e)
        );
    };

    submitToXero = () => {
        const parentForm = this.props.form;
        const { invoiceIndex, form } = this.state;

        this.setState({ loading: true });
        const that = this;
        const original = parentForm.getField(`Invoices[${invoiceIndex}]`);

        return updateInvoiceFunc(form.state, original).then(
            result => {
                return submitToXeroFunc(form.state).then(
                    ({ data }) => {
                        const Invoices = parentForm.getField('Invoices') || [];
                        const updatedInvoice = cloneDeep(data.submitInvoiceToXero);
                        Object.assign(Invoices[invoiceIndex], updatedInvoice);
                        parentForm.setField({ Invoices }, false);
                        that.setState({
                            loading: false,
                            form: createForm(that, cloneDeep(updatedInvoice)),
                            hasExportedToXero: true,
                            canEdit: false
                        });
                        this.props.setSnackbarMessage('Success, your invoice was submitted.', true);
                    },
                    e => that.onGqlError('Failed to submit invoice to xero.', e)
                );
            },
            e => that.onGqlError('Failed to update invoice.', e)
        );
    };

    onGqlError(userMessage, errorMessage) {
        console.error(userMessage, errorMessage);
        this.props.setSnackbarMessage(userMessage, false, null, errorMessage);
        this.setState({ loading: false });
    }
}

const getQuotesForLineItems = quotes => {
    return quotes
        .filter(e => !!e.Accepted)
        .map(quote => {
            const quoteItems = Object.assign([], quote.QuoteItems.edges || quote.QuoteItems);
            return {
                quoteID: quote.ID,
                quoteType: quote.QuoteType,
                items: quoteItems
                    .map(z => {
                        const e = z.node ? z.node : z;
                        return {
                            ID: null,
                            Quantity: e.Qty,
                            Price: e.Price,
                            Prepaid: e.Prepaid || false,
                            QuoteID: quote.ID,
                            Product: { ...e.Product },
                            Variation: { ...e.Variation }
                        };
                    })
                    .filter(e => !!e.Product && !!e.Product.ID && e.Product.ID !== '0')
            };
        });
};

const getPurchaseOrderList = form => {
    const supplierList = PO_SUPPLIER_LIST.map(supplier => {
        return { supplier: supplier, data: form.getField(supplier.key) || [] };
    }).filter(x => x.data.length > 0);

    //get Bills from suppliers
    const supplierPurchaseOrders = [];
    for (let x = 0; x < supplierList.length; x++) {
        for (let y = 0; y < supplierList[x].data.length; y++) {
            const supplier = supplierList[x].data[y];
            if (
                !isNullOrUndefined(supplier.PurchaseOrder) &&
                Number(supplier.PurchaseOrder.ID) > 0 &&
                Number(supplier.PurchaseOrder.Invoice.ID) > 0
            ) {
                supplierPurchaseOrders.push({
                    Supplier: supplierList[x].supplier.label,
                    PO: supplier.PurchaseOrder,
                    Confirmed: !!supplier.Confirmed ? 'Yes' : 'No'
                });
            }
        }
    }
    const disposalPurchaseOrders = PO_DOCTOR_LIST.map(field => {
        return { Supplier: field.label, PO: form.getField(field.key), Confirmed: 'Yes' };
    }).filter(po => !isNullOrUndefined(po.PO) && !isNullOrUndefined(po.PO.Invoice) && Number(po.PO.Invoice.ID) > 0);

    return disposalPurchaseOrders.concat(supplierPurchaseOrders);
};

const getPurchaseOrderLineItems = form => {
    if (!form) return;
    const poList = form.getField('PurchaseOrders');

    const supplierList = PO_SUPPLIER_LIST.map(supplier => form.getField(supplier.key) || []).filter(x => x.length > 0);

    //get Bills from suppliers
    const supplierPurchaseOrders = [];
    for (let x = 0; x < supplierList.length; x++) {
        for (let y = 0; y < supplierList[x].length; y++) {
            const supplier = supplierList[x][y];
            if (
                supplier.Confirmed &&
                !isNullOrUndefined(supplier.PurchaseOrder) &&
                Number(supplier.PurchaseOrder.ID) > 0
            ) {
                supplierPurchaseOrders.push(supplier.PurchaseOrder);
            }
        }
    }

    //get Bills from disposal
    const disposalPurchaseOrders = [
        'Certification.RefereePurchaseOrder',
        'Certification.HospitalPurchaseOrder',
        'Certification.DoctorPurchaseOrder'
    ]
        .map(field => form.getField(field))
        .filter(po => !isNullOrUndefined(po) && Number(po.ID) > 0);

    const purchaseOrderIDs = disposalPurchaseOrders.concat(supplierPurchaseOrders).map(e => e.ID);

    //get the coffins and coffin accessories
    let disposalItems = form.getField('Disposal.DisposalBookingItems') || [];
    let coffins = disposalItems.filter(bookingItem => isCategory(bookingItem, QueryKeys.Coffins));
    let otherDisposalItems = disposalItems.filter(bookingItem => !isCategory(bookingItem, QueryKeys.Coffins));
    //get the first OR actual coffin
    let coffin = coffins.length === 1 ? coffins[0] : coffins.find(x => x.Quantity === 1);

    const disposalLineItems = (!isNullOrUndefined(coffin) ? [coffin] : [])
        .concat(otherDisposalItems)
        .map(bookingItem => {
            const theProduct = assembleProduct(bookingItem);
            return {
                Quantity: bookingItem.Quantity,
                Price: bookingItem.UnitPrice,
                ...theProduct
            };
        });

    //convert all into line items
    const lineItems =
        (poList &&
            poList
                .filter(e => purchaseOrderIDs.find(f => e.ID === f))
                .map(purchaseOrder => {
                    return (
                        !!purchaseOrder.Products &&
                        purchaseOrder.Products.filter(obj => obj.Product).map(lineItem => {
                            const Variation = !!lineItem.Variation
                                ? {
                                      ID: lineItem.Variation.ID,
                                      InternalItemID: lineItem.Variation.InternalItemID,
                                      ShortDescription: lineItem.Variation.ShortDescription,
                                      GST: lineItem.Variation.GST
                                  }
                                : null;

                            return {
                                Quantity: lineItem.Qty,
                                Price: lineItem.UnitPrice,
                                Product: {
                                    ID: lineItem.Product.ID,
                                    InternalItemID: lineItem.Product.InternalItemID,
                                    Title: lineItem.Product.Title,
                                    GST: lineItem.Product.GST
                                },
                                Variation
                            };
                        })
                    );
                })
                .flat()
                .filter(e => !!e)
                .concat(disposalLineItems)) ||
        [];

    return lineItems.filter(e => !!e.Product && !!e.Product.ID && e.Product.ID !== '0');
};

const assembleProduct = bookingItem => {
    const Product = {};
    const Variation = {};
    const ProductID = bookingItem.ProductID || null;
    const VariationID = bookingItem.VariationID || null;
    if (!bookingItem.Product && !!ProductID) {
        const theProduct = ProductConfig.productMap[ProductID];
        if (!!theProduct) {
            Product.ID = theProduct.id;
            Product.InternalItemID = theProduct.internalId;
            Product.Title = theProduct.title;
            if (!!theProduct.original) {
                Product.GST = theProduct.original.gst;
                Variation.ID = '0';
            } else if (!!VariationID) {
                const theVariant = theProduct.variationMap[VariationID];
                if (!!theVariant) {
                    Variation.ID = theVariant.id;
                    Variation.InternalItemID = theVariant.internalId;
                    Variation.ShortDescription = theVariant.title.replace(theProduct.title + ' |', '');
                    Variation.GST = theVariant.gst;
                }
            }
        }
    } else if (!!bookingItem.Product && !!bookingItem.Product.ID) {
        Product.ID = bookingItem.Product.ID;
        Product.InternalItemID = bookingItem.Product.InternalItemID;
        Product.Title = bookingItem.Product.Title || 'N/A';
        Product.GST = bookingItem.Product.GST || false;

        if (!!bookingItem.Variation && bookingItem.Variation.ID) {
            Variation.ID = bookingItem.Variation.ID;
            Variation.InternalItemID = bookingItem.Variation.InternalItemID;
            Variation.ShortDescription = bookingItem.Variation.ShortDescription || 'N/A';
            Variation.GST = false;
        } else {
            Variation.ID = '0';
        }
    }

    return { Product, Variation };
};

const styles = ({ breakpoints }) => ({
    modalMagick: {
        maxWidth: 1440,
        width: '100%',
        [breakpoints.down('sm')]: {
            margin: 6
        },
        '& > div:nth-child(2)': {
            padding: 8,
            position: 'relative',
            '& > div:first-child': {
                // Content area
                overflow: 'auto',
                maxHeight: 'calc(100vh - 20rem)',
                maxWidth: 1440,
                flexDirection: 'unset',
                margin: 0,
                marginTop: 36,
                paddingTop: 0
            },
            '& > div:nth-child(2)': {
                // Footer
                padding: '20px 22px',
                margin: 0,
                justifyContent: 'unset',
                [breakpoints.down('sm')]: {
                    padding: '12px 6px'
                },
                '& button': {
                    '& svg': { margin: 0 },
                    [breakpoints.down('sm')]: {
                        minWidth: 'unset',
                        width: '3.5rem',
                        '& svg': { fontSize: 32, margin: 0 },
                        '& > span > span': {
                            display: 'none'
                        }
                    }
                }
            }
        }
    }
});

// prettier-ignore
export default compose(
    withSnackbarMessage,
    withStyles(styles)
)(InvoiceModal);
