import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash.clonedeep';
import Grid from '../../../component/form/Grid';
import LinkButton from '../../../component/form/LinkButton';
import Checkbox from '../../../component/form/Checkbox';
import StaffAutoComplete from '../../../component/form/StaffAutoComplete';
import ValidationPlaceholder from '../../../component/form/ValidationPlaceholder';
import { InlineField, InlineFieldRightAlignChildren, InlineHeader } from '../../../component/form/Inline';
import ColumnLayout from '../../../component/ColumnLayout';
import Table, {
    TableCell,
    TableHeaderCell,
    TableHeaderRow,
    TableNestedRow,
    TableRow,
    TableSecondaryHeaderRow
} from '../../../component/form/Table';
import LinearProgressIndicator from '../../../component/form/LinearProgressIndicator';
import DeleteIcon from '../../../component/icon/DeleteIcon';
import EditIcon from '../../../component/icon/EditIcon';
import SpecialInstructionModal from './SpecialInstructionModal';
import IconButton from '@material-ui/core/IconButton';
import { ALLOCATION, ALLOCATION_OPTIONS, GenerateRunsheet } from './SummaryConstants';
import cx from 'classnames';
import PrimaryButton, { AltButton, SaveButton } from '../../../component/form/PrimaryButton';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import { applyUrlParams, joinDefined } from '../../../util/strings';
import ReactToPrint from 'react-to-print';
import { isFirstLoad } from '../../../util/objects';
import { getUser } from '../../../util/sessions';
import Select from '../../../component/form/Select';
import PrintIcon from '../../../component/icon/PrintIcon';
import TickIcon from '../../../component/icon/TickIcon';
import QuestionMarkCircleIcon from '../../../component/icon/QuestionMarkCircleIcon';
import QuotesIcon from '../../../component/icon/QuotesIcon';
import Label from '../../../component/form/Label';
import Spinner from '../../../component/Spinner';
import { isContactDefined } from '../../../util/bookable';
import gql from 'graphql-tag';
import ChangelogModal from './ChangelogModal';
import { getMyClient, getServiceURLHostname } from '../../../apollo';
import TaskFragment1 from '../../workQueue/components/TaskFragment';
import { getTaskStatus, getTaskStatusClass } from '../../workQueue2/TaskConstants';
import { PO_SUPPLIER_LIST } from '../funeralConstants';
import { niceDateTimeFromString } from '../../../util/date';
import CommunicationNotes from '../../../component/modal/CommunicationNote';
import BiggerMessageBar from '../../../component/BiggerMessageBar';
import DownloadIcon from '../../../component/icon/DownloadIcon';

class Summary extends Component {
    state = {
        outstandingTasks: null,
        completedTasks: null,
        loading: false,
        tasksWillLoad: true // changed from false so that tasks won't load here, they're already in sidebar!
    };

    componentDidUpdate() {
        const { tasksWillLoad } = this.state;
        const { form } = this.props;
        if (!form) {
            return;
        }
        if (!form.getField('ID')) {
            return;
        }

        if (!tasksWillLoad) {
            const that = this;
            const user = getUser();
            that.setState({
                tasksWillLoad: true
            });
            that.loadFuneralTasks(user.ID).then(
                data => {
                    that.setState({
                        outstandingTasks: cloneDeep(data.OutstandingTasks).edges.map(e => e.node),
                        completedTasks: cloneDeep(data.CompletedTasks).edges.map(e => e.node),
                        loading: false
                    });
                },
                error => {
                    that.onGqlError('Failed to load tasks', error);
                    that.setState({ loading: false });
                }
            );
        }
    }

    componentWillReceiveProps(nextProps) {
        const { runsheet } = this.state;
        if (isFirstLoad(this, nextProps) || !runsheet) {
            const { form } = nextProps;
            if (!!form && !form.loading) this.setState({ runsheet: GenerateRunsheet(nextProps) });
        }
    }

    render() {
        const { editSpecialInstructionIndex } = this.state;
        const { form, locked } = this.props;

        let editSpecialInstruction = null;
        if (editSpecialInstructionIndex !== undefined) {
            editSpecialInstruction =
                editSpecialInstructionIndex !== null
                    ? form.getField('SpecialInstructions')[editSpecialInstructionIndex]
                    : {
                          ID: null,
                          Comment: '',
                          Check: false,
                          Action: ''
                      };
        }

        return (
            <Fragment>
                <SpecialInstructionModal
                    onSubmit={si => this.onSubmitSpecialInstruction(si)}
                    onCancel={() => this.onCloseSpecialInstructionModal()}
                    specialInstruction={editSpecialInstruction}
                    open={!!editSpecialInstruction}
                    name={'editSpecialInstruction'}
                    disabled={locked}
                />

                <ColumnLayout>
                    {this.renderFirstColumn()}
                    {this.renderSecondColumn()}
                </ColumnLayout>
            </Fragment>
        );
    }

    renderFirstColumn() {
        const {
            form,
            match: {
                params: { id }
            }
        } = this.props;
        return (
            <Fragment>
                <Grid container spacing={16}>
                    {this.renderFuneralCompletionStatus()}
                    {this.renderArrangerCoordinators('Arrangers', 'Arranger', 'Arrangers')}
                    {this.renderArrangerCoordinators('Conductors', 'Funeral Conductor', 'Funeral Conductors')}
                    {this.renderArrangerCoordinators('Coordinators', 'Coordinator', 'Coordinators')}
                    {this.renderSpecialInstructions()}
                    {this.renderSupplierConfirmations()}
                    <Grid bucket>
                        <Grid item pc>
                            <Label text={'Funeral data history'} />
                        </Grid>
                        <Grid item pc>
                            <p style={{ marginTop: 0 }}>
                                Record created on {niceDateTimeFromString(form.getField('Created'))}
                                {' by '}
                                {form.getField('CreatedBy.FirstName')} {form.getField('CreatedBy.Surname')}
                            </p>
                            <ChangelogModal FuneralID={id} LegacyKey={form.getField('LegacyKey')} />
                        </Grid>
                    </Grid>

                    {1 === 2 && this.renderOutstandingFuneralTasks()}
                    {1 === 2 && this.renderCompletedFuneralTasks()}
                </Grid>
            </Fragment>
        );
    }

    renderSecondColumn() {
        const { runsheet } = this.state;
        const { form, user } = this.props;
        return (
            <Grid container spacing={16}>
                <Grid bucket>
                    {form.getField('ApprovedOn') && (
                        <Fragment>
                            <Grid pc={1}>
                                <BiggerMessageBar messageType="good">
                                    This run sheet was approved on {form.getField('ApprovedOn')}
                                </BiggerMessageBar>
                            </Grid>
                        </Fragment>
                    )}
                    {form.getField('RejectedOn') && (
                        <Grid pc={1}>
                            <BiggerMessageBar messageType="error">
                                Client rejected this run sheet on {form.getField('RejectedOn')} with message: "
                                {form.getField('RejectionNote')}"
                            </BiggerMessageBar>
                        </Grid>
                    )}

                    <Grid pc={1}>{this.renderRunSheets(runsheet)}</Grid>
                </Grid>

                <CommunicationNotes
                    recordID={form.getField('ID')}
                    recordKey={form.getField('LegacyKey')}
                    recordType="Funeral"
                    substitutionFields={{
                        '[FirstName]': form.getField('Informant.FirstName'),
                        '[DeceasedFirstName]': form.getField('FirstName'),
                        '[DeceasedSurname]': form.getField('Surname'),
                        '[RelationshipToDeceased]': form.getField('Informant.RelationshipToDeceased'),
                        '[OfficeName]': form.getField('Office.BusinessName'),
                        '[OfficePhone]': form.getField('Office.Phone'),
                        '[FuneralKey]': form.getField('LegacyKey'),
                        '[StaffName]': user.readCurrentUser.FirstName + ' ' + user.readCurrentUser.Surname
                    }}
                />
            </Grid>
        );
    }

    renderSpecialInstructions() {
        const { classes, form, locked } = this.props;
        const specialInstructions = form.getField('SpecialInstructions') || [];
        return (
            <Grid bucket={true}>
                <InlineHeader header="Special Instructions" />
                <InlineField>
                    <Grid item xs={12}>
                        <table className={classes.table}>
                            <tbody>
                                {specialInstructions.map((obj, i) => (
                                    <tr key={i} className={classes.listItem}>
                                        <td className={classes.siDescription}>
                                            {obj.Action}
                                            {!!obj.Comment && (
                                                <div style={{ lineHeight: 1, fontStyle: 'italic' }}>
                                                    <small>{obj.Comment}</small>
                                                </div>
                                            )}
                                        </td>
                                        <td className={classes.siButtons}>
                                            <Checkbox
                                                disabled={locked}
                                                label="Confirmed"
                                                form={form}
                                                name={`SpecialInstructions[${i}].Check`}
                                            />
                                        </td>
                                        {/*<td>
                                        <InlineField>
                                            <OptionalTextField
                                                linkbutton="true"
                                                textfieldlabel="Comment"
                                                buttonlabel="+ Add Comment"
                                                form={form}
                                                name={`SpecialInstructions[${i}].Comment`}
                                            />
                                        </InlineField>
                                    </td>*/}
                                        <td className={classes.siButtons}>
                                            <InlineField>
                                                <IconButton
                                                    className={classes.tableIconButton}
                                                    title={'Edit'}
                                                    onClick={() => this.addOrEditSpecialInstruction(i)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    disabled={locked}
                                                    className={classes.tableIconButton}
                                                    title={'Delete'}
                                                    onClick={() => this.deleteSpecialInstruction(i)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </InlineField>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Grid>
                </InlineField>
                <InlineField lineHeight={'normal'}>
                    <PrimaryButton
                        disabled={locked && (specialInstructions || []).length > 0}
                        onClick={() => this.addOrEditSpecialInstruction(null)}
                    >
                        + Add Instruction
                    </PrimaryButton>
                </InlineField>
            </Grid>
        );
    }

    renderArrangerCoordinators(type, singular, plural) {
        const { form, classes, locked } = this.props;
        const array = form.getField(type) || [];
        return (
            <Grid bucket={true}>
                <ValidationPlaceholder name={type} form={form}>
                    <InlineHeader header={plural} />
                    {array.map((obj, i) => (
                        <InlineField key={i} className={classes.listItem}>
                            <Grid item xs={12} sm={6}>
                                <StaffAutoComplete
                                    disabled={locked}
                                    label={singular}
                                    placeholder={`Search for a Staff Member`}
                                    onSelect={(_, staff) => this.onSelectArrangerCoordinator(type, i, staff)}
                                    value={obj.Member}
                                    clearOnSelect={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InlineField>
                                    <Select
                                        disabled={locked}
                                        label="Allocation"
                                        options={ALLOCATION_OPTIONS}
                                        name={`${type}[${i}].Allocation`}
                                        form={form}
                                        allowNone={false}
                                    />
                                    <IconButton
                                        disabled={locked}
                                        className={cx(classes.tableIconButton, classes.alignWithFloatingLabel)}
                                        onClick={() => this.deleteArrangerCoordinator(type, i)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </InlineField>
                            </Grid>
                        </InlineField>
                    ))}

                    <InlineField lineHeight={'normal'}>
                        <PrimaryButton disabled={locked} onClick={() => this.addArrangerCoordinator(type)}>
                            + Add {singular}
                        </PrimaryButton>
                    </InlineField>
                </ValidationPlaceholder>
            </Grid>
        );
    }

    renderSupplierConfirmations() {
        const { form } = this.props;

        const supplierList = PO_SUPPLIER_LIST.map(e => e);

        const suppliers = supplierList
            .map(supplier => {
                return {
                    ...supplier,
                    purchaseOrders: (form.getField(supplier.key) || []).filter(
                        x =>
                            !!x.Contact &&
                            !!x.Confirmed &&
                            !(!!x.PurchaseOrder && !!x.PurchaseOrder.Invoice && !!x.PurchaseOrder.Invoice.XeroID)
                    ),
                    confirmations: (form.getField(supplier.key) || []).filter(x => !!x.Contact && !x.Confirmed)
                };
            })
            .concat(
                ['Referee', 'Doctor', 'Hospital'].map(monkey => {
                    const Contact = form.getField('Certification.' + monkey);
                    if (!isContactDefined(Contact)) return null;
                    if (
                        monkey === 'Doctor' &&
                        (!!form.getField('DoctorAttachedToFlag') ||
                            form.getField('Disposal.CrematedOrBuried') !== 'Cremated')
                    )
                        return null;
                    if (
                        monkey === 'Referee' &&
                        !(
                            form.getField('Disposal.CrematedOrBuried') === 'Cremated' &&
                            form.getField('Certification.TypeOfBdmDeathCertificate') === 'MCCD'
                        )
                    )
                        return null;
                    const po = form.getField('Certification.' + monkey + 'PurchaseOrder');
                    return {
                        key: monkey,
                        label: 'Certification ' + monkey,
                        link: 'certification',
                        purchaseOrders: !!po && !!po.Invoice && !!po.Invoice.XeroID ? [] : [{ Contact }],
                        confirmations: []
                    };
                })
            )
            .filter(e => !!e);

        const noBookings = suppliers.every(x => x.confirmations.every(y => y.length === 0));
        const noOrders = suppliers.every(x => x.purchaseOrders.every(y => y.length === 0));

        return (
            <Fragment>
                <Grid bucket={true}>
                    <InlineHeader header="Supplier Confirmations & Bills" />
                    {noBookings && noOrders ? (
                        <Grid item xs={12}>
                            <p>There are no outstanding supplier confirmations or missing bills.</p>
                        </Grid>
                    ) : (
                        <InlineField lineHeight={'normal'}>
                            <Grid item xs={12}>
                                <Table>
                                    <TableHeaderRow>
                                        <TableHeaderCell>Issues with Suppliers</TableHeaderCell>
                                    </TableHeaderRow>
                                    {suppliers.map((supplier, i) => this.renderOutstandingSupplierGroup(supplier, i))}
                                    <TableRow>
                                        <TableCell />
                                    </TableRow>
                                </Table>
                            </Grid>
                        </InlineField>
                    )}
                    {!noOrders && 1 > 2 && (
                        <InlineField>
                            <SaveButton>
                                <QuotesIcon />
                                Generate Bills
                            </SaveButton>
                        </InlineField>
                    )}
                </Grid>
            </Fragment>
        );
    }

    renderOutstandingFuneralTasks() {
        const { outstandingTasks, loading } = this.state;

        return (
            <Grid bucket={true}>
                <InlineHeader header="Outstanding Tasks" />

                <Grid item xs={12}>
                    {loading ? (
                        <Spinner />
                    ) : !!outstandingTasks && !!outstandingTasks.length ? (
                        <Table columns={['Task', 'Status', 'Details']}>
                            {outstandingTasks.map(task => {
                                return this.getTaskRow(task, 'outstanding-task');
                            })}
                        </Table>
                    ) : (
                        'There are no outstanding tasks for this funeral.'
                    )}
                </Grid>
            </Grid>
        );
    }

    renderCompletedFuneralTasks() {
        const { completedTasks, loading } = this.state;

        return (
            <Grid container bucket>
                <InlineHeader header="Completed Tasks" />

                <Grid item xs={12}>
                    {loading ? (
                        <Spinner />
                    ) : !!completedTasks && !!completedTasks.length ? (
                        <Table columns={['Task', 'Status', 'Details']}>
                            {completedTasks.map(task => {
                                return this.getTaskRow(task, 'complete-task');
                            })}
                        </Table>
                    ) : (
                        'There are no completed tasks for this funeral.'
                    )}
                </Grid>
            </Grid>
        );
    }

    getTaskRow = (task, type) => {
        const { form } = this.props;
        return (
            <TableRow pad key={type + '-' + task.ID}>
                <TableCell>{task.Title}</TableCell>
                <TableCell>
                    <span className="workqueue-page">
                        <span className="wq-page" style={{ padding: 0 }}>
                            <span className={`task-status ${getTaskStatusClass(task)}`}>
                                <span className="status-label">{getTaskStatus(task)}</span>
                            </span>
                        </span>
                    </span>
                </TableCell>
                <TableCell>
                    {/*Link will open respective task*/}
                    <LinkButton
                        onClick={() => {
                            const newPath = `/work-queue/funeral/${form.getField('ID')}/${task.ID}`;
                            let win = window.open(newPath, '_blank');
                            win.focus();
                        }}
                        text="View Details"
                    />
                </TableCell>
            </TableRow>
        );
    };

    renderOutstandingSupplierGroup(supplier, index) {
        if (supplier.confirmations.length === 0 && supplier.purchaseOrders.length === 0) return null;
        const { classes, match } = this.props;
        return (
            <Fragment key={index}>
                <TableSecondaryHeaderRow pad={true}>
                    <TableHeaderCell>
                        <span className={classes.actionLabel}>{supplier.label}:</span>
                    </TableHeaderCell>
                </TableSecondaryHeaderRow>
                {supplier.confirmations.length > 0 &&
                    supplier.confirmations.map((item, i) => {
                        return (
                            <TableNestedRow key={i} pad={true}>
                                <TableCell>
                                    <InlineFieldRightAlignChildren lineHeight={'normal'}>
                                        <span>
                                            <strong>
                                                {item.Cancelled
                                                    ? 'Cancelled'
                                                    : item.Responded
                                                    ? 'Unavailable'
                                                    : 'Awaiting Confirmation'}
                                                :&nbsp;
                                            </strong>
                                            <span>
                                                {item.Contact ? item.Contact.Name || item.Contact.Contact : '(none)'}
                                            </span>
                                        </span>
                                        <LinkButton
                                            href={applyUrlParams(supplier.link, match.params)}
                                            text="View Details"
                                        />
                                    </InlineFieldRightAlignChildren>
                                </TableCell>
                            </TableNestedRow>
                        );
                    })}
                {supplier.purchaseOrders.length > 0 &&
                    supplier.purchaseOrders.map((item, i) => {
                        return (
                            <TableNestedRow key={i} pad={true}>
                                <TableCell>
                                    <InlineFieldRightAlignChildren lineHeight={'normal'}>
                                        <span>
                                            <strong>Missing Bill:&nbsp;</strong>
                                            <span>
                                                {item.Contact ? item.Contact.Name || item.Contact.Contact : '(none)'}
                                            </span>
                                        </span>
                                        <LinkButton
                                            href={applyUrlParams(supplier.link, match.params)}
                                            text="View Details"
                                        />
                                    </InlineFieldRightAlignChildren>
                                </TableCell>
                            </TableNestedRow>
                        );
                    })}
            </Fragment>
        );
    }

    renderRunSheets(runsheet) {
        const { classes, form, match } = this.props;

        const FirstName = form.getField('FirstName');
        const MiddleName = form.getField('MiddleName');
        const Surname = form.getField('Surname');
        const PDFs = form.getField('RunsheetPDFs');

        return (
            <Grid item xs={12}>
                <div className={'remove-nested-flex'} ref={e => (this.runsheetRef = e)}>
                    <Grid container spacing={16}>
                        <InlineHeader header="Run Sheet for:">
                            <h2 style={{ display: 'inline' }}>
                                &nbsp;{`${joinDefined([FirstName, MiddleName, Surname], ' ')}`}
                            </h2>
                        </InlineHeader>

                        <Grid item xs={12}>
                            <Table columns={['Run Sheet Items', 'Confirmed']}>
                                {runsheet &&
                                    runsheet
                                        .filter(e => !!e.Action)
                                        .map((runsheetGroup, i) => (
                                            <Fragment key={'rs_' + i}>
                                                <TableSecondaryHeaderRow className={'page-break-avoid'}>
                                                    <TableHeaderCell pad={true}>
                                                        {runsheetGroup.Action && (
                                                            <span className={classes.actionLabel}>
                                                                {runsheetGroup.Action}:&nbsp;
                                                            </span>
                                                        )}
                                                        <span>{runsheetGroup.Description}</span>
                                                        {runsheetGroup.Comment && (
                                                            <span>
                                                                <br />
                                                                <i>{runsheetGroup.Comment}</i>
                                                            </span>
                                                        )}
                                                    </TableHeaderCell>
                                                    <TableHeaderCell className={classes.confirmedColumn}>
                                                        {runsheetGroup.Link !== undefined && (
                                                            <LinkButton
                                                                href={applyUrlParams(runsheetGroup.Link, match.params)}
                                                                text="view details"
                                                            />
                                                        )}
                                                        {runsheetGroup.Link === undefined &&
                                                            runsheetGroup.Confirmed !== undefined &&
                                                            (!!runsheetGroup.Confirmed ? (
                                                                <span className={classes.progressTick}>
                                                                    <TickIcon />
                                                                </span>
                                                            ) : (
                                                                <span className={classes.progressQuestionMark}>
                                                                    <QuestionMarkCircleIcon />
                                                                </span>
                                                            ))}
                                                    </TableHeaderCell>
                                                </TableSecondaryHeaderRow>
                                                {runsheetGroup.Items != null &&
                                                    runsheetGroup.Items.length > 0 &&
                                                    runsheetGroup.Items.map((item, j) => (
                                                        <TableNestedRow key={'rs_' + i + '_' + j}>
                                                            <TableCell>
                                                                {item.Action && (
                                                                    <span className={classes.actionLabel}>
                                                                        {item.Action}:&nbsp;
                                                                    </span>
                                                                )}
                                                                <span>{item.Description}</span>
                                                                {item.Comment && (
                                                                    <span>
                                                                        <br />
                                                                        <i>{item.Comment}</i>
                                                                    </span>
                                                                )}
                                                            </TableCell>
                                                            <TableCell className={classes.confirmedColumn}>
                                                                {item.Link !== undefined && (
                                                                    <LinkButton
                                                                        href={applyUrlParams(item.Link, match.params)}
                                                                        text="view details"
                                                                    />
                                                                )}
                                                                {item.Link === undefined &&
                                                                    item.Confirmed !== undefined &&
                                                                    (!!item.Confirmed ? (
                                                                        <span className={classes.progressTick}>
                                                                            <TickIcon />
                                                                        </span>
                                                                    ) : (
                                                                        <span className={classes.progressQuestionMark}>
                                                                            <QuestionMarkCircleIcon />
                                                                        </span>
                                                                    ))}
                                                            </TableCell>
                                                        </TableNestedRow>
                                                    ))}
                                            </Fragment>
                                        ))}
                                <TableRow>
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            </Table>
                        </Grid>
                    </Grid>
                </div>
                <Grid item xs={12}>
                    <br />
                </Grid>
                <InlineField lineHeight={'normal'}>
                    <ReactToPrint
                        pageStyle={'@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; } }'}
                        trigger={() => (
                            <AltButton>
                                <PrintIcon className={classes.textIconButton} />
                                Print Run Sheet
                            </AltButton>
                        )}
                        content={() => this.runsheetRef}
                    />

                    {!form.getField('ApprovedOn') && (
                        <AltButton
                            target="_blank"
                            href={
                                '//' +
                                getServiceURLHostname() +
                                '/arrangement-confirmation-page?ID=' +
                                form.getField('ID') +
                                '&Key=' +
                                form.getField('LegacyKey')
                            }
                        >
                            Show draft PDF
                        </AltButton>
                    )}
                </InlineField>
                <br />
                {!!form.getField('ApprovedOn') && (
                    <Table columns={['Run Sheet Version', 'Download']}>
                        {PDFs &&
                            PDFs.map((pdf, i) => {
                                return (
                                    <TableRow key={'pdf_' + i}>
                                        <TableCell>{pdf.Name}</TableCell>
                                        <TableCell className={classes.confirmedColumn}>
                                            <IconButton target="_blank" href={pdf.AbsoluteLink}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </Table>
                )}
            </Grid>
        );
    }

    renderFuneralCompletionStatus() {
        const { adminChecklist } = this.props;
        const { AdminChecksComplete } = adminChecklist || 0;
        const funeralCompletion = 100 * (Number(AdminChecksComplete) || 0);

        return (
            <Grid item xs={12} style={{ margin: '10px 10px 0 8px' }}>
                <InlineHeader header="Funeral Checklist Completion Status" />
                <LinearProgressIndicator progress={funeralCompletion} />
            </Grid>
        );
    }

    addOrEditSpecialInstruction(i) {
        this.setState({ editSpecialInstructionIndex: i });
    }

    deleteSpecialInstruction(i) {
        const { form } = this.props;
        const SpecialInstructions = form.getField('SpecialInstructions');
        SpecialInstructions.splice(i, 1);
        form.setField({ SpecialInstructions });
    }

    onSubmitSpecialInstruction(specialInstruction) {
        const { form } = this.props;
        const { editSpecialInstructionIndex } = this.state;
        const SpecialInstructions = form.getField('SpecialInstructions') || [];

        if (editSpecialInstructionIndex > -1 && SpecialInstructions[editSpecialInstructionIndex]) {
            const existing = SpecialInstructions[editSpecialInstructionIndex];
            Object.assign(existing, specialInstruction);
        } else {
            SpecialInstructions.push(specialInstruction);
        }

        form.setField({ SpecialInstructions });
        this.onCloseSpecialInstructionModal();
    }

    onCloseSpecialInstructionModal() {
        this.setState({ editSpecialInstructionIndex: undefined });
    }

    onSelectArrangerCoordinator(type, index, staff) {
        const { form } = this.props;
        const array = form.getField(type);
        array[index].Member = { ...staff };
        form.setField({ [type]: array });
    }

    addArrangerCoordinator(type) {
        const { form } = this.props;
        const array = form.getField(type) || [];
        array.push({
            ID: null,
            Allocation:
                array.filter(x => x.Allocation === ALLOCATION.Primary).length === 0
                    ? ALLOCATION.Primary
                    : ALLOCATION.Secondary,
            Member: { ...getUser() }
        });
        form.setField({ [type]: array });
    }

    deleteArrangerCoordinator(type, i) {
        const { form } = this.props;
        const array = form.getField(type) || [];
        array.splice(i, 1);
        form.setField({ [type]: array });
    }

    setCheckedValue(checked, propertyPath, propertyName) {
        const { form } = this.props;
        const source = form.getField(propertyPath);
        source[propertyName] = checked;
        form.setField(propertyPath);
    }

    goToDetails(link) {
        const { match, history } = this.props;
        const path = applyUrlParams(link, match.params);
        history.push(path);
    }

    loadFuneralTasks = async userID => {
        const { form } = this.props;
        const funeralId = form.getField('ID');

        const asyncQuery = await getMyClient().query({
            fetchPolicy: 'network-only',
            query: gql`
                ${TaskFragment1}
                query ReadFuneralTasks($funeralId: Int!) {
                    OutstandingTasks: readTasks(isComplete: false, funeralId: $funeralId) {
                        edges {
                            node {
                                ...TaskFragment1
                            }
                        }
                    }
                    CompletedTasks: readTasks(isComplete: true, funeralId: $funeralId) {
                        edges {
                            node {
                                ...TaskFragment1
                            }
                        }
                    }
                }
            `,
            variables: {
                funeralId: funeralId
            }
        });
        return asyncQuery && asyncQuery.data ? asyncQuery.data : false;
    };
}

const styles = () => ({
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        margin: '0 -6px',
        '& td': {
            padding: '2px 6px'
        }
    },

    tableIconButton: {
        height: '32px',
        width: '32px',
        '& svg': {
            height: '18px'
        }
    },

    confirmedColumn: {
        width: '80px',
        textAlign: 'center!important',
        padding: '0!important'
    },

    textIconButton: {
        height: '18px'
    },

    siDescription: {
        // width: '50%'
    },

    siButtons: {
        width: '70px'
    },

    actionLabel: {
        fontWeight: 'bold'
    },

    alignWithFloatingLabel: {
        marginTop: 17
    },

    alignWithLabel: {
        marginTop: 10
    },
    listItem: {
        width: '100%',
        padding: '0 6px',
        margin: '6px 0 0',
        borderRadius: 5,
        '& td': { height: 54, lineHeight: 'normal' },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.035)'
        }
    },
    progressQuestionMark: {
        height: 24,
        width: 24,
        color: '#CCC',
        background: '#FFF',
        borderRadius: 99,
        margin: 6,
        display: 'inline-block',
        '& > svg': {
            height: '100%',
            width: '100%'
        }
    },
    progressTick: {
        borderRadius: '100%',
        padding: 3,
        height: 24,
        width: 24,
        background: '#26CC6F',
        color: '#FFFFFF',
        margin: 6,
        display: 'inline-block',
        '& > svg': {
            height: '100%',
            width: '100%'
        }
    }
});

export default compose(withRouter, withStyles(styles))(Summary);
