import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import cx from 'classnames';
import { applyUrlParams } from '../util/strings';

function getTabPath(tab, props) {
    return applyUrlParams(tab.path, props.match.params);
}

class RouteTabs extends React.Component {
    state = {
        selectedIndex: 0
    };

    static getDerivedStateFromProps(newProps, oldState) {
        const { tabs, history, location, onChange } = newProps;
        let selectedIndex = tabs.findIndex(tab => location.pathname.startsWith(getTabPath(tab, newProps)));
        if (selectedIndex === -1) {
            // Redirect to the first tab path
            history.replace(getTabPath(tabs[0], newProps));
            selectedIndex = 0;
        }
        if (selectedIndex !== oldState.selectedIndex) {
            if (onChange) onChange(tabs[selectedIndex].label);
            return { selectedIndex };
        }
        return null;
    }

    onChange = (e, selectedIndex) => {
        const { history, tabs } = this.props;
        history.push(getTabPath(tabs[selectedIndex], this.props));
    };

    render() {
        const { classes, tabs, children } = this.props;
        const { selectedIndex } = this.state;

        return (
            <Fragment>
                <Tabs
                    value={selectedIndex}
                    TabIndicatorProps={{ component: 'indicator' }}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    className={classes.tabs}
                    classes={{
                        scrollButtonsAuto: classes.scrollButtons,
                        flexContainer: classes.tabsContainer,
                        scroller: classes.scroller
                    }}
                    onChange={this.onChange}
                >
                    {tabs.map(this.renderTab)}
                </Tabs>
                {children(selectedIndex)}
            </Fragment>
        );
    }

    renderTab = (tab, index) => {
        const { classes } = this.props;
        return (
            <Tab
                key={tab.key}
                tab={tab.key}
                label={this.renderTabLabel(tab, index === this.state.selectedIndex)}
                className={classes.tab}
            />
        );
    };

    renderTabLabel(tab, selected) {
        const { classes } = this.props;

        return (
            <div className={classes.tabContainer}>
                <div className={selected ? cx(classes.tabLabel, classes.tabLabelSelected) : classes.tabLabel}>
                    {tab.title || tab.label}
                </div>
                <div className={classes.badgeContainer}>
                    <div title={'Please fix required fields'} className={classes.badgeError} tab-error={tab.key} />
                    <div
                        title={'Please fix suggested fields'}
                        className={classes.badgeSuggested}
                        tab-suggested={tab.key}
                    />
                </div>
            </div>
        );
    }
}

// We don't want the browser scroll bar to be visible when scrolling the tabs
// so we increase the height of the tabs scroller div so the scroll bar is hidden
const browserScrollBarOffset = 32;

const styles = ({ palette, typography, sizes }) => ({
    tabContainer: {
        display: 'flex'
    },
    badgeContainer: {
        display: 'flex',
        '& > *': {
            borderRadius: '50% 50%',
            alignContent: 'center',
            height: '20px',
            width: '20px',
            fontSize: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '3px',
            display: 'none' //flex
        }
    },
    badgeError: {
        color: '#FFFFFF',
        backgroundColor: palette.validation.error
    },
    badgeSuggested: {
        color: '#FFFFFF',
        backgroundColor: palette.validation.suggested
    },
    tabs: {
        borderBottom: `1px solid ${palette.divider}`,
        height: sizes.tabHeight
    },
    tabsContainer: {
        height: sizes.tabHeight
    },
    tab: {
        height: sizes.tabHeight
    },
    scrollButtons: {
        flex: '0 0 40px'
    },
    scroller: {
        height: sizes.tabHeight + browserScrollBarOffset,
        '& > :last-child': {
            top: sizes.tabHeight - 3
        }
    },
    tabLabel: {
        whiteSpace: 'pre',
        textTransform: 'none',
        ...typography.subheading
    },
    tabLabelSelected: {
        fontWeight: typography.fontWeightMedium
    }
});

export default withRouter(withStyles(styles)(RouteTabs));
